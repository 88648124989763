import React, { CSSProperties, useEffect, useState } from 'react';
import './index.scss';
import { Button, Menu, Modal, notification, Space, Form, Typography, Input, Row, Col, Select, Divider, Upload, Avatar, message } from 'antd';
import { useHistory } from 'react-router-dom';
import AdminListLayout from 'shared/components/layout/AdminListLayout/AdminListLayout';
import { ShopOutlined, PlusCircleOutlined } from '@ant-design/icons';
import Search from 'antd/es/input/Search';
import API_SERVICE from '../../services/api-service';
import TextArea from 'antd/es/input/TextArea';
import { FileUpload } from '../../components';

const { SubMenu } = Menu;

const { Text, Title, Link } = Typography;


export default function EditProfile(props: any) {
    const [form] = Form.useForm();
    const [saving, setSaving] = useState(false);

    const [profile, setProfile] = useState({} as any);
    const [profileImage, setProfileImage] = useState({} as any);
    const [fetching, setFetching] = useState(false);

    const getProfile = async () => {
        setFetching(true);
        try {
            const { data: {data} } = await API_SERVICE.getProfile();
            data._roles = '';
            data.roles.map((role: any) => {
              if (role.name)
                data._roles = `${data._roles}${role.name}${'\n'}`;
            })
            data._company = ((data.companyAdmin || {}).company || {}).name || '';
            setProfile(data);
            setProfileImage(data.profileImage || {});
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        } finally {
            setFetching(false);
        }
    };
    useEffect(() => {
        getProfile();
    }, []);

    if (fetching)
      return <div> Getting profile details... </div>
    return (
      <div>
            <Form
                form={form}
                initialValues={profile || {}}
                fields={[]}
                onFinish={(value) => {
                    const reqObj: any = value;
                    delete reqObj.phoneNumber;
                    console.log(reqObj)
                    setSaving(true);
                    API_SERVICE.profileUpdate(reqObj).then((d) => {
                        notification.success({message: 'Profile updated successfully'});
                    }).catch((e) => {
                        notification.error({ message: API_SERVICE.handleErrors(e) });
                    }).finally(() => {
                        setSaving(false);
                    });
                }}
                layout='horizontal'
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 16 }}
                requiredMark={true}
            >

                <Form.Item
                    label={'Profile Image:'}
                    name={'profileImageId'}
                    rules={[
                        { required: true, message: 'This field is required' },
                    ]}
                >
                    <FileUpload type={1} callback={setProfileImage}>
                        <Avatar src={profileImage.url} size={72} style={{marginTop: -16}} />
                    </FileUpload>
                </Form.Item>
                <Form.Item
                    label={'First Name:'}
                    name={'firstName'}
                    rules={[
                        { required: true, message: 'This field is required' },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={'Last Name:'}
                    name={'lastName'}
                    rules={[
                        { required: true, message: 'This field is required' },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={'Email ID:'}
                    name={'email'}

                >
                    <Input disabled style={styles.disabledInput} />
                </Form.Item>
                <Form.Item
                    label={'Contact Number:'}
                    name={'phoneNumber'}

                >
                    <Input disabled style={styles.disabledInput} />
                </Form.Item>
                <Form.Item
                    label={'Role(s):'}
                    name={'_roles'}

                >
                    <TextArea disabled style={styles.disabledInput}
                              autoSize={{minRows: 5, maxRows: 5}}/>
                </Form.Item>
                <Form.Item
                    label={'Company:'}
                    name={'_company'}

                >
                    <Input disabled style={styles.disabledInput} />
                </Form.Item>

            </Form>
            <div className="profile-submit">
              <Button onClick={form.submit} shape='round' className="theme-button primary">Update</Button>
            </div>
        </div>
    );
}


const styles = {
  disabledInput: {
    backgroundColor: '#fff',
    color: '#6E6E6E',
  }
}
