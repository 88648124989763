import * as React from 'react';
import { ReactElement } from 'react';
import { Route, Redirect, Switch, useHistory, useLocation } from 'react-router-dom';
import { useMedia } from 'react-use';
import DesktopLayout from 'shared/components/layout/DesktopLayout';
import MobileLayout from 'shared/components/layout/MobileLayout';

import { useTranslation } from 'react-i18next';


interface MenuItem {
    route: string;
    icon: ReactElement;
    label: string;
}


type Props = {}

const Layout: React.FunctionComponent<Props> = () => {
    const { t } = useTranslation();

    const { pathname } = useLocation();
    const history = useHistory();

    const selectedMenu = pathname;

    function handleMenuClick(route: string) {
        if (route !== pathname) {
            history.push(route);
        }
    }

    const AppLayout =  DesktopLayout;

    // React.useEffect(()=>{
    //   document.title = "Franchise | Danube Home One Application | Home Decor, Furnishings, Lighting, Bath & Kitchen"
    // },[])

    return (
        <AppLayout
            selectedMenu={selectedMenu}
            onMenuClick={handleMenuClick}
        >
            <Switch>
                <Route exact path="/">
                  <Redirect to="/home" />
                </Route>
                {/*<Route exact path="/home" component={HomePage}  />*/}
                <Route exact path="/home" element={<div>Home</div>}  />
            </Switch>
        </AppLayout>
    );
};

export default Layout;
