import React from 'react';
import { ConfigProvider } from "antd";
import './i18n';


import enUS from 'antd/es/locale/en_US';

// Router
import ProtectedRoute from 'shared/components/ProtectedRoute';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AuthProvider } from "shared/contexts/AuthContext";


// Views
import Layout from "views/Layout";
import Login from "views/login/Login";
import Register from 'views/register/Register';
import OTP from 'views/otp/OTP';
import ForgotPassword from 'views/forgotPassword/forgotPassword';



import "antd/dist/antd.css";
import "./App.scss";
import ClosedLayout from './views/ClosedLayout';
import Dashboard from 'views/Dashboard/Dashboard';
import Analytics from 'views/Analytics/Analytics';



function App() {
  return (
          <ConfigProvider locale={enUS}>
              <AuthProvider>
                  <Router>
                  <Switch>

                          <Route exact path="/login" component={Login} />
                          <Route exact path="/register" component={Register} />
                          <Route exact path="/otp" component={OTP} />
                          <Route exact path="/reset-password" component={ForgotPassword} />
                          <Route exact path="/reset-password/:token" component={ForgotPassword} />
                          {/*<Route exact path="/confirm-email" component={ConfirmEmail} />*/}
                          {/*<Route exact path="/reset-password-request" component={ResetPasswordRequest} />*/}
                          <ProtectedRoute path="/admin" component={ClosedLayout} />
                          <ProtectedRoute path="/" component={Layout} />
                      </Switch>
                  </Router>
              </AuthProvider>
          </ConfigProvider>

  );
}

export default App;
