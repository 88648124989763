import React, { CSSProperties, useEffect, useState } from 'react';
import { Row, Col, Divider, notification, Button, Popconfirm } from 'antd';
import API_SERVICE from 'services/api-service';
import './index.scss';
import ConsumablesTable from './ConsumablesTable/ConsumablesTable';
import { useTranslation } from 'react-i18next';
import EmployeesCostTable from './EmployeesCostTable/EmployeesCostTable';
import ExternalWorkersTable from './ExternalWorkersTable/ExternalWorkersTable';
import SubContractorTable from './SubContractorTable/SubContractorTable';
import { DownloadOutlined, CheckOutlined } from '@ant-design/icons';

export default function FinalizeFinancial(props: any) {
    const { t } = useTranslation();

    const [claimId, setClaimId] = useState(null);
    const [claimDetails, setClaimDetails] = useState(null);

    const getClaimsDetails = async (claimId: number) => {
        try {
            const {
                data: { data },
            } = await API_SERVICE.getClaimById(claimId);
            setClaimDetails(data);
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        }
    };

    useEffect(() => {
        const {
            match: { params },
        } = props;
        setClaimId(params.claimId);
        getClaimsDetails(params.claimId);
    }, []);

    const handleDownload = async () => {
        const {
            match: { params },
        } = props;
        try {
            const {
                data: { data },
            } = await API_SERVICE.downloadFinancialReport(params.claimId);
            console.log('download data', data.url);
            window.open(API_SERVICE.downloadReport(data.url));
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        }
    };

    const finalizeFinancialStage = async () => {
        try {
            const {
                match: { params },
            } = props;
            await API_SERVICE.finalizeFinancials(params.claimId);

            notification.success({
                message: 'Financials Data successfully Finalised',
            });
        } catch (error) {
            notification.error({ message: API_SERVICE.handleErrors(error) });
        }
    };

    return (
        <div className={'financial-details-wrapper page-wrapper'}>
            <div>
                <Row
                    className="title-bar d-flex"
                    align={'middle'}
                    justify={'space-between'}
                    gutter={20}
                    wrap={false}
                >
                    <Col>
                        <div className="d-flex align-items-center">
                            <div className={'title d-flex align-items-center'}>
                                Finalize Report
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <Popconfirm
                            title="Are you sure to you want to Finalize Report"
                            onConfirm={() => finalizeFinancialStage()}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                type="link"
                                shape="round"
                                className="black-button"
                                icon={<CheckOutlined className="check-icon " />}
                            >
                                Finalize Financials
                            </Button>
                        </Popconfirm>
                        <Button
                            onClick={handleDownload}
                            shape="round"
                            className="black-button ml-2"
                            icon={<DownloadOutlined />}
                        >
                            Download Financial Report
                        </Button>
                    </Col>
                </Row>
            </div>
            <Divider />

            <div>
                {claimId && claimDetails && (
                    <EmployeesCostTable
                        claimId={claimId}
                        claimDetails={claimDetails}
                    />
                )}
            </div>
            <Divider />

            <div>
                {claimId && claimDetails && (
                    <SubContractorTable
                        claimId={claimId}
                        claimDetails={claimDetails}
                    />
                )}
            </div>
            <Divider />

            <div>
                {claimId && claimDetails && (
                    <ExternalWorkersTable
                        claimId={claimId}
                        claimDetails={claimDetails}
                    />
                )}
            </div>
            <Divider />

            <div>
                {claimId && claimDetails && (
                    <ConsumablesTable
                        claimId={claimId}
                        claimDetails={claimDetails}
                    />
                )}
            </div>
        </div>
    );
}
