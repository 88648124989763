import React, { useState, useEffect } from 'react';
import { Upload, notification } from 'antd';
import API_SERVICE from '../services/api-service';

export default function BulkUpload(props: any) {
    async function handleUpload(file: any) {
        let bodyFormData = new FormData();
        bodyFormData.append('file', file);
        let response: any;
        try {
            if (props.tableType === 'esc') {
                response =
                    await API_SERVICE.uploadFinancialEmployeeCostBulkSheet(
                        props.claimId,
                        bodyFormData,
                    );
            } else if (props.tableType === 'scr') {
                response = await API_SERVICE.uploadSubContractorCostBulkSheet(
                    props.claimId,
                    bodyFormData,
                );
            } else if (props.tableType === 'ewc') {
                response = await API_SERVICE.uploadExternalWorkerCostBulkSheet(
                    props.claimId,
                    bodyFormData,
                );
            } else if (props.tableType === 'cms') {
                response = await API_SERVICE.uploadConsumablesCostBulkSheet(
                    props.claimId,
                    bodyFormData,
                );
            }
            const {
                data: { costs, downloadUrl },
            } = response.data;
            if (props.callback) {
                props.callback(costs, downloadUrl);
            }
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        }
    }

    return (
        <Upload
            showUploadList={false}
            beforeUpload={(file) => {
                // if (file && file.type.includes('xlsx')) {
                //     handleUpload(file);
                // }
                handleUpload(file);
                return false;
            }}
        >
            {props.children}
        </Upload>
    );
}
