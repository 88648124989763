import React, {
    useContext,
    useState,
    useEffect,
    useRef,
    ReactDOM,
} from 'react';
import {
    Table,
    Input,
    Button,
    Popconfirm,
    Form,
    Space,
    Row,
    Col,
    Pagination,
    Typography,
    notification,
    Select,
    Radio,
    InputNumber,
} from 'antd';
import { FormInstance } from 'antd/lib/form';
import {
    PlusOutlined,
    MinusCircleOutlined,
    SaveOutlined,
} from '@ant-design/icons';
import { PrimaryTooltip } from 'components/index';
import API_SERVICE from 'services/api-service';
import { withRouter } from 'react-router';

const { Text } = Typography;
const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
    key: string;
    name: string;
    age: string;
    address: string;
}

interface EditableRowProps {
    index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: keyof Item;
    record: Item;
    handleSave: (record: Item) => void;
    inputType: 'number' | 'text' | 'select' | 'radio' | 'percentage';
    options: Array<any>;
}

const EditableCell: React.FC<EditableCellProps> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    inputType,
    options,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<any>(null);
    const form = useContext(EditableContext)!;

    //@ts-ignore
    useEffect(() => {
        form.setFieldsValue({ [dataIndex]: record?.[dataIndex] });
    }, [record?.[dataIndex]]);

    /*useEffect(() => {
        if (editing) {
            inputRef.current!.focus();
        }
    }, [editing]);*/

    // const toggleEdit = () => {
    //     setEditing(!editing);
    //     form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    // };

    const handleIsConnectedChange = async (event: any) => {
        if (event.target.value) {
            form.setFieldsValue({ adjustmentsPercentage: 100 });
        } else {
            form.setFieldsValue({ adjustmentsPercentage: 65 });
        }
        save();
    };

    const save = async () => {
        try {
            const values = await form.validateFields();

            // toggleEdit();
            handleSave({ ...record, ...values });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = (
            <Form.Item
                style={{ margin: 0 }}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `Field required.`,
                    },
                ]}
            >
                {inputType === 'select' ? (
                    <Select ref={inputRef} onSelect={save} onBlur={save}>
                        {options.map((item, i) => {
                            return (
                                <Select.Option value={item.id}>
                                    {item.name}
                                </Select.Option>
                            );
                        })}
                    </Select>
                ) : inputType === 'number' ? (
                    <InputNumber
                        formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        //@ts-ignore
                        parser={(value) => value.replace(/\£\s?|(,*)/g, '')}
                        //@ts-ignore
                        addonBefore={'£'}
                        ref={inputRef}
                        onPressEnter={save}
                        onBlur={save}
                    />
                ) : inputType === 'percentage' ? (
                    <InputNumber
                        min={0}
                        max={100}
                        formatter={(value) => `${value}`}
                        //@ts-ignore
                        parser={(value) => value.replace('%', '')}
                        //@ts-ignore
                        addonAfter={'%'}
                        ref={inputRef}
                        onPressEnter={save}
                        onBlur={save}
                    />
                ) : (
                    <Input ref={inputRef} onPressEnter={save} onBlur={save} />
                )}
            </Form.Item>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

interface DataType {
    key: React.Key;
    id: null;
    name: string;
    consumableId: string;
    description: string;
    netAmount: string;
    rndAllocationPercentage: string;
    total: string;
}

interface EditableTableState {
    compareCount: number;
    dataSource: DataType[];
    count: number;
    fetching: boolean;
    total: number;
    pagination: { page: number; perpage: any };
    financialDetails: Array<any>;
    claimId: any;
    updateData: Array<any>;
    saving: boolean;
    claimDetails: any;
    columns: any;
    consumablesTypeList: Array<any>;
    consumablesCostTotal: { netAmount: any; rnd: any; total: any };
}

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

class Consumables extends React.Component<
    EditableTableProps,
    EditableTableState
> {
    columns: (ColumnTypes[number] & {
        editable?: boolean;
        dataIndex: string;
    })[];

    constructor(props: EditableTableProps) {
        super(props);

        this.columns = [
            {
                title: '',
                dataIndex: 'del',
                width: '50px',
                //@ts-ignore
                render: (_, record: { key: React.Key }) =>
                    this.state.dataSource.length >= 1 ? (
                        <Popconfirm
                            title="Sure to delete?"
                            onConfirm={() => this.handleDelete(record)}
                        >
                            <div className="text-primary cursor-pointer text-center">
                                <MinusCircleOutlined />
                            </div>
                        </Popconfirm>
                    ) : null,
            },
            {
                title: 'S.No',
                dataIndex: 'sNO',
                width: '70px',
                render: (a: any, v: any, index: number) => (
                    <Text>
                        {(this.state.pagination.page - 1) *
                            this.state.pagination.perpage +
                            index +
                            1}
                    </Text>
                ),
            },
            {
                title: (
                    <PrimaryTooltip
                        title="prompt text"
                        text="Consumable Name"
                    />
                ),
                dataIndex: 'name',
                width: '200px',
                editable: true,
            },
            {
                title: (
                    <PrimaryTooltip
                        title="prompt text"
                        text="Consumable Type"
                    />
                ),
                dataIndex: 'consumableId',
                width: '200px',
                editable: true,
            },
            {
                title: (
                    <PrimaryTooltip title="prompt text" text="Description" />
                ),
                dataIndex: 'description',
                width: '180px',
                editable: true,
            },
            {
                title: (
                    <PrimaryTooltip
                        title="prompt text"
                        text="Net Amount (GBP)"
                    />
                ),
                dataIndex: 'netAmount',
                width: '180px',
                editable: true,
            },
            {
                title: (
                    <PrimaryTooltip
                        title="prompt text"
                        text="R&D Allocation (%)"
                    />
                ),
                dataIndex: 'rndAllocationPercentage',
                width: '150px',
                editable: true,
            },
            {
                title: (
                    <PrimaryTooltip title="prompt text" text="Total (GBP)" />
                ),
                dataIndex: 'total',
                width: '150px',
                editable: false,
                render: (a: any) => (
                    <Text>
                        {`£ ${a}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </Text>
                ),
            },
        ];

        this.state = {
            compareCount: 0,
            dataSource: [],
            count: 0,
            fetching: false,
            total: 10,
            pagination: { page: 1, perpage: '10' },
            financialDetails: [],
            claimId: null,
            updateData: [],
            saving: false,
            columns: this.columns,
            claimDetails: {},
            consumablesTypeList: [],
            consumablesCostTotal: { netAmount: '', rnd: '', total: '' },
        };
    }

    componentDidMount(): void {
        const {
            // @ts-ignore
            match: { params },
        } = this.props;
        this.getConsumablesTypeList();
        this.setState({ claimId: params.claimId }, () => {
            this.getConsumablesCostList();
        });
    }

    setUpdatedColumns = () => {
        const editableSMEColumn = [
            {
                title: (
                    <PrimaryTooltip
                        title="prompt text"
                        text="SME Allocation (%)"
                    />
                ),
                dataIndex: 'smeAllocation',
                width: '200px',
                editable: true,
            },
            {
                title: (
                    <PrimaryTooltip
                        title="prompt text"
                        text="SME Allocation (GBP)"
                    />
                ),
                dataIndex: 'smeTotal',
                width: '200px',
                editable: false,
                render: (a: any) => (
                    <Text>
                        {`£ ${a ? a : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </Text>
                ),
            },
        ];

        const nonEditableSMEColumn = [
            {
                title: (
                    <PrimaryTooltip
                        title="prompt text"
                        text="SME Allocation (%)"
                    />
                ),
                dataIndex: 'smeAllocation',
                width: '200px',
                editable: false,
            },
            {
                title: (
                    <PrimaryTooltip
                        title="prompt text"
                        text="SME Allocation (GBP)"
                    />
                ),
                dataIndex: 'smeTotal',
                width: '200px',
                editable: false,
                render: (a: any) => (
                    <Text>
                        {`£ ${a ? a : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </Text>
                ),
            },
        ];

        const nonEditableRDECColumn = [
            {
                title: (
                    <PrimaryTooltip
                        title="prompt text"
                        text="RDEC Allocation (%)"
                    />
                ),
                key: 'rdecAllocation',
                width: '150px',
                editable: false,
                render: (a: any) => (
                    <Text>{`${
                        100 - (a.smeAllocation ? a.smeAllocation : 0)
                    } %`}</Text>
                ),
            },
            {
                title: (
                    <PrimaryTooltip
                        title="prompt text"
                        text="RDEC Allocation (GBP)"
                    />
                ),
                dataIndex: 'rdecTotal',
                width: '150px',
                editable: false,
                render: (a: any) => (
                    <Text>
                        {`£ ${a ? a : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </Text>
                ),
            },
        ];
        if (this.state.claimDetails?.type?.id === 1) {
            let newColumns = [...this.columns, ...nonEditableRDECColumn];
            this.setState({ columns: newColumns });
        } else if (this.state.claimDetails?.type?.id === 2) {
            let newColumns = [...this.columns, ...nonEditableSMEColumn];
            this.setState({ columns: newColumns });
        } else if (this.state.claimDetails?.type?.id === 3) {
            let newColumns = [
                ...this.columns,
                ...editableSMEColumn,
                ...nonEditableRDECColumn,
            ]; // @media screen and (min-width: 780px) {
            //     .addEmployeeBox {
            //         display: flex;
            //         flex-direction: column;
            //     }
            // }

            this.setState({ columns: newColumns });
        }
    };

    handleDelete = (row: any) => {
        if (row.id) {
            this.handleDeleteConsumablesCost(row.id);
        } else {
            const dataSource = [...this.state.dataSource];
            this.setState({
                dataSource: dataSource.filter((item) => item.key !== row.key),
            });
        }
    };

    handleAdd = () => {
        const { count, dataSource } = this.state;
        const newData: DataType = {
            key: count,
            id: null,
            name: '',
            consumableId: '',
            description: '',
            netAmount: '',
            rndAllocationPercentage: '',
            total: '',
        };
        this.setState({
            dataSource: [...dataSource, newData],
            count: count + 1,
        });
    };

    handleSave = (row: DataType) => {
        //to add new dummy row to frontend list
        const newData = [...this.state.dataSource];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });

        //to update the reqObj for create/update
        const updateData: Array<any> = [...this.state.updateData];
        const isExist = updateData.findIndex((item) => row.key === item.key);
        if (isExist > -1) {
            const updateItem = updateData[isExist];
            updateData.splice(isExist, 1, {
                ...updateItem,
                ...row,
            });
        } else {
            updateData.push(row);
        }

        this.setState({ dataSource: newData, updateData }, () => {
            console.log('updateData', this.state.updateData);
        });
    };

    getConsumablesTypeList = async () => {
        try {
            await API_SERVICE.consumablesTypeList().then((result) => {
                const {
                    data: { data },
                } = result;
                this.setState({ consumablesTypeList: data.items });
            });
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        }
    };

    getConsumablesCostList = async () => {
        this.setState({ fetching: true });
        const params = { ...this.state.pagination };
        try {
            const {
                data: { data },
            } = await API_SERVICE.consumablesCostList(
                this.state.claimId,
                params,
            );
            let mData = data.items.map((item: any, i: number) => {
                item.key = i;
                return item;
            });
            this.setState({
                dataSource: mData,
                count: data.items.length,
                total: data.total,
            });
            this.getConsumablesCostTotal();
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        } finally {
            this.setState({ fetching: false });
        }
    };

    getClaimsDetails = async () => {
        try {
            const {
                data: { data },
            } = await API_SERVICE.getClaimById(this.state.claimId);
            this.setState({ claimDetails: data }, () => {
                console.log(data);
                this.setUpdatedColumns();
            });
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        }
    };

    getConsumablesCostTotal = async () => {
        try {
            await API_SERVICE.consumablesCostTotal(this.state.claimId).then(
                (result) => {
                    const {
                        data: { data },
                    } = result;
                    this.setState({ consumablesCostTotal: data });
                    this.getClaimsDetails();
                },
            );
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        }
    };

    handleSaveConsumablesCost = async () => {
        let reqObj = {} as any;
        reqObj['costs'] = this.state.updateData;
        if (
            this.state.dataSource.length > this.state.compareCount &&
            reqObj['costs'].length === 0
        ) {
            notification.error({
                message: `Please Check & fill all the required Fields`,
            });
            return;
        }
        if (
            this.state.dataSource.length === this.state.compareCount &&
            reqObj['costs'].length === 0
        ) {
            notification.success({
                message: `Details Already Saved`,
            });
            return;
        }
        this.setState({ saving: true });
        await API_SERVICE.consumablesCostCreate(this.state.claimId, reqObj)
            .then((d) => {
                this.getConsumablesCostList();
                this.setState({ updateData: [] });
                notification.success({
                    message: 'Success',
                });
            })
            .catch((e) => {
                console.log(e);
                notification.error({ message: API_SERVICE.handleErrors(e) });
            })
            .finally(() => {
                this.setState({ saving: false });
            });
    };

    handleDeleteConsumablesCost = async (id: any) => {
        this.setState({ saving: true });
        API_SERVICE.consumablesCostDelete(this.state.claimId, id)
            .then((d) => {
                this.getConsumablesCostList();
                notification.success({
                    message: 'Success',
                });
            })
            .catch((e) => {
                console.log(e);
                notification.error({ message: API_SERVICE.handleErrors(e) });
            })
            .finally(() => {
                this.setState({ saving: false });
            });
    };

    render() {
        const { dataSource } = this.state;
        const components = {
            body: {
                row: EditableRow,
                cell: EditableCell,
            },
        };
        //@ts-ignore
        const columns = this.state.columns.map((col) => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: (record: DataType) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: this.handleSave,
                    inputType:
                        col.dataIndex === 'consumableId'
                            ? 'select'
                            : col.dataIndex === 'netAmount' ||
                              col.dataIndex === 'total'
                            ? 'number'
                            : col.dataIndex === 'rndAllocationPercentage' ||
                              col.dataIndex === 'smeAllocation' ||
                              col.dataIndex === 'rdecAllocation'
                            ? 'percentage'
                            : 'text',
                    options: this.state.consumablesTypeList,
                }),
            };
        });
        return (
            <>
                <Row
                    className={`${
                        this.state.claimDetails.stageId >= 2 &&
                        'cursor-not-allowed'
                    }`}
                >
                    <Table
                        loading={this.state.fetching}
                        scroll={{ x: true, y: 'calc(100vh - 280px)' }}
                        components={components}
                        rowClassName={() =>
                            `${
                                this.state.claimDetails.stageId >= 2 &&
                                'pointer-none'
                            } editable-row`
                        }
                        bordered
                        dataSource={dataSource}
                        columns={columns as ColumnTypes}
                        pagination={false}
                    />
                </Row>
                <div className="d-flex align-items-center justify-content-between my-3 addEmployeeBox">
                    <Button
                        disabled={this.state.claimDetails.stageId >= 2}
                        className="black-button"
                        onClick={this.handleAdd}
                        icon={<PlusOutlined />}
                    >
                        Add Consumable
                    </Button>
                    <div className="d-flex align-items-center">
                        <Space>
                            <Form.Item
                                className="mb-0"
                                style={{ width: '220px' }}
                            >
                                <Input
                                    disabled
                                    value={`Net Amount: ${
                                        this.state.consumablesCostTotal
                                            .netAmount &&
                                        this.state.consumablesCostTotal.netAmount.toFixed(
                                            2,
                                        )
                                    } GBP`}
                                />
                            </Form.Item>
                            {/*<Form.Item className="mb-0" style={{width: '220px'}}>
                                <Input disabled value={`${this.state.consumablesCostTotal.rnd} GBP`} />
                            </Form.Item>*/}
                            <Form.Item
                                className="mb-0"
                                style={{ width: '220px' }}
                            >
                                <Input
                                    disabled
                                    value={`Total: ${
                                        this.state.consumablesCostTotal.total &&
                                        this.state.consumablesCostTotal.total.toFixed(
                                            2,
                                        )
                                    } GBP`}
                                />
                            </Form.Item>
                        </Space>
                    </div>
                </div>
                <Row>
                    <Col xs={24} className={'linear justify-content-between'}>
                        <Pagination
                            showSizeChanger
                            showQuickJumper
                            defaultCurrent={1}
                            total={this.state.total}
                            onChange={(page, size) => {
                                this.setState(
                                    {
                                        pagination: {
                                            page: page,
                                            perpage: size,
                                        },
                                    },
                                    this.getConsumablesCostList,
                                );
                            }}
                        />

                        <Button
                            disabled={this.state.claimDetails.stageId >= 2}
                            type={'primary'}
                            shape="round"
                            className="theme-button primary"
                            onClick={this.handleSaveConsumablesCost}
                            icon={<SaveOutlined />}
                        >
                            Save
                        </Button>
                    </Col>
                </Row>
            </>
        );
    }
}

// @ts-ignore
export default withRouter(Consumables);
// @ts-ignore
// ReactDOM.render(<EditableTable />, mountNode);
