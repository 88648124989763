import React, { CSSProperties, useEffect, useState } from 'react';
import {
    Button,
    Menu,
    notification,
    Space,
    Table,
    Tag,
    Typography,
    Modal,
    Pagination,
    Form,
    Select,
    Row,
    Col,
    Progress,
    Input,
    Popconfirm,
    Upload,
} from 'antd';
import API_SERVICE from '../../../services/api-service';
import './index.scss';
import {
    DownloadOutlined,
    FileExcelOutlined,
    ImportOutlined,
    FileTextOutlined,
} from '@ant-design/icons';
import pound from 'shared/assets/icons/active_pound.png';
import EmployeesCost from './EmployeesCost/EmployeesCost';
import SubContractor from './SubContractor/SubContractor';
import EditableTable from '../../../components/EditableTable';
import ExternalWorkers from './ExternalWorkers/ExternalWorkers';
import Consumables from './Consumables/Consumables';
import { useTranslation } from 'react-i18next';
import { BulkUpload, ConfirmationModal } from '../../../components';
import BulkUploadListModal from '../../../components/BulkUploadListModal';
import XeroFetchModal from './Xero/EmployeeCost/XeroFetchModal';

const { Text, Title, Link } = Typography;
export default function FinancialDetailsPage(props: any) {
    const { t } = useTranslation();
    const xeroForm = React.createRef();
    const tableData: any = [];

    const [tableType, setTableType] = useState({} as any);
    const [claimId, setClaimId] = useState(null);
    const [claimDetails, setClaimDetails] = useState({} as any);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isXeroModalVisible, setIsXeroModalVisible] = useState(false);
    const [bulkUploadModal, setBulkUploadModal] = useState(false);
    const [bulkUploadedData, setBulkUploadedData] = useState([] as any);
    const [bulkUploadedURL, setBulkUploadedURL] = useState([] as any);
    const [xeroAuthStatus, setXeroAuthStatus] = useState(false);
    const [xeroTabledata, setXeroTabledata] = useState([] as any);
    const [descriptionDetails, setDescriptionDetails] = useState({
        descriptionEmployeeCost: '',
        descriptionExternalWorkersCost: '',
        descriptionSubContractorsData: '',
        descriptionConsumablesData: '',
    });

    const showBulkUploadModal = (data: any, downloadUrl: any) => {
        setBulkUploadedData(data);
        setBulkUploadedURL(downloadUrl);
        setBulkUploadModal(true);
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setIsXeroModalVisible(false);
    };

    const handleTableTypeChange = (value: any) => {
        setTableType(value);
    };

    const handlePulldata = (data: any) => {
        if (tableType === 'esc') {
            setXeroTabledata(data);
        }

        // will be enable later
        /*else if(tableType === 'scr') {
           setXeroTabledata(data);
       } else if(tableType === 'ewc') {
           setXeroTabledata(data);
       } else if(tableType === 'cms') {
           setXeroTabledata(data);
       }*/
    };

    const handleXeroClick = async () => {
        claimId &&
            claimDetails &&
            claimDetails.companyId &&
            true &&
            setIsXeroModalVisible(true);
    };

    const downdloadSample = async () => {
        try {
            if (tableType === 'esc') {
                // @ts-ignore
                window.open(
                    API_SERVICE.downloadEmoployeeCostSampleSheet(claimId),
                );
            } else if (tableType === 'scr') {
                window.open(
                    API_SERVICE.downloadSubContractorCostSampleSheet(claimId),
                );
            } else if (tableType === 'ewc') {
                window.open(
                    API_SERVICE.downloadExternalWorkerCostSampleSheet(claimId),
                );
            } else if (tableType === 'cms') {
                window.open(
                    API_SERVICE.downloadConsumablesCostSampleSheet(claimId),
                );
            }
        } catch (e) {
            notification.error({
                message: t(API_SERVICE.handleErrors(e)),
            });
        }
    };

    const checkIsXeroAuthenticated = async () => {
        if (claimDetails && claimDetails.companyId) {
            try {
                const {
                    data: { data },
                } = await API_SERVICE.checkXeroAuthStatus({
                    companyId: claimDetails.companyId,
                });
                setXeroAuthStatus(data.status);
            } catch (e) {
                notification.error({ message: API_SERVICE.handleErrors(e) });
            }
        }
    };

    const getToken = () => {
        let user = JSON.parse(localStorage.getItem('user') || '');
        if (user && user.token) {
            return user.token;
        }
        return null;
    };

    const setDescription = async (value: any) => {
        try {
            if (tableType === 'esc') {
                await API_SERVICE.financialEmployeeCostDescriptionUpdate(
                    claimId,
                    value,
                );
            } else if (tableType === 'scr') {
                await API_SERVICE.financialSubContractorCostDescriptionUpdate(
                    claimId,
                    value,
                );
            } else if (tableType === 'ewc') {
                await API_SERVICE.externalWorkerCostDescriptionUpdate(
                    claimId,
                    value,
                );
            } else if (tableType === 'cms') {
                await API_SERVICE.financialConsumablesCostDescriptionUpdate(
                    claimId,
                    value,
                );
            }
            notification.success({
                message: 'Description Updated',
            });
            handleCancel();
        } catch (e) {
            notification.error({
                message: t(API_SERVICE.handleErrors(e)),
            });
        }
    };

    const getClaimsDetails = async (cId: any) => {
        try {
            const {
                data: { data },
            } = await API_SERVICE.getClaimById(cId);
            setClaimDetails(data);
            setDescriptionDetails({
                descriptionEmployeeCost: data.descriptionEmployeeCost,
                descriptionExternalWorkersCost:
                    data.descriptionExternalWorkersCost,
                descriptionSubContractorsData:
                    data.descriptionSubContractorsData,
                descriptionConsumablesData: data.descriptionConsumablesData,
            });
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        }
    };

    useEffect(() => {
        setTableType('esc');
        const {
            match: { params },
        } = props;
        setClaimId(params.claimId);
        getClaimsDetails(params.claimId);
    }, []);

    useEffect(() => {
        checkIsXeroAuthenticated();
    }, [claimDetails]);

    return (
        <div className={`financial-details-wrapper page-wrapper `}>
            <div>
                <Row
                    className="title-bar"
                    align={'middle'}
                    justify={'space-between'}
                    gutter={20}
                    wrap={false}
                >
                    <Col>
                        <div className="d-flex align-items-center">
                            <div className={'title d-flex align-items-center'}>
                                <img
                                    src={pound}
                                    alt="pound"
                                    style={{
                                        height: '35px',
                                        marginRight: '7px',
                                    }}
                                />
                                Financial Details
                            </div>

                            <Form
                                style={{ width: '250px', marginLeft: '15px' }}
                            >
                                <Row gutter={20}>
                                    <Col flex={'auto'}>
                                        <Form.Item
                                            name="tableType"
                                            style={{ marginBottom: 0 }}
                                        >
                                            <Select
                                                showArrow={true}
                                                showSearch={false}
                                                defaultValue={'esc'}
                                                onSelect={handleTableTypeChange}
                                            >
                                                <Select.Option value={'esc'}>
                                                    EMPLOYEES COST
                                                </Select.Option>
                                                <Select.Option value={'scr'}>
                                                    SUB-CONTRACTOR R&D
                                                </Select.Option>
                                                <Select.Option value={'ewc'}>
                                                    EXTERNAL WORKERS COST
                                                </Select.Option>
                                                <Select.Option value={'cms'}>
                                                    CONSUMABLES R&D
                                                </Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </Col>
                    <Col className={'header-buttons'}>
                        <span>
                            <Space>
                                <Form>
                                    <Form.Item
                                        className={'mb-0'}
                                        label={''}
                                        name={'file'}
                                    >
                                        <BulkUpload
                                            claimId={claimId}
                                            tableType={tableType}
                                            callback={showBulkUploadModal}
                                        >
                                            <Button
                                                disabled={
                                                    claimDetails.stageId >= 2
                                                }
                                                shape="round"
                                                className="black-button my-3"
                                                icon={<FileExcelOutlined />}
                                            >
                                                Bulk Upload
                                            </Button>
                                        </BulkUpload>
                                    </Form.Item>
                                </Form>

                                <Button
                                    disabled={claimDetails.stageId >= 2}
                                    shape="round"
                                    onClick={downdloadSample}
                                    className="black-button my-3"
                                    icon={<DownloadOutlined />}
                                >
                                    Sample
                                </Button>
                            </Space>
                        </span>

                        <span className="mx-4">
                            <form
                                style={{ display: 'inline' }}
                                method="post"
                                action={API_SERVICE.authFormURL()}
                            >
                                <input
                                    style={{ display: 'none' }}
                                    type="text"
                                    name="token"
                                    value={getToken()}
                                />
                                <input
                                    style={{ display: 'none' }}
                                    type="text"
                                    name="redirectUrl"
                                    value={window.location.href}
                                />
                                <input
                                    style={{ display: 'none' }}
                                    type="text"
                                    name="companyId"
                                    value={claimDetails.companyId || null}
                                />

                                {xeroAuthStatus ? (
                                    <Button
                                        shape="round"
                                        htmlType={'button'}
                                        className="black-button my-3"
                                        icon={<ImportOutlined />}
                                        onClick={handleXeroClick}
                                    >
                                        Import from Xero
                                    </Button>
                                ) : (
                                    <Button
                                        disabled={claimDetails.stageId >= 2}
                                        shape="round"
                                        htmlType={'submit'}
                                        className="black-button my-3"
                                        icon={<ImportOutlined />}
                                    >
                                        Import from Xero
                                    </Button>
                                )}
                            </form>
                        </span>
                        <span>
                            <Button
                                disabled={claimDetails.stageId >= 2}
                                shape="round"
                                className="black-button my-3"
                                icon={<FileTextOutlined />}
                                onClick={showModal}
                            >
                                Add Description
                            </Button>
                        </span>
                    </Col>
                </Row>
                {/*<EditableTable />*/}
                {tableType === 'esc' && (
                    <EmployeesCost xeroTabledata={xeroTabledata} />
                )}
                {tableType === 'scr' && <SubContractor />}
                {tableType === 'ewc' && <ExternalWorkers />}
                {tableType === 'cms' && <Consumables />}
            </div>

            <Modal
                title="Add Description"
                footer={null}
                visible={isModalVisible}
                onCancel={handleCancel}
            >
                <Form
                    className={'forgot-password-form'}
                    fields={[]}
                    onFinish={setDescription}
                    requiredMark={false}
                    layout={'vertical'}
                    initialValues={descriptionDetails}
                >
                    {tableType === 'esc' && (
                        <Form.Item
                            style={{ flex: 1 }}
                            name="descriptionEmployeeCost"
                        >
                            <Input.TextArea
                                autoComplete={'off'}
                                placeholder="Add description here..."
                                rows={4}
                            />
                        </Form.Item>
                    )}

                    {tableType === 'scr' && (
                        <Form.Item
                            style={{ flex: 1 }}
                            name="descriptionSubContractorsData"
                        >
                            <Input.TextArea
                                autoComplete={'off'}
                                placeholder="Add description here..."
                                rows={4}
                            />
                        </Form.Item>
                    )}

                    {tableType === 'ewc' && (
                        <Form.Item
                            style={{ flex: 1 }}
                            name="descriptionExternalWorkersCost"
                        >
                            <Input.TextArea
                                autoComplete={'off'}
                                placeholder="Add description here..."
                                rows={4}
                            />
                        </Form.Item>
                    )}

                    {tableType === 'cms' && (
                        <Form.Item
                            style={{ flex: 1 }}
                            name="descriptionConsumablesData"
                        >
                            <Input.TextArea
                                autoComplete={'off'}
                                placeholder="Add description here..."
                                rows={4}
                            />
                        </Form.Item>
                    )}

                    <Form.Item>
                        <div className="text-right">
                            <Space>
                                <Button
                                    className="black-button"
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="primary"
                                    shape="round"
                                    htmlType="submit"
                                    className="theme-button primary"
                                >
                                    update
                                </Button>
                            </Space>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>

            {isXeroModalVisible && (
                <XeroFetchModal
                    claimId={claimId}
                    companyId={claimDetails.companyId}
                    callback={handlePulldata}
                    onClose={(e: boolean) => {
                        if (e) {
                            // refresh();
                        }
                        setIsXeroModalVisible(false);
                    }}
                />
            )}

            <BulkUploadListModal
                bulkUploadModal={bulkUploadModal}
                tableType={tableType}
                claimDetails={claimDetails}
                data={bulkUploadedData}
                url={bulkUploadedURL}
                callback={(a: boolean) => {
                    setBulkUploadModal(false);
                    if (a) {
                        setTableType(tableType);
                    }
                }}
            />
        </div>
    );
}
