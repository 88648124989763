import React, { CSSProperties, useEffect, useState } from 'react';
import './index.scss';
import {
    Button,
    Menu,
    Modal,
    notification,
    Space,
    Form,
    Typography,
    Input,
    Row,
    Col,
    Select,
    DatePicker, Checkbox,
} from 'antd';
import API_SERVICE from '../../../services/api-service';
import moment from 'moment';

const { Text } = Typography;


export default function ProjectCreateOrEditPage({ onClose, details, claimId }: any) {
    const [form] = Form.useForm();
    const [saving, setSaving] = useState(false);

    const [companies, setCompanies] = useState([] as any);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [employees, setEmployees] = useState([] as any);
    const [taxYears, setTaxYears] = useState([] as any);
    const [fetching, setFetching] = useState(false);
    const [claimTypes, setClaimTypes] = useState([] as any);
    const [startDate, setStartDate] = useState(null);
    const [internalDeadlineDate, setInternalDeadlineDate] = useState(null);

    const [isOngoing, setIsOngoing] = useState(false);

    function previousDateDisabled(current: any) {
        return current && current < moment().endOf('day');
    }
    function futureDateDisabled(current: any) {
        return current && current > moment().endOf('day');
    }
    function dateBeforeClaimStartDisabled(current: any) {
        if(startDate)
            return current && current < moment(startDate, "YYYY-MM-DD");
        else
            return current && current < moment(details && details.claimStartDate, "YYYY-MM-DD");
    }



    const getInitialValues = (details: any) => {
        if (details) {
            const obj = {
                id: details.id,
                claimId: details.claimId,
                name: details.name,
                startDate: moment(details.startDate),
                isOngoing: details.isOngoing
            }
            if(!details.isOngoing){
                //@ts-ignore
                obj.endDate = moment(details.endDate);
            }

            return obj;
        }
        else return {};
    }



    const handleDateFromChange = (value: any) => {
        setStartDate(value);
    }

    const onCheckboxChange = (e: { target: { checked: boolean } }) => {
        setIsOngoing(e.target.checked);
      };


    useEffect(() => {
        if(details?.isOngoing){
            setIsOngoing(true);
        }
    }, [details]);



    return (
        <Modal title={
            (<Space>
                <Text>{details ? 'Edit Project' : 'Add New Project'}</Text>
            </Space>)
        }
               width={500}
               visible={true}
               maskClosable={false}
               cancelText="Cancel"
               okText={details ? "Update" : "+ Add"}
               okType="primary"
               okButtonProps={{ shape: 'round' }}
               cancelButtonProps={{ shape: 'round', className: 'btn-dark' }}
               onOk={
                   () => {
                       form.submit();
                   }
               }
               onCancel={() => onClose()}>

            <Form
                form={form}
                initialValues={getInitialValues(details) || {}}
                fields={[]}
                onFinish={(value) => {
                    const reqObj = { ...value };
                    reqObj.startDate = moment(reqObj.startDate).format('YYYY-MM-DD');
                    reqObj.endDate = moment(reqObj.endDate).format('YYYY-MM-DD');
                    if(reqObj.isOngoing){
                        reqObj.isOngoing = 1;
                    }
                    else{
                        reqObj.isOngoing = 0;
                    }
                    setSaving(true);
                    if (details) {
                        // console.log(reqObj); return;
                        API_SERVICE.updateProject(claimId, details.id, reqObj).then((d) => {
                            notification.success({ message: 'Project updated successfully'});
                            onClose(true);
                        }).catch((e) => {
                            notification.error({ message: API_SERVICE.handleErrors(e) });
                        }).finally(() => {
                            setSaving(false);
                        });
                    } else {
                        // console.log(reqObj); return;
                        API_SERVICE.createProject(claimId, reqObj).then((d) => {
                            notification.success({ message: 'Project created successfully'});
                            onClose(true);
                        }).catch((e) => {
                            notification.error({ message: API_SERVICE.handleErrors(e) });
                        }).finally(() => {
                            setSaving(false);
                        });
                    }
                }}
                layout='horizontal'
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 14 }}
                requiredMark={true}
            >

                <Form.Item
                    label={'Project Name:'}
                    name={'name'}
                    rules={[
                        { required: true, message: 'Project name required' },
                    ]}
                >
                    <Input placeholder="enter project name here" />
                </Form.Item>

                <Form.Item
                    label={'Project Start Date:'}
                    name={'startDate'}
                    rules={[
                        { required: true, message: 'Start date required' },
                    ]}
                >
                    <DatePicker
                        disabledDate={futureDateDisabled}
                        // defaultValue={moment()}
                        // value={details ? details.claimStartDate : moment()}
                        placeholder="Select date (DD/MM/YYYY)"
                        format="DD-MM-YYYY"
                        onChange={handleDateFromChange}
                    />
                </Form.Item>

                <Form.Item
                    label={'Project End Date:'}
                    name={'endDate'}
                    rules={[
                        { required: !isOngoing ? true : false, message: 'End date required' },
                    ]}
                >
                    <DatePicker
                        disabledDate={dateBeforeClaimStartDisabled}
                        disabled={!isOngoing ? false : true}
                        placeholder="Select date (DD/MM/YYYY)"
                        format="DD-MM-YYYY"
                    />
                </Form.Item>

                <Form.Item
                    style={{marginBottom: 0}}
                    label={''}
                    name='isOngoing'
                    valuePropName="checked"
                    labelCol={{ span: 10 }}
                    wrapperCol={{ span: 14, offset: 10  }}
                >
                    <Checkbox checked={isOngoing} onChange={onCheckboxChange}>
                        Project is Ongoing
                    </Checkbox>
                </Form.Item>

            </Form>
        </Modal>
    );
}

