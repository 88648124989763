import React, { useEffect, useState } from 'react';
import './index.scss';
import StackedBarChart from '../../components/Charts/StackedBarChart';
import LineChart from 'components/Charts/LineChart';
import DonutChart from 'components/Charts/DonutChart';
import SingleDonutChart from 'components/Charts/SingleDonutChart';
import { DatePicker, notification, Select, Space, Spin } from 'antd';
import DeadlineContainerBox from 'components/Charts/DeadlineContainerBox';
import { Option } from 'antd/lib/mentions';
import API_SERVICE from 'services/api-service';
import moment from 'moment';

const Dashboard = () => {
    const [loading, setLoading] = useState(true);
    const [types, setTypes] = useState([]);
    const [userState, setUserState] = useState({
        active: 0,
        inactive: 0,
        pending: 0,
        verified: 0,
    });
    const [noOfCompanies, setNoOfCompanies] = useState({
        total: 0,
        sme: 0,
        nonsme: 0,
    });

    const [externalDeadlineClaims, setExternalDeadlineClaims] = useState({
        total: 0,
        incomplete: 0,
        complete: 0,
    });

    const [companyType, setCompanyType] = useState('sme');
    const [valueOfClaims, setValueOfClaims] = useState({});
    const [filteredValueOfClaims, setFilteredValueOfClaims] = useState({
        incomplete: [],
        complete: [],
    });

    const [selectedYear, setSelectedYear] = useState('2021');
    const [numberOfClaims, setNumberOfClaims] = useState({});
    const [filteredData, setFilteredData] = useState({
        newFields: [],
        isProgressField: [],
        completeField: [],
    });

    const getClaimTypes = async () => {
        try {
            const {
                data: { data },
            } = await API_SERVICE.getClaimTypes();
            setTypes(data.items);
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        }
    };

    const fetchNumberOfClaims = async () => {
        try {
            const {
                data: { data },
            } = await API_SERVICE.fetchNumberOfClaims();
            setNumberOfClaims({ ...data });
        } catch (err) {
            notification.error({
                message: API_SERVICE.handleErrors(err),
            });
        } finally {
            fetchValueOfClaims();
        }
    };

    function modifyNoofClaimsData() {
        let formattedData = {
            newFields: [],
            isProgressField: [],
            completeField: [],
        };
        formattedData.newFields.push(numberOfClaims[selectedYear]?.new.mixed);
        formattedData.newFields.push(
            numberOfClaims[selectedYear]?.inprogress.mixed,
        );
        formattedData.newFields.push(
            numberOfClaims[selectedYear]?.complete.mixed,
        );
        formattedData.isProgressField.push(
            numberOfClaims[selectedYear]?.new.sme,
        );
        formattedData.isProgressField.push(
            numberOfClaims[selectedYear]?.inprogress.sme,
        );
        formattedData.isProgressField.push(
            numberOfClaims[selectedYear]?.complete.sme,
        );
        formattedData.completeField.push(
            numberOfClaims[selectedYear]?.new.rdec,
        );
        formattedData.completeField.push(
            numberOfClaims[selectedYear]?.inprogress.rdec,
        );
        formattedData.completeField.push(
            numberOfClaims[selectedYear]?.complete.rdec,
        );
        setFilteredData(formattedData);
    }

    let years = [];
    const object = numberOfClaims;

    for (const key in object) {
        years.push(key);
    }

    const handleSelectYear = (value) => {
        setSelectedYear(value);
        modifyNoofClaimsData();
    };

    //Value Of Claims Graph Function

    const fetchValueOfClaims = async () => {
        try {
            const {
                data: { data },
            } = await API_SERVICE.valueOfClaims();
            setValueOfClaims({ ...data });
        } catch (e) {
            notification.error({
                message: API_SERVICE.handleErrors(e),
            });
        }
    };

    let valueOfClaimsYears = [];
    const object1 = valueOfClaims;

    for (const key in object1) {
        valueOfClaimsYears.push(key);
    }

    const handleCompanyType = (value) => {
        value = value.toLowerCase();
        setCompanyType(value);
        modifyValueOfClaims(value);
    };

    function modifyValueOfClaims(ctype) {
        if (ctype === 'sme') {
            filteringDataWithType(ctype);
        } else if (ctype === 'rdec') {
            filteringDataWithType(ctype);
        } else if (ctype === 'mixed') {
            filteringDataWithType(ctype);
        }
    }

    function filteringDataWithType(type) {
        let formattedData = {
            incomplete: [],
            complete: [],
        };
        for (let i = 0; i < valueOfClaimsYears.length; i++) {
            formattedData.complete.push(
                valueOfClaims[valueOfClaimsYears[i]][type].complete,
            );
            formattedData.incomplete.push(
                valueOfClaims[valueOfClaimsYears[i]][type].incomplete,
            );
        }
        return setFilteredValueOfClaims(formattedData);
    }

    //3rd Graph Function

    const fetchNoOfCompanies = async () => {
        try {
            const {
                data: { data },
            } = await API_SERVICE.fetchNoOfCompanies();
            setNoOfCompanies({ ...data });
        } catch (e) {
            notification.error({
                message: API_SERVICE.handleErrors(e),
            });
        } finally {
            fetchActiveUsers();
        }
    };

    //4th Graph Function
    const fetchActiveUsers = async () => {
        try {
            const {
                data: { data },
            } = await API_SERVICE.getActiveUser();
            setUserState({ ...data });
        } catch (e) {
            notification.error({
                message: API_SERVICE.handleErrors(e),
            });
        } finally {
            setLoading(false);
        }
    };

    const handleExternalDeadlineClaims = async (value) => {
        let selectedDate = moment(value).format('YYYY-MM-DD');

        try {
            const {
                data: { data },
            } = await API_SERVICE.externalDeadlineClaims(selectedDate);
            setExternalDeadlineClaims({ ...data });
        } catch (e) {
            notification.error({
                message: API_SERVICE.handleErrors(e),
            });
        }
    };

    useEffect(() => {
        fetchNumberOfClaims();
        fetchNoOfCompanies();
        getClaimTypes();
        handleExternalDeadlineClaims(moment(new Date()).format('YYYY-MM-DD'));
    }, []);

    useEffect(() => {
        modifyNoofClaimsData(selectedYear);
    }, [selectedYear, numberOfClaims]);

    useEffect(() => {
        modifyValueOfClaims(companyType);
    }, [companyType, valueOfClaims]);

    if (loading) {
        return (
            <div
                style={{
                    display: 'grid',
                    height: '100vh',
                    width: '100vw',
                    placeItems: 'center',
                }}
            >
                <Spin size="large" />
            </div>
        );
    }

    return (
        <>
            <div className="chartWrapper">
                <div className="chart">
                    <div className="chart__top">
                        <h3>
                            Number of Claims | Tax year{' '}
                            {`${selectedYear} - ${parseInt(selectedYear) + 1}`}
                        </h3>
                        <Select
                            placeholder="Select a year"
                            style={{
                                width: 140,
                            }}
                            defaultValue="2021"
                            onChange={handleSelectYear}
                        >
                            {years.map((y) => {
                                return (
                                    <Option key={y} value={y}>
                                        {`${y} - ${parseInt(y) + 1}`}
                                    </Option>
                                );
                            })}
                        </Select>
                    </div>
                    <div className="chart__container">
                        <StackedBarChart
                            claimTypes={types}
                            key={+new Date()}
                            filteredData={filteredData}
                        />
                    </div>
                </div>
                <div className="chart">
                    <div className="chart__top">
                        <h3>
                            Value of Claims | Type :{' '}
                            {companyType.toUpperCase() ===
                                types[0].name.toUpperCase() &&
                                types[0].displayName.toUpperCase()}
                            {companyType.toUpperCase() ===
                                types[1].name.toUpperCase() &&
                                types[1].displayName.toUpperCase()}
                            {companyType.toUpperCase() ===
                                types[2].name.toUpperCase() &&
                                types[2].displayName.toUpperCase()}
                        </h3>{' '}
                        <Select
                            defaultValue="SME"
                            style={{
                                width: 120,
                            }}
                            onChange={handleCompanyType}
                        >
                            {types.map((t) => (
                                <Option key={t.id} value={t.name}>
                                    {t.displayName}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    <div className="chart__container">
                        <LineChart
                            key={+new Date()}
                            filteredValueOfClaims={filteredValueOfClaims}
                            valueOfClaimsYears={valueOfClaimsYears}
                        />
                    </div>
                </div>
            </div>
            <div className="chartWrapper">
                <div className="chart">
                    <DonutChart
                        sme={noOfCompanies.sme}
                        nonsme={noOfCompanies.nonsme}
                    />
                </div>
                <div className="chart verified-users">
                    <div className="verified-users-left">
                        <p>Pending Verification</p>
                        <p># of Users</p>
                        <p className="pending-users-value">
                            {userState.pending}
                        </p>
                    </div>
                    <div className="verified-users-right">
                        <p>
                            Verified Users :
                            <span className="verified-users-value">
                                {userState.verified}
                            </span>
                        </p>
                        <div className="verified-users-donut-container">
                            <SingleDonutChart
                                text="Active"
                                value={userState.active}
                            />

                            <SingleDonutChart
                                text="InActive"
                                value={userState.inactive}
                            />
                        </div>
                    </div>
                </div>
                <div className="chart deadline-container">
                    <h3>
                        External Deadline Before :
                        <DatePicker
                            onChange={handleExternalDeadlineClaims}
                            placeholder="Select date (DD/MM/YYYY)"
                            format="DD-MM-YYYY"
                            defaultValue={moment(new Date(), 'YYYY-MM-DD')}
                            style={{ width: '30%', marginLeft: '15px' }}
                        />
                    </h3>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                            gap: '20px',
                            flexWrap: 'wrap',
                        }}
                    >
                        <DeadlineContainerBox
                            value={externalDeadlineClaims.complete}
                            text="Complete"
                        />
                        <DeadlineContainerBox
                            value={externalDeadlineClaims.incomplete}
                            text="Incomplete"
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
