import React, { CSSProperties, useEffect, useState } from 'react';
import './index.scss';
import {
    Button,
    Menu,
    Modal,
    notification,
    Space,
    Form,
    Typography,
    Input,
    Row,
    Col,
    Select,
    DatePicker,
    Divider,
} from 'antd';
import API_SERVICE from '../../services/api-service';
import moment from 'moment';
import AccessControl, { accessIncludes } from '../../services/AccessControl';
import { EditOutlined } from '@material-ui/icons';
import { getUser } from 'services/local-storage';

const { Text } = Typography;

export default function ClaimCreateOrEditPage({ onClose, details }: any) {
    const [form] = Form.useForm();
    const [saving, setSaving] = useState(false);
    const user = getUser();
    const roleId = user?.user?.roleIds[0];
    const companyId = user?.user?.companyId[0];
    const [companies, setCompanies] = useState([] as any);
    const [selectedCompany, setSelectedCompany] = useState(companyId || null);
    const [employees, setEmployees] = useState([] as any);
    const [taxYears, setTaxYears] = useState([] as any);
    const [fetching, setFetching] = useState(false);
    const [claimTypes, setClaimTypes] = useState([] as any);
    const [claimFromDate, setClaimFromDate] = useState(null);
    const [internalDeadlineDate, setInternalDeadlineDate] = useState(null);

    function previousDateDisabled(current: any) {
        return current && current < moment().endOf('day');
    }
    function futureDateDisabled(current: any) {
        return current && current > moment().endOf('day');
    }
    function dateBeforeClaimStartDisabled(current: any) {
        if (claimFromDate)
            return current && current < moment(claimFromDate, 'YYYY-MM-DD');
        else
            return (
                current &&
                current <
                    moment(details && details.claimStartDate, 'YYYY-MM-DD')
            );
    }

    function dateBeforeInternalDeadlineDisabled(current: any) {
        if (internalDeadlineDate)
            return (
                current && current < moment(internalDeadlineDate, 'YYYY-MM-DD')
            );
        else
            return (
                current &&
                current <
                    moment(details && details.deadlineInternal, 'YYYY-MM-DD')
            );
    }

    const fetchCompanies = async () => {
        try {
            const {
                data: { data },
            } = await API_SERVICE.getCompanies({ perpage: 1000 });
            setCompanies(data.items);
            if (details) {
                fetchEmployees(details.companyId);
            }
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        }
    };

    const fetchEmployees = async (cId: any = null) => {
        try {
            const {
                data: { data },
            } = await API_SERVICE.getEmployees(cId || selectedCompany || 0);
            setEmployees(data.items);
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        }
    };

    const getInitialValues = (details: any) => {
        if (details) {
            const obj = {
                companyId: null,
                financialYear: null,
                claimStartDate: moment(),
                claimEndDate: moment(),
                deadlineInternal: moment(),
                deadlineExternal: moment(),
                employeeCostAsPerAcc: null,
                typeId: null,
                value: null,
                managerId: null,
            };
            obj.companyId = details.companyId;
            obj.financialYear = details.financialYear;
            obj.claimStartDate = moment(details.claimStartDate);
            obj.claimEndDate = moment(details.claimEndDate);
            obj.deadlineInternal = moment(details.deadlineInternal);
            obj.deadlineExternal = moment(details.deadlineExternal);
            obj.employeeCostAsPerAcc = details.employeeCostAsPerAcc;
            obj.typeId = details.typeId;
            obj.value = details.value;
            obj.managerId = details.managerId;

            return obj;
        } else return {};
    };

    const setFinancialYears = () => {
        let currentYear = moment().year();
        let lastYear = moment().subtract(1, 'year').year();
        let lastToLastYear = moment().subtract(2, 'year').year();
        let thirdLastYear = moment().subtract(3, 'year').year();
        let taxYears = [
            {
                value: lastYear,
                name: `${lastYear} - ${currentYear}`,
            },
            {
                value: lastToLastYear,
                name: `${lastToLastYear} - ${lastYear}`,
            },
            {
                value: thirdLastYear,
                name: `${thirdLastYear} - ${lastToLastYear}`,
            },
        ];
        setTaxYears(taxYears);
    };

    const getClaimTypes = async () => {
        setFetching(true);
        try {
            const {
                data: { data },
            } = await API_SERVICE.getClaimTypes();
            setClaimTypes(data.items);
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        } finally {
            setFetching(false);
        }
    };

    const handleCompanyChange = (value: any) => {
        setSelectedCompany(value);
    };
    const handleDateFromChange = (value: any) => {
        setClaimFromDate(value);
    };
    const handleInternalDeadlineChange = (value: any) => {
        setInternalDeadlineDate(value);
    };

    useEffect(() => {
        fetchCompanies();
        setFinancialYears();
        getClaimTypes();
    }, []);

    useEffect(() => {
        if (selectedCompany) {
            fetchEmployees();
            if (roleId === 4) {
                form.setFieldsValue({
                    companyId: companyId,
                });
            }
        }
    }, [selectedCompany]);

    return (
        <Modal
            title={
                <Space>
                    <Text>{details ? 'Edit Claim' : 'Add a New Claim'}</Text>
                </Space>
            }
            width={400}
            footer={false}
            visible={true}
            maskClosable={false}
            onCancel={() => onClose()}
            // cancelText="Cancel"
            // okText={details ? "Update" : "+ Add"}
            // okType="primary"
            // okButtonProps={{ shape: 'round' }}
            // cancelButtonProps={{ shape: 'round', className: 'btn-dark' }}
            // onOk={
            //     () => {
            //         form.submit();
            //     }
            // }
            // onCancel={() => onClose()}
        >
            <Form
                form={form}
                initialValues={getInitialValues(details) || {}}
                fields={[]}
                onFinish={(value) => {
                    const reqObj = { ...value };
                    reqObj.claimStartDate = moment(
                        reqObj.claimStartDate,
                    ).format('YYYY-MM-DD');
                    reqObj.claimEndDate = moment(reqObj.claimEndDate).format(
                        'YYYY-MM-DD',
                    );
                    reqObj.deadlineInternal = moment(
                        reqObj.deadlineInternal,
                    ).format('YYYY-MM-DD');
                    reqObj.deadlineExternal = moment(
                        reqObj.deadlineExternal,
                    ).format('YYYY-MM-DD');
                    setSaving(true);
                    if (details) {
                        API_SERVICE.claimUpdate(details.id, reqObj)
                            .then((d) => {
                                onClose(true);
                            })
                            .catch((e) => {
                                notification.error({
                                    message: API_SERVICE.handleErrors(e),
                                });
                            })
                            .finally(() => {
                                setSaving(false);
                            });
                    } else {
                        API_SERVICE.claimCreate(reqObj)
                            .then((d) => {
                                onClose(true);
                            })
                            .catch((e) => {
                                notification.error({
                                    message: API_SERVICE.handleErrors(e),
                                });
                            })
                            .finally(() => {
                                setSaving(false);
                            });
                    }
                }}
                layout="vertical"
                requiredMark={true}
            >
                <Row gutter={20}>
                    <Col md={24} sm={24} xs={24}>
                        <Form.Item
                            label={'Select Company:'}
                            name={'companyId'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Company required',
                                },
                            ]}
                        >
                            <Select
                                disabled={roleId === 4 ? true : false}
                                placeholder={'Select Company'}
                                onChange={handleCompanyChange}
                            >
                                {companies.map((company: any) => {
                                    return (
                                        <Select.Option value={company.id}>
                                            {company.name}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col md={24} sm={24} xs={24}>
                        <Form.Item
                            label={'Select Claim Tax Year:'}
                            name={'financialYear'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Tax year required',
                                },
                            ]}
                        >
                            <Select>
                                {taxYears.map((taxYear: any) => {
                                    return (
                                        <Select.Option value={taxYear.value}>
                                            {taxYear.name}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col md={24} sm={24} xs={24}>
                        <Form.Item
                            label={'Select Date From (Claim Period):'}
                            name={'claimStartDate'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Start date required',
                                },
                            ]}
                        >
                            <DatePicker
                                disabledDate={futureDateDisabled}
                                // defaultValue={moment()}
                                // value={details ? details.claimStartDate : moment()}
                                placeholder="Select date (DD/MM/YYYY)"
                                format="DD-MM-YYYY"
                                onChange={handleDateFromChange}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={24} sm={24} xs={24}>
                        <Form.Item
                            label={'Select Date To (Claim Period):'}
                            name={'claimEndDate'}
                            rules={[
                                {
                                    required: true,
                                    message: 'End date required',
                                },
                            ]}
                        >
                            <DatePicker
                                disabledDate={dateBeforeClaimStartDisabled}
                                disabled={
                                    claimFromDate ||
                                    (details && details.claimStartDate)
                                        ? false
                                        : true
                                }
                                placeholder="Select date (DD/MM/YYYY)"
                                format="DD-MM-YYYY"
                            />
                        </Form.Item>
                    </Col>
                    <Col md={24} sm={24} xs={24}>
                        <Form.Item
                            label={'Deadline (Internal):'}
                            name={'deadlineInternal'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Internal deadline required.',
                                },
                            ]}
                        >
                            <DatePicker
                                disabledDate={previousDateDisabled}
                                placeholder="Select date (DD/MM/YYYY)"
                                format="DD-MM-YYYY"
                                onChange={handleInternalDeadlineChange}
                            />
                        </Form.Item>
                    </Col>

                    <Col md={24} sm={24} xs={24}>
                        <Form.Item
                            label={'Deadline (External):'}
                            name={'deadlineExternal'}
                            rules={[
                                {
                                    required: true,
                                    message: 'External deadline required.',
                                },
                            ]}
                        >
                            <DatePicker
                                disabledDate={
                                    dateBeforeInternalDeadlineDisabled
                                }
                                disabled={
                                    internalDeadlineDate ||
                                    (details && details.deadlineInternal)
                                        ? false
                                        : true
                                }
                                placeholder="Select date (DD/MM/YYYY)"
                                format="DD-MM-YYYY"
                            />
                        </Form.Item>
                    </Col>
                    <Col md={24} sm={24} xs={24}>
                        <Form.Item
                            label={'Claim Type:'}
                            name={'typeId'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Claim type required',
                                },
                            ]}
                        >
                            <Select placeholder="Select Claim Type">
                                {claimTypes.map((claimType: any) => {
                                    return (
                                        <Select.Option value={claimType.id}>
                                            {claimType.displayName}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col md={24} sm={24} xs={24}>
                        <Form.Item
                            label={
                                'Total Employment cost as per Accounts (in GBP):'
                            }
                            name={'employeeCostAsPerAcc'}
                            rules={[
                                {
                                    required: true,
                                    message: '',
                                },
                            ]}
                        >
                            <Input placeholder="" />
                        </Form.Item>
                    </Col>

                    <Col md={24} sm={24} xs={24}>
                        <Form.Item
                            label={'Claim Value (in GBP):'}
                            name={'value'}
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Claim value required',
                            //     },
                            // ]}
                        >
                            <Input placeholder="Enter Claim Value in GBP" />
                        </Form.Item>
                    </Col>

                    <Col md={24} sm={24} xs={24}>
                        <Form.Item
                            label={'Claim Manager:'}
                            name={'managerId'}
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Claim manager required',
                            //     },
                            // ]}
                        >
                            <Select placeholder={'Select the Claim Manager'}>
                                {employees.map((employee: any) => {
                                    return (
                                        <Select.Option value={employee.id}>
                                            {employee &&
                                                employee.user &&
                                                employee.user.name}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Divider />
                    <Col md={24} sm={24} xs={24}>
                        <div className="text-right">
                            <Space>
                                <Button
                                    shape="round"
                                    className="btn-dark primary"
                                    onClick={() => onClose()}
                                >
                                    Cancel
                                </Button>
                                {details ? (
                                    <AccessControl id={60}>
                                        <Button
                                            htmlType="submit"
                                            shape="round"
                                            type="primary"
                                        >
                                            Update
                                        </Button>
                                    </AccessControl>
                                ) : (
                                    <AccessControl id={59}>
                                        <Button
                                            htmlType="submit"
                                            shape="round"
                                            type="primary"
                                        >
                                            + Add
                                        </Button>
                                    </AccessControl>
                                )}
                            </Space>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}
