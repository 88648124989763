import React, { CSSProperties, useEffect, useState } from 'react';
import './index.scss';
import {
    Button,
    Menu,
    Modal,
    notification,
    Space,
    Form,
    Typography,
    Input,
    Row,
    Col,
    Select,
    Divider,
    message,
    DatePicker,
    Radio,
} from 'antd';
import { useHistory } from 'react-router-dom';
import AdminListLayout from 'shared/components/layout/AdminListLayout/AdminListLayout';
import { ShopOutlined, PlusCircleOutlined } from '@ant-design/icons';
import Search from 'antd/es/input/Search';
import API_SERVICE from '../../services/api-service';
import TextArea from 'antd/es/input/TextArea';
import moment from 'moment';
import { FileUpload } from '../../components';
import { EditableTagGroup } from 'shared/components/Ant/Tags/Tags';
import { ISOtoDateString } from '../../../src/services/util';
import { useTranslation } from 'react-i18next';
import { accessIncludes } from 'client/src/services/AccessControl';
import AccessControl from '../../services/AccessControl';

const { SubMenu } = Menu;

const { Text, Title, Link } = Typography;

export default function CompanyCreateOrEditPage({ onClose, details }: any) {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [companyLogo, setCompanyLogo] = useState<any>(
        (details && details.logo) || {},
    );
    const [saving, setSaving] = useState(false);
    const [sicCodes, setSicCodes] = useState([]);
    const [registeredSIC, setRegisteredSIC] = useState([]);
    const [randomNumber, setRandomNumber] = useState(0);

    const history = useHistory();
    const regHttpLink = /^(http(s)?:\/\/).*/i;

    function isDateDisabled(current: any) {
        return current && current > moment().endOf('day');
    }
    function beforeIncorporationandFutureDate(current: any) {
        /*let a = moment().endOf('day');
        let b = moment(details && details.incorporationDate).endOf('day')
        return a.diff(b, 'days');*/
        return current && current > moment().endOf('day');
    }

    function handleRetrieveTagsList(childData: any) {
        setSicCodes(childData);
    }

    const fetchCompanyHouseDetails = async (e: any) => {
        let cin = e.target.value;

        if (cin) {
            try {
                const {
                    data: { data },
                } = await API_SERVICE.getCompanyHouseDetails(cin);
                form.setFieldsValue({
                    name: data.name,
                    address: data.address,
                    incorporationDate: moment(data.incorporationDate),
                    postCode: data.postCode,
                });
                let codes = data.sicCodes.map((item: any) => {
                    return { code: item };
                });
                setRegisteredSIC(codes);
            } catch (e) {
                form.setFieldsValue({ cin: '' });
                notification.error({ message: t(API_SERVICE.handleErrors(e)) });
            }
        }
    };

    useEffect(() => {
        if (details && details.sicCodes) {
            setRegisteredSIC(details.sicCodes);
        }
    }, []);

    useEffect(() => {
        let randomNumber = Math.random();
        setRandomNumber(randomNumber);
    }, [registeredSIC]);

    return (
        <Modal
            title={
                <Space>
                    <ShopOutlined />
                    <Text>{details ? 'Edit Company' : 'Add Company'}</Text>
                </Space>
            }
            width={800}
            footer={null}
            visible={true}
            maskClosable={false}
            cancelText="Cancel"
            okText={details ? 'Update' : '+ Add'}
            okType="primary"
            okButtonProps={{ shape: 'round' }}
            cancelButtonProps={{ shape: 'round', className: 'btn-dark' }}
            onOk={() => {
                form.submit();
            }}
            onCancel={onClose}
        >
            <Form
                size="large"
                form={form}
                initialValues={details || {}}
                fields={[]}
                // onValuesChange={(i)=>console.log(i)}
                onFinish={(value) => {
                    const reqObj = { ...value };
                    reqObj.sicCodes = sicCodes;
                    reqObj.incorporationDate = moment
                        .utc(reqObj.incorporationDate)
                        .format('YYYY-MM-DD');
                    reqObj.latestFilingDate = moment
                        .utc(reqObj.latestFilingDate)
                        .format('YYYY-MM-DD');

                    if (reqObj.websiteUrl) {
                        reqObj.websiteUrl = reqObj.websiteUrl.toLowerCase();
                        reqObj.websiteUrl = !regHttpLink.test(reqObj.websiteUrl)
                            ? `http://${reqObj.websiteUrl}`
                            : reqObj.websiteUrl;
                    } else {
                        reqObj.websiteUrl = null;
                    }
                    setSaving(true);
                    if (details) {
                        API_SERVICE.companyUpdate(details.id, reqObj)
                            .then((d) => {
                                onClose(true);
                            })
                            .catch((e) => {
                                notification.error({
                                    message: API_SERVICE.handleErrors(e),
                                });
                            })
                            .finally(() => {
                                setSaving(false);
                            });
                    } else {
                        API_SERVICE.companyCreate(reqObj)
                            .then((d) => {
                                onClose(true);
                            })
                            .catch((e) => {
                                notification.error({
                                    message: API_SERVICE.handleErrors(e),
                                });
                            })
                            .finally(() => {
                                setSaving(false);
                            });
                    }
                }}
                layout="vertical"
                requiredMark={true}
            >
                <Row align="middle">
                    <img
                        src={companyLogo.url || ''}
                        style={{
                            height: 100,
                            width: 100,
                            margin: 16,
                            backgroundColor: '#F4F4F4',
                        }}
                        alt=""
                    />
                    <Form.Item label={''} name={'logoId'}>
                        <FileUpload type={2} callback={setCompanyLogo}>
                            <Button
                                type={'primary'}
                                shape="round"
                                className="theme-button primary"
                                size="middle"
                            >
                                Change
                            </Button>
                        </FileUpload>
                    </Form.Item>
                </Row>

                <Row gutter={20}>
                    <Col md={12} sm={24} xs={24}>
                        <Col>
                            <Form.Item
                                label={'Company Registration Number:'}
                                name={'cin'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Company Number required',
                                    },
                                ]}
                            >
                                <Input
                                    onBlur={fetchCompanyHouseDetails}
                                    onPressEnter={fetchCompanyHouseDetails}
                                />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                                label={'Company Name:'}
                                name={'name'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Company Name required',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                        <Col>
                            <Form.Item
                                label={'Description:'}
                                name={'description'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Description required',
                                    },
                                ]}
                            >
                                <TextArea rows={4} />
                            </Form.Item>
                        </Col>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                        <Col>
                            <Form.Item
                                label={'Registered Address:'}
                                name={'address'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Address required',
                                    },
                                ]}
                            >
                                <TextArea rows={4} />
                            </Form.Item>
                        </Col>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                        <Col>
                            <Form.Item
                                label={'UTR Number:'}
                                name={'registrationNumber'}
                                rules={[
                                    {
                                        required: false,
                                        message: 'Registration Number required',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item label={'SIC Code(s):'}>
                                <EditableTagGroup
                                    retrieveTagsList={handleRetrieveTagsList}
                                    preTags={registeredSIC}
                                    randomNumber={randomNumber}
                                />
                                {/*{
                                    (!details && registeredSIC.length === 0) && <EditableTagGroup retrieveTagsList={handleRetrieveTagsList} preTags={[]} />
                                }
                                {
                                    (!details && registeredSIC.length > 0) && <EditableTagGroup retrieveTagsList={handleRetrieveTagsList} preTags={registeredSIC} />
                                }
                                {
                                    (details && registeredSIC.length === 0) && <EditableTagGroup retrieveTagsList={handleRetrieveTagsList} preTags={details.sicCodes} />
                                }
                                {
                                    (details && registeredSIC.length > 0) && <EditableTagGroup retrieveTagsList={handleRetrieveTagsList} preTags={registeredSIC} />
                                }*/}
                            </Form.Item>
                        </Col>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                        <Col>
                            <Form.Item
                                label={'Incorporation Date:'}
                                name={'incorporationDate'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Incorporation Date required',
                                    },
                                ]}
                            >
                                <DatePicker
                                    disabledDate={isDateDisabled}
                                    placeholder="Select date (DD/MM/YYYY)"
                                    format="DD-MM-YYYY"
                                />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                                label={'Website:'}
                                name={'websiteUrl'}
                                // rules={[
                                //     { required: true, message: 'Website URL required' },
                                // ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                                label={'Approx. Turnover (GBP):'}
                                name={'approxTurnOver'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Approx. Turnover required',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                                label={'Postcode:'}
                                name={'postCode'}
                                // rules={[
                                //     { required: true, message: 'Postcode required' },
                                // ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                                label={'Last Accounts made upto:'}
                                name={'latestFilingDate'}
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Last Accounts made upto is required',
                                    },
                                ]}
                            >
                                <DatePicker
                                    disabledDate={
                                        beforeIncorporationandFutureDate
                                    }
                                    placeholder="Select date (DD/MM/YYYY)"
                                    format="DD-MM-YYYY"
                                />
                            </Form.Item>
                        </Col>

                        <Col>
                            <Form.Item
                                label={'No. of Employees:'}
                                name={'noOfEmployees'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'No of employees required',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item label="is SME" name="isSme">
                                <Radio.Group>
                                    <Radio value={true}>Yes</Radio>
                                    <Radio value={false}>No</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Col>

                    <Col md={12} sm={24} xs={24}>
                        <Form.Item name="companyAdmin">
                            <CompanyAdminForm
                                value={details && details.companyAdmin}
                            />
                        </Form.Item>
                    </Col>
                    {/*
                    <Col md={12} sm={24} xs={24}>
                          <Form.Item
                              label={'Country'}
                              name={'countryId'}
                              rules={[
                                  { required: true, message: 'Country required' },
                              ]}
                          >
                              <Select onInputKeyDown={handle_keypress} onBlur={()=>set_keyfilter('')}>
                                  {
                                      countries.map((country: any) => {
                                          if (country.name.indexOf(keyfilter)===0)
                                          return <Select.Option
                                              value={country.id}>{country.name}</Select.Option>;
                                      })
                                  }
                              </Select>
                          </Form.Item>
                    </Col>
                    */}

                    <Divider />
                    <Col md={24} sm={24} xs={24}>
                        <div className="text-right">
                            <Space>
                                <Button
                                    size="middle"
                                    shape="round"
                                    className="btn-dark primary"
                                    onClick={() => onClose()}
                                >
                                    Cancel
                                </Button>
                                {details ? (
                                    <AccessControl id={43}>
                                        <Button
                                            size="middle"
                                            htmlType="submit"
                                            shape="round"
                                            type="primary"
                                        >
                                            Update
                                        </Button>
                                    </AccessControl>
                                ) : (
                                    <AccessControl id={43}>
                                        <Button
                                            size="middle"
                                            htmlType="submit"
                                            shape="round"
                                            type="primary"
                                        >
                                            + Add
                                        </Button>
                                    </AccessControl>
                                )}
                            </Space>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}

const CompanyAdminForm = (props: any) => {
    const companyAdmin = props.value || {};
    const [formValues, setFormValues] = useState(companyAdmin.user || {});
    const [roleValue, setRoleValue] = useState(companyAdmin || {});

    function updateFormValues(values: any) {
        setFormValues({ ...formValues, ...values });
    }

    useEffect(() => {
        props.onChange(formValues);
    }, [formValues]);

    return (
        <>
            <Form
                className="company-admin-details-wrapper"
                initialValues={companyAdmin.user || {}}
                onValuesChange={updateFormValues}
            >
                <h3 className="text-center">Company Admin Details</h3>
                <Col>
                    <Form.Item label={'Admin Name:'} name="name">
                        <Input />
                    </Form.Item>
                    <br />
                    <br />
                </Col>

                <Col>
                    <Form.Item label={'Admin Email:'} name="email">
                        <Input />
                    </Form.Item>
                    <br />
                    <br />
                </Col>

                <Col>
                    <Form.Item label={'Admin Phone Number:'} name="phoneNumber">
                        <Input />
                    </Form.Item>
                    <br />
                </Col>
            </Form>

            <Form
                className="company-admin-details-wrapper"
                initialValues={companyAdmin || {}}
                onValuesChange={updateFormValues}
            >
                <Col>
                    <Form.Item label={'Role Name:'} name="role">
                        <Input />
                    </Form.Item>
                    <br />
                    <br />
                </Col>
            </Form>
        </>
    );
};
