import React, { CSSProperties, useEffect, useState } from 'react';
import '../index.scss';
import {
    Button,
    Menu,
    Modal,
    notification,
    Space,
    Form,
    Typography,
    Input,
    Row,
    Col,
    Select,
    Divider,
    Upload,
    Descriptions,
    message,
    Popconfirm,
} from 'antd';
import { useHistory } from 'react-router-dom';
import AdminListLayout from 'shared/components/layout/AdminListLayout/AdminListLayout';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import Search from 'antd/es/input/Search';
import API_SERVICE from '../../../services/api-service';
import TextArea from 'antd/es/input/TextArea';
import AccessControl from "../../../services/AccessControl";

const { SubMenu } = Menu;

const { Text, Title, Link } = Typography;


export default function ConsumableTypes(props: any) {
    const [form] = Form.useForm();
    const [saving, setSaving] = useState(false);
    const [count, setCount] = useState(0);

    const [consumablesTypes, setConsumablesTypeList] = useState([] as any);
    const [fetching, setFetching] = useState(true);


    const getConsumablesTypeList = async () => {
        setFetching(true);
        try {
            await API_SERVICE.consumablesTypeList().then((result)=> {
                const { data: { data } } = result;
                let mData = data.items.map((item: any, i: number) => {
                    item.key = i;
                    return item;
                });

                setConsumablesTypeList(mData);
                setCount(data.items.length);
            });
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        } finally {
            setFetching(false);
        }
    }

    useEffect(() => {
        getConsumablesTypeList();
    }, []);

    const addNewRow = () => {
        const updatedList = [...consumablesTypes, {id: null, key: count}];
        console.log(updatedList);
        setConsumablesTypeList(updatedList);
        setCount(count+1);
    }

    const handleDelete = (id: any) => {
        const dataSource = [...consumablesTypes];
        console.log('ggg', dataSource.filter(item => item.key !== id));
        setConsumablesTypeList(dataSource.filter(item => item.key !== id));
    };

    if (fetching)
        return <div>Getting Consumable Types...</div>
    else if (!consumablesTypes.length)
        return <div>No Consumable Type found.</div>
    return (
        <div>
            <Form
                size='large'
                form={form}
                initialValues={{}}
                fields={[]}
                onFinish={(value) => {
                    const reqObj: any = {
                        claimConsumables: Object.values(value)
                    };
                    // console.log(reqObj);
                    setSaving(true);
                    API_SERVICE.createBulkClaimConsumables(reqObj).then((d) => {
                        notification.success({
                            message: 'Success',
                        });
                        getConsumablesTypeList();
                    }).catch((e) => {
                        notification.error({ message: API_SERVICE.handleErrors(e) });
                    }).finally(() => {
                        setSaving(false);
                    });
                }}
                layout='horizontal'
                requiredMark={true}
            >

                {
                    consumablesTypes.map((item: any, index: number)=>{
                        return (
                            <Form.Item name={''+index}>
                                <DisplayNameInput obj={item} handleDelete={handleDelete} index={index} />
                            </Form.Item>
                        )
                    })
                }
            </Form>
            <br/>
            <AccessControl id = {236}>
                <Row>
                    <Col offset={2} md={12}>
                        <div className={'text-left'}>
                            <Space>
                                <Button onClick={addNewRow}
                                        shape='round' icon={<PlusOutlined />} className="theme-button primary">
                                    Add More
                                </Button>
                                <Button onClick={form.submit}
                                        shape='round' className="theme-button primary">
                                    Save
                                </Button>
                            </Space>
                        </div>
                    </Col>
                </Row>
            </AccessControl>
        </div>
    );
}


const DisplayNameInput = (props: any) => {
    const obj: any = props.obj || {}
    const [displayName, setDisplayName] = useState(obj.name || '')
    useEffect(()=>{
        props.onChange({
            id: obj.id,
            name: displayName
        });
    },[displayName])
    return (
        <Row align={'middle'}>
            <AccessControl id = {236}>
                <Col md={2} sm={24} xs={24}>
                    <Popconfirm title="Sure to delete?" onConfirm={() => props.handleDelete(props.index)}>
                        <div className='text-primary cursor-pointer text-center'><MinusCircleOutlined /></div>
                    </Popconfirm>
                </Col>
            </AccessControl>
            <Col md={12} sm={24} xs={24}>
                <Input
                    style={{width: 200}}
                    defaultValue={obj.name}
                    onChange={({target: {value}})=>setDisplayName(value)}/>
            </Col>
        </Row>
    )
}
