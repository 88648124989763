import React, { CSSProperties, useEffect, useState } from 'react';
//import './index.scss';
import {
    Button,
    Menu,
    notification,
    Space,
    Table,
    Tag,
    Typography,
    Modal,
    Pagination,
    Form,
    Select,
    Row,
    Col,
} from 'antd';
import { useHistory } from 'react-router-dom';
import AdminListLayout from 'shared/components/layout/AdminListLayout/AdminListLayout';
import Search from 'antd/es/input/Search';
import API_SERVICE from '../../services/api-service';
import UserCreateOrEditPage from './UserCreateOrEditPage';

//import AccessControl from 'client/src/services/AccessControl';
import { ConfirmationModal } from '../../components';

import { PlusOutlined } from '@ant-design/icons';
import {
    VisibilityOutlined,
    EditOutlined,
    DeleteOutlined,
} from '@material-ui/icons';
import AccessControl from '../../services/AccessControl';

const { SubMenu } = Menu;

const { Text, Title, Link } = Typography;
export default function UsersPage(props: any) {
    const status = [
        {
            name: 'Active',
            id: 1,
        },
        {
            name: 'Inactive',
            id: 0,
        },
    ];

    const history = useHistory();
    const [companies, setCompanies] = useState([] as any[]);
    const [roles, setRoles] = useState([] as any[]);
    const [users, setUsers] = useState([] as any[]);
    const [search, setSearch] = useState('' as string);
    const [addNew, setAddNew] = useState(false);
    const [editUser, setEditUser] = useState(null as any);
    const [fetching, setFetching] = useState(false);
    const [pagination, setPagination] = useState({
        page: 1,
        perpage: '10',
    } as any);
    const [total, setTotal] = useState(10);
    const [confirmationModal, setConfirmationModal] = useState({} as any);
    const [filters, setFilters] = useState({} as any);
    const columns = [
        {
            title: 'S.No',
            key: 'sNO',
            width: 70,
            render: (a: any, v: any, index: number) => (
                <Text>
                    {(pagination.page - 1) * pagination.perpage + index + 1}
                </Text>
            ),
        },
        {
            title: 'Name',
            key: 'name',
            width: 200,
            ellipsis: true,
            render: (a: any) => (
                <Space size={0}>
                    <Text>{a.name}</Text>
                </Space>
            ),
        },
        {
            title: 'Role(s) & Permissions',
            key: 'roles',
            width: 250,
            ellipsis: true,
            render: (a: any) => {
                const roles: any[] = [];
                (a.roles || []).map((role: any, index: number) => {
                    roles.push(role.name);
                });
                return <Text>{roles.join(', ')}</Text>;
            },
        },
        {
            title: 'Contact Details',
            key: 'contactDetails',
            width: 350,
            ellipsis: true,
            render: (a: any) => (
                <Space
                    direction={'vertical'}
                    size={0}
                    //@ts-ignore
                    title={`${a.phoneNumber}\n${a.email}`}
                >
                    <Text>{a.phoneNumber || 'NA'}</Text>
                    <Text>{a.email || 'NA'}</Text>
                </Space>
            ),
        },
        {
            title: 'Company Name',
            key: 'companyName',
            width: 250,
            ellipsis: true,
            render: (a: any) => (
                <Text>
                    {a.companies && a.companies.length && a.companies[0].name}
                </Text>
            ),
        },
        {
            title: 'Verification Status',
            key: 'verificationStatus',
            width: 200,
            ellipsis: true,
            dataIndex: 'emailVerifiedAt',
            render: (a: boolean, value: any) =>
                a ? <Text>Verified</Text> : <Text>Pending Verification</Text>,
        },
        {
            title: 'Actions',
            key: 'action',
            width: 300,
            ellipsis: true,
            render: (value: any) => {
                return (
                    <Space>
                        {!value.twoFactorSetup && (
                            <>
                                <AccessControl id={36}>
                                    <Space className="px-2">
                                        <Button
                                            shape="round"
                                            className="theme-button primary ant-btn-icon-only-img"
                                            icon={<EditOutlined />}
                                            onClick={() => {
                                                setEditUser(value);
                                            }}
                                        />
                                    </Space>
                                </AccessControl>
                                <AccessControl id={36}>
                                    <Space>
                                        <Button
                                            shape="round"
                                            className={
                                                value.status
                                                    ? 'success-button'
                                                    : 'info-button'
                                            }
                                            onClick={() => {
                                                const reqObj = {
                                                    status: value.status
                                                        ? 0
                                                        : 1,
                                                };
                                                setConfirmationModal({
                                                    message: `Change status to ${
                                                        value.status
                                                            ? 'InActive'
                                                            : 'Active'
                                                    }`,
                                                    action: API_SERVICE.userStatus,
                                                    params: [value.id, reqObj],
                                                });
                                            }}
                                        >
                                            {value.status
                                                ? 'Active'
                                                : 'InActive'}
                                        </Button>
                                    </Space>
                                </AccessControl>
                            </>
                        )}
                        <AccessControl id={38}>
                            <Space>
                                <Button
                                    type="default"
                                    shape="round"
                                    className="gray-button ant-btn-icon-only-img"
                                    icon={<DeleteOutlined />}
                                    onClick={() => {
                                        setConfirmationModal({
                                            message: `Delete user?`,
                                            action: API_SERVICE.userDelete,
                                            params: [value.id],
                                        });
                                    }}
                                />
                            </Space>
                        </AccessControl>
                    </Space>
                );
            },
        },
    ];

    const fetchCompanies = async () => {
        try {
            const {
                data: { data },
            } = await API_SERVICE.getCompanies({ perpage: 1000 });
            setCompanies(data.items);
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        }
    };

    const fetchRoles = async () => {
        try {
            const {
                data: { data },
            } = await API_SERVICE.getRoles({ perpage: 1000 });
            setRoles(data.items);
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        }
    };

    const refresh = async () => {
        setFetching(true);
        const params = { ...pagination, ...filters };
        if (search) {
            params['q'] = search;
        }
        try {
            const {
                data: { data },
            } = await API_SERVICE.users(params);
            setUsers(data.items);
            setTotal(data.total);
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        } finally {
            setFetching(false);
        }
    };

    useEffect(() => {
        refresh();
    }, [props.location, pagination, search, filters]);

    useEffect(() => {
        fetchCompanies();
        fetchRoles();
    }, []);

    return (
        <div className={'franchise-wrapper page-wrapper'}>
            <AdminListLayout
                title={'All Users'}
                titleSearch={
                    <AccessControl id={34}>
                        <div className="search-bar">
                            <Search
                                placeholder="Search for Users here"
                                onSearch={(e) => {
                                    setSearch(e);
                                    setPagination({
                                        page: 1,
                                        perpage: pagination.perpage,
                                    });
                                }}
                            />
                        </div>
                    </AccessControl>
                }
                titleAction={
                    <AccessControl id={35}>
                        <Button
                            type={'primary'}
                            shape="round"
                            className="theme-button primary"
                            onClick={() => setAddNew(true)}
                            icon={<PlusOutlined />}
                        >
                            Add New User
                        </Button>
                    </AccessControl>
                }
                filters={
                    <Form
                        style={{ flex: 'auto' }}
                        onValuesChange={(obj) => {
                            setFilters({ ...filters, ...obj });
                            setPagination({
                                page: 1,
                                perpage: pagination.perpage,
                            });
                        }}
                    >
                        <AccessControl id={34}>
                            <Row gutter={20}>
                                <Col flex={'auto'}>
                                    <Form.Item
                                        name="status"
                                        style={{ marginBottom: 0 }}
                                    >
                                        <Select
                                            placeholder="Filter by Status"
                                            allowClear
                                        >
                                            {status.map((s: any) => {
                                                return (
                                                    <Select.Option value={s.id}>
                                                        {s.name}
                                                    </Select.Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col flex={'auto'}>
                                    <Form.Item
                                        name="companyId"
                                        style={{ marginBottom: 0 }}
                                    >
                                        <Select
                                            placeholder="Filter by Company"
                                            allowClear
                                        >
                                            {companies.map((c: any) => {
                                                return (
                                                    <Select.Option value={c.id}>
                                                        {c.name}
                                                    </Select.Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col flex={'auto'}>
                                    <Form.Item
                                        name="roleId"
                                        style={{ marginBottom: 0 }}
                                    >
                                        <Select
                                            placeholder="Filter by Role"
                                            allowClear
                                        >
                                            {roles.map((r: any) => {
                                                return (
                                                    <Select.Option value={r.id}>
                                                        {r.name}
                                                    </Select.Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </AccessControl>
                    </Form>
                }
                pagination={
                    <AccessControl id={34}>
                        <Pagination
                            showSizeChanger
                            showQuickJumper
                            current={pagination.page}
                            defaultCurrent={1}
                            total={total}
                            onChange={(page, size) =>
                                setPagination({ page: page, perpage: size })
                            }
                        />
                    </AccessControl>
                }
            >
                {addNew && (
                    <UserCreateOrEditPage
                        onClose={(e: boolean) => {
                            if (e) {
                                refresh();
                            }
                            setAddNew(false);
                        }}
                    />
                )}
                {editUser && (
                    <UserCreateOrEditPage
                        details={editUser}
                        onClose={(e: boolean) => {
                            if (e) {
                                refresh();
                            }
                            setEditUser(null);
                        }}
                    />
                )}
                <AccessControl id={34}>
                    <Table
                        loading={fetching}
                        scroll={{ x: true, y: 'calc(100vh - 280px)' }}
                        pagination={false}
                        dataSource={users}
                        columns={columns}
                    />
                </AccessControl>
            </AdminListLayout>

            <ConfirmationModal
                {...confirmationModal}
                callback={(a: boolean) => {
                    setConfirmationModal({});
                    if (a) {
                        refresh();
                    }
                }}
            />
        </div>
    );
}
