import { DeleteOutlined, EditOutlined } from '@material-ui/icons';
import {
    Button,
    DatePicker,
    Pagination,
    Select,
    Space,
    Table,
    notification,
} from 'antd';
import { Option } from 'antd/lib/mentions';
import Text from 'antd/lib/typography/Text';
import { ConfirmationModal, PrimaryTooltip } from 'components';
import DeadlineContainer from 'components/Charts/DeadlineContainerBox';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import AccessControl from 'services/AccessControl';
import AdminListLayout from 'shared/components/layout/AdminListLayout/AdminListLayout';
import { CLAIMS_OVERVIEW } from 'shared/constants/RouteConstants';
import { commaAddingInPrice } from 'shared/services/Utility';
import ClaimCreateOrEditPage from 'views/Claims/ClaimCreateOrEditPage';
import API_SERVICE from '../../services/api-service';
import './index.scss';

const Analytics = () => {
    const history = useHistory();
    const [claimsStatus, setClaimsStatus] = useState({
        total: 0,
        completed: 0,
        inProgress: 0,
    });
    const [claims, setClaims] = useState([] as any[]);
    const [addNew, setAddNew] = useState(false);
    const [editClaim, setEditClaim] = useState(null as any);
    const [fetching, setFetching] = useState(false);
    const [pagination, setPagination] = useState({
        page: 1,
        perpage: '10',
    } as any);
    const [total, setTotal] = useState(10);
    const [filters, setFilters] = useState({} as any);
    const [confirmationModal, setConfirmationModal] = useState({} as any);

    const columns = [
        {
            title: 'S.No',
            key: 'sNO',
            width: 70,
            render: (a: any, v: any, index: number) => (
                <Text>
                    {(pagination.page - 1) * pagination.perpage + index + 1}
                </Text>
            ),
        },
        {
            title: <PrimaryTooltip title="prompt text" text="Company Name" />,
            key: 'companyName',
            width: 200,
            ellipsis: true,
            render: (a: any) => (
                <Text strong>
                    <Link
                        to={`${CLAIMS_OVERVIEW}/${a.id}`}
                        title={a && a.company && a.company.name}
                        className={'theme-link'}
                    >
                        {a && a.company && a.company.name}
                    </Link>
                </Text>
            ),
        },
        {
            title: <PrimaryTooltip title="prompt text" text="Claim Tax Year" />,
            key: 'taxYear',
            dataIndex: 'financialYear',
            width: 150,
            render: (a: any) => (
                <Text>
                    {a} - {a + 1}
                </Text>
            ),
        },
        {
            title: <PrimaryTooltip title="prompt text" text="Claim Period" />,
            key: 'claimPeriod',
            width: 150,
            render: (a: any) =>
                a.claimStartDate &&
                a.claimEndDate && (
                    <Text>
                        {moment(a.claimStartDate).format('DD-MM-YYYY')} <br />{' '}
                        to <br /> {moment(a.claimEndDate).format('DD-MM-YYYY')}
                    </Text>
                ),
        },
        {
            title: (
                <PrimaryTooltip title="prompt text" text="Filing Start Date" />
            ),
            key: 'filingStartDate',
            width: 150,
            render: (a: any) =>
                a.createdAt && (
                    <Text>{moment(a.createdAt).format('DD-MM-YYYY')}</Text>
                ),
        },
        {
            title: (
                <PrimaryTooltip title="prompt text" text="Internal Deadline" />
            ),
            key: 'internalDeadline',
            width: 150,
            render: (a: any) =>
                a.deadlineInternal && (
                    <Text>
                        {moment(a.deadlineInternal).format('DD-MM-YYYY')}
                    </Text>
                ),
        },
        {
            title: (
                <PrimaryTooltip title="prompt text" text="External Deadline" />
            ),
            key: 'externalDeadline',
            width: 150,
            render: (a: any) =>
                a.deadlineExternal && (
                    <Text>
                        {moment(a.deadlineExternal).format('DD-MM-YYYY')}
                    </Text>
                ),
        },
        {
            title: <PrimaryTooltip title="prompt text" text="Claim Date" />,
            key: 'claimDate',
            width: 150,
            render: (a: any) =>
                a.claimDate ? (
                    <Text>{moment(a.claimDate).format('DD-MM-YYYY')}</Text>
                ) : (
                    <Text>Claim Pending</Text>
                ),
        },

        {
            title: <PrimaryTooltip title="prompt text" text="Claim Type" />,
            key: 'claimType',
            dataIndex: 'type',
            width: 150,
            render: (a: any) => <Text>{a && a.displayName}</Text>,
        },
        {
            title: (
                <PrimaryTooltip title="prompt text" text="Claim Value (GBP)" />
            ),
            key: 'claimValue',
            dataIndex: 'value',
            width: 150,
            render: (value: any) => commaAddingInPrice(value),
        },
        {
            title: <PrimaryTooltip title="prompt text" text="Stage" />,
            key: 'stage',
            dataIndex: 'stage',
            width: 150,
            render: (a: any) => <Text>{a && a.displayName}</Text>,
        },
        {
            title: 'Actions',
            key: 'action',
            width: 200,
            render: (value: any) => {
                return (
                    <Space>
                        <AccessControl id={58}>
                            <Space className="px-2">
                                <Button
                                    shape="round"
                                    className="theme-button primary ant-btn-icon-only-img"
                                    icon={<EditOutlined />}
                                    onClick={() => {
                                        setEditClaim(value);
                                    }}
                                />
                            </Space>
                        </AccessControl>
                        <AccessControl id={61}>
                            <Space>
                                <Button
                                    type="default"
                                    shape="round"
                                    className="gray-button ant-btn-icon-only-img"
                                    icon={<DeleteOutlined />}
                                    onClick={() => {
                                        setConfirmationModal({
                                            message: `Delete claim?`,
                                            action: API_SERVICE.claimDelete,
                                            params: [value.id],
                                        });
                                    }}
                                />
                            </Space>
                        </AccessControl>
                    </Space>
                );
            },
        },
    ];
    const refresh = async () => {
        setFetching(true);
        const params = { ...pagination, ...filters };

        try {
            const {
                data: { data },
            } = await API_SERVICE.claimsTotals(params);

            if (data.data) {
                data.data.claims.items.forEach((item: any) => {
                    item.latestFilingDate = moment.utc(item.latestFilingDate);
                    item.incorporationDate = moment.utc(item.incorporationDate);
                    return item;
                });
                setClaims(data.data.claims.items);
                setTotal(data.data.claims.total);
                setClaimsStatus({
                    completed: data.data.completed,
                    total: data.data.total,
                    inProgress: data.data.inProgress,
                });
            }
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        } finally {
            setFetching(false);
        }
    };

    useEffect(() => {
        refresh();
    }, [pagination]);

    return (
        <>
            <div className="analytics">
                <div className="analytics-left">
                    <div>
                        <h3>Select Report Type :</h3>
                        <Select
                            allowClear
                            onChange={(value) => {
                                if (value) {
                                    setFilters((prev: any) => {
                                        prev['type'] = value;
                                        return { ...prev };
                                    });
                                } else {
                                    setFilters((prev: any) => {
                                        prev['type'] = null;
                                        return { ...prev };
                                    });
                                }
                            }}
                            placeholder="All Claims Report"
                            style={{
                                width: '100%',
                                borderColor: '#000',
                                color: '#000',
                                outlineColor: '#000',
                            }}
                        >
                            <Option value="1">Complete</Option>
                            <Option value="2">Incomplete</Option>
                        </Select>
                    </div>
                    <div className="external-deadline">
                        <h3>External Deadline Before :</h3>
                        <DatePicker
                            allowClear
                            onChange={(value: any) => {
                                if (value) {
                                    const externalDeadline =
                                        moment(value).format('YYYY-MM-DD');
                                    setFilters((prev: any) => {
                                        prev['externalDeadline'] =
                                            externalDeadline;
                                        return { ...prev };
                                    });
                                } else {
                                    setFilters((prev: any) => {
                                        prev['externalDeadline'] = null;
                                        return { ...prev };
                                    });
                                }
                            }}
                            style={{ width: '60%' }}
                        />
                    </div>
                    <Button
                        onClick={() => refresh()}
                        type={'primary'}
                        shape="round"
                        className="theme-button  analyticsBtn"
                    >
                        Submit
                    </Button>
                </div>
                <div className="analytics-right">
                    <div className="analytic-right-top">
                        <DeadlineContainer
                            text="Complete"
                            value={claimsStatus?.completed}
                            height="250"
                            width="250"
                        />
                        <DeadlineContainer
                            text="In Complete"
                            height="250"
                            width="250"
                            value={claimsStatus?.inProgress}
                        />
                    </div>
                    <h3>
                        {filters?.externalDeadline
                            ? `With External Deadline before : ${moment(
                                  filters?.externalDeadline,
                              ).format('DD/MM/YYYY')}`
                            : 'Overall'}
                    </h3>
                    <div className="analytics-right-bottom">
                        {/*@ts-ignore*/}
                        <AdminListLayout
                            title={'All Claims'}
                            pagination={
                                <Pagination
                                    showSizeChanger
                                    showQuickJumper
                                    defaultCurrent={1}
                                    total={total}
                                    onChange={(page, size) => {
                                        console.log(
                                            `page: ${page}, size: ${size}`,
                                        );
                                        setPagination({
                                            page: page,
                                            perpage: size,
                                        });
                                    }}
                                />
                            }
                        >
                            {addNew && (
                                <ClaimCreateOrEditPage
                                    onClose={(e: boolean) => {
                                        if (e) {
                                            refresh();
                                        }
                                        setAddNew(false);
                                    }}
                                />
                            )}
                            {editClaim && (
                                <ClaimCreateOrEditPage
                                    details={editClaim}
                                    onClose={(e: boolean) => {
                                        if (e) {
                                            refresh();
                                        }
                                        setEditClaim(null);
                                    }}
                                />
                            )}
                            <AccessControl id={57}>
                                <Table
                                    loading={fetching}
                                    scroll={{
                                        x: true,
                                        y: '300px',
                                    }}
                                    pagination={false}
                                    dataSource={claims}
                                    columns={columns}
                                />
                            </AccessControl>

                            <ConfirmationModal
                                {...confirmationModal}
                                callback={(a: boolean) => {
                                    setConfirmationModal({});
                                    if (a) {
                                        refresh();
                                    }
                                }}
                            />
                        </AdminListLayout>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Analytics;
