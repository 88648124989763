import {
    Button,
    Col,
    Form,
    Menu,
    Pagination,
    Row,
    Select,
    Space,
    Table,
    Typography,
    notification,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AdminListLayout from 'shared/components/layout/AdminListLayout/AdminListLayout';
import './index.scss';

import Search from 'antd/es/input/Search';
import API_SERVICE from '../../services/api-service';
import CompanyCreateOrEditPage from './CompaniesCreateOrEditPage';
import dummyImage from '../../assests/NoImage.png';
//import {ISOtoDateString} from '../../../src/services/util';
import moment from 'moment';
import { ConfirmationModal } from '../../components';

import { PlusOutlined } from '@ant-design/icons';
import {
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    EditOutlined,
} from '@material-ui/icons';
import { commaAddingInPrice } from 'shared/services/Utility';
import AccessControl from '../../services/AccessControl';

const { SubMenu } = Menu;

const { Text, Title, Link } = Typography;

const status = [
    {
        name: 'Active',
        id: 1,
    },
    {
        name: 'Inactive',
        id: 0,
    },
];

const approvalStatus = [
    {
        name: 'Pending',
        id: 0,
    },
    {
        name: 'Approved',
        id: 1,
    },
    {
        name: 'Rejected',
        id: 2,
    },
];

export default function CompanyPage(props: any) {
    const filter = [
        {
            name: 'Active',
            id: 1,
        },
        {
            name: 'Inactive',
            id: 2,
        },
        {
            name: 'Approved',
            id: 3,
        },
        {
            name: 'Not applicable',
            id: 4,
        },
    ];

    const history = useHistory();
    const [companies, setCompanies] = useState([] as any[]);
    const [search, setSearch] = useState('' as string);
    const [addNew, setAddNew] = useState(false);
    const [editCompany, setEditCompany] = useState(null as any);
    const [fetching, setFetching] = useState(false);
    const [pagination, setPagination] = useState({
        page: 1,
        perpage: '10',
    } as any);
    const [total, setTotal] = useState(10);
    const [filters, setFilters] = useState({} as any);
    const [confirmationModal, setConfirmationModal] = useState({} as any);
    /*const filterStatus: any = {
        status: false
    }*/
    const columns = [
        /*
        {
            title: 'S.No',
            key: 'sNO',
            render: (a: any, v: any, index: number) =>
                <Text>{(pagination.page - 1) * (pagination.perpage) + index + 1}</Text>,
        },
        */
        {
            title: '',
            key: 'logo',
            width: 150,
            render: (a: any) => (
                <div className="linear">
                    <img
                        src={a.logo ? a.logo.url : dummyImage}
                        className="company-logo"
                        alt=""
                    />
                </div>
            ),
        },
        {
            title: 'Company Name',
            key: 'name',
            width: 300,
            ellipsis: true,
            render: (a: any) => <Text>{a.name}</Text>,
        },
        {
            title: 'Company Registration Number',
            key: 'cin',
            width: '240px',
            render: (a: any) => (
                <div className="linear">
                    <Text>{a.cin}</Text>
                </div>
            ),
        },
        {
            title: 'Incorporation Date',
            key: 'incorporationDate',
            width: 200,
            render: (a: any) =>
                a.incorporationDate && (
                    <Text>{a.incorporationDate.format('DD-MM-YYYY')}</Text>
                ),
        },
        {
            title: 'Last Accounts made upto',
            key: 'latestFilingDate',
            width: '240px',
            render: (a: any) =>
                a.latestFilingDate && (
                    <Text>{a.latestFilingDate.format('DD-MM-YYYY')}</Text>
                ),
        },
        {
            title: 'Appx. Turnover (GBP)',
            key: 'approxTurnOver',
            dataIndex: 'approxTurnOver',
            width: 200,
            render: (value: any) => commaAddingInPrice(value),
        },
        {
            title: 'Company Admin',
            key: 'address',
            width: 300,
            ellipsis: true,
            render: (a: any) => {
                const { user } = a.companyAdmin || { user: {} };
                return (
                    <div>
                        <Space direction="vertical">
                            <Text>
                                {user && user.firstName} {user && user.lastName}
                            </Text>
                            <Text>
                                {user && user.phoneNumber && user.phoneNumber}
                            </Text>
                            <Text>{user && user.email && user.email}</Text>
                        </Space>
                    </div>
                );
            },
        },

        {
            title: 'Approval Status',
            key: 'status',
            width: '150px',
            /*filters: [
                {
                    text: 'Pending',
                    value: 0,
                },
                {
                    text: 'Approved',
                    value: 1,
                },
                {
                    text: 'Rejected',
                    value: 2,
                }
            ],*/
            // onFilter: (value: any, companies: any) => companies.approvalStatus === value,
            /*onFilter: (value: any)=>{
                if(!filterStatus.status){
                    console.log('value', value);
                    setFilters({...filters, approvalStatus: value});
                    filterStatus.status = true;
                }
                return true;
            },*/
            render: (value: any) => {
                if (value.approvalStatus === 0) {
                    return <Text>Pending</Text>;
                } else if (value.approvalStatus === 1) {
                    return <Text>Approved</Text>;
                } else if (value.approvalStatus === 2) {
                    return <Text>Rejected</Text>;
                }
            },
        },
        {
            title: 'Actions',
            key: 'action',
            width: 300,
            render: (value: any) => {
                if (value.approvalStatus === 1)
                    return (
                        <Space>
                            <AccessControl id={43}>
                                <Space>
                                    <Button
                                        shape="round"
                                        className={
                                            value.status
                                                ? 'success-button'
                                                : 'info-button'
                                        }
                                        onClick={() => {
                                            const reqObj = {
                                                status: value.status ? 0 : 1,
                                            };
                                            setConfirmationModal({
                                                message: `Change status to ${
                                                    value.status
                                                        ? 'InActive'
                                                        : 'Active'
                                                }`,
                                                action: API_SERVICE.companyStatus,
                                                params: [value.id, reqObj],
                                            });
                                        }}
                                    >
                                        {value.status ? 'Active' : 'InActive'}
                                    </Button>
                                </Space>
                            </AccessControl>
                            <AccessControl id={43}>
                                <Space className="px-2">
                                    <Button
                                        shape="round"
                                        className="theme-button primary ant-btn-icon-only-img"
                                        icon={<EditOutlined />}
                                        onClick={() => {
                                            setEditCompany(value);
                                        }}
                                    />
                                </Space>
                            </AccessControl>
                            <AccessControl id={48}>
                                <Space>
                                    <Button
                                        type="default"
                                        shape="round"
                                        className="gray-button ant-btn-icon-only-img"
                                        icon={<DeleteOutlined />}
                                        onClick={() => {
                                            setConfirmationModal({
                                                message: `Delete company?`,
                                                action: API_SERVICE.companyDelete,
                                                params: [value.id],
                                            });
                                        }}
                                    />
                                </Space>
                            </AccessControl>
                        </Space>
                    );
                else if (value.approvalStatus === 0)
                    return (
                        <Space>
                            <Space>
                                <Button
                                    shape="round"
                                    className="success-button"
                                    onClick={() => {
                                        // const reqObj = {...value, isApproved: true}
                                        setConfirmationModal({
                                            message: 'Approve company?',
                                            action: API_SERVICE.companyApprove,
                                            params: [value.id],
                                        });
                                    }}
                                >
                                    <Row align="middle">
                                        <CheckOutlined /> Approve
                                    </Row>
                                </Button>
                            </Space>
                            <Space>
                                <Button
                                    shape="round"
                                    className="gray-button"
                                    onClick={() => {
                                        // const reqObj = {...value, isApproved: false}
                                        setConfirmationModal({
                                            message: 'Reject company',
                                            action: API_SERVICE.companyReject,
                                            params: [value.id],
                                        });
                                    }}
                                >
                                    <Row align="middle">
                                        <CloseOutlined /> Reject
                                    </Row>
                                </Button>
                            </Space>
                        </Space>
                    );
            },
        },
    ];

    const refresh = async () => {
        setFetching(true);
        const params = { ...pagination, ...filters };
        if (search) {
            params['q'] = search;
            params['page'] = 1;
        }
        try {
            const {
                data: { data },
            } = await API_SERVICE.getCompanies(params);
            data.items.forEach((item: any) => {
                if (item.latestFilingDate) {
                    item.latestFilingDate = moment.utc(item.latestFilingDate);
                }
                item.incorporationDate = moment.utc(item.incorporationDate);
                return item;
            });
            setCompanies(data.items);
            setTotal(data.total);
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        } finally {
            setFetching(false);
        }
    };
    useEffect(() => {
        refresh();
    }, [props.location, pagination, search, filters]);
    return (
        <div className={'company-wrapper page-wrapper'}>
            {/*@ts-ignore*/}
            <AdminListLayout
                title={'All Companies'}
                titleSearch={
                    <AccessControl id={40}>
                        <div className="search-bar">
                            <Search
                                placeholder="Search"
                                onSearch={(e) => {
                                    setSearch(e);
                                    setPagination({
                                        page: 1,
                                        perpage: pagination.perpage,
                                    });
                                }}
                            />
                        </div>
                    </AccessControl>
                }
                titleAction={
                    <AccessControl id={44}>
                        <Button
                            type={'primary'}
                            shape="round"
                            className="theme-button primary"
                            onClick={() => setAddNew(true)}
                            icon={<PlusOutlined />}
                        >
                            Add Company
                        </Button>
                    </AccessControl>
                }
                filters={
                    <Form
                        style={{ width: '250px' }}
                        onValuesChange={(obj) => {
                            setPagination({
                                page: 1,
                                perpage: pagination.perpage,
                            });
                            setFilters({ ...filters, ...obj });
                        }}
                    >
                        <AccessControl id={40}>
                            <Row gutter={20}>
                                <Col flex={'auto'}>
                                    <Form.Item
                                        name="approvalStatus"
                                        style={{ marginBottom: 0 }}
                                    >
                                        <Select
                                            placeholder="Filter by Status"
                                            mode={'multiple'}
                                            showArrow={true}
                                            showSearch={false}
                                            maxTagCount={1}
                                            allowClear
                                        >
                                            {approvalStatus.map((s: any) => {
                                                return (
                                                    <Select.Option value={s.id}>
                                                        {s.name}
                                                    </Select.Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </AccessControl>
                    </Form>
                }
                pagination={
                    <AccessControl id={40}>
                        <Pagination
                            showSizeChanger
                            showQuickJumper
                            current={pagination.page}
                            defaultCurrent={1}
                            total={total}
                            onChange={(page, size) => {
                                setPagination({ page: page, perpage: size });
                            }}
                        />
                    </AccessControl>
                }
            >
                {addNew && (
                    <CompanyCreateOrEditPage
                        onClose={(e: boolean) => {
                            if (e) {
                                refresh();
                            }
                            setAddNew(false);
                        }}
                    />
                )}
                {editCompany && (
                    <CompanyCreateOrEditPage
                        details={editCompany}
                        onClose={(e: boolean) => {
                            if (e) {
                                refresh();
                            }
                            setEditCompany(null);
                        }}
                    />
                )}
                <AccessControl id={40}>
                    <Table
                        loading={fetching}
                        scroll={{ x: true, y: 'calc(100vh - 280px)' }}
                        pagination={false}
                        dataSource={companies}
                        //@ts-ignore
                        columns={columns}
                    />
                </AccessControl>

                <ConfirmationModal
                    {...confirmationModal}
                    callback={(a: boolean) => {
                        setConfirmationModal({});
                        if (a) {
                            refresh();
                        }
                    }}
                />
            </AdminListLayout>
        </div>
    );
}
