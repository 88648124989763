import React from 'react';

import { Form, Input, Button, Row, Col, InputNumber, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import API_SERVICE from 'services/api-service';
import OTP from './OTP';

interface Props {
    onOTP: (otp: string) => void;
    loading: boolean;
    email: string;
    resendOtp: () => void;
}

export default function OTPForm({ onOTP, loading, email, resendOtp }: Props) {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const history = useHistory();

    function handleSubmit(values: any) {
        if (onOTP) {
            const { otp } = values;
            onOTP(otp);
        }
    }

    return (
        <Form
            className={'otp-form'}
            form={form}
            onFinish={handleSubmit}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            requiredMark={false}
        >
            <Form.Item
                style={{ alignItems: 'center', marginBottom: 0 }}
                label={'OTP'}
                name="otp"
                rules={[{ required: true, message: 'OTP required' }]}
            >
                <InputNumber
                    autoComplete={'off'}
                    placeholder="Enter one time password"
                    type="password"
                />
            </Form.Item>
            <Row justify={'end'}>
                <Col sm={18} className={'text-right'}>
                    <small>
                        didn't receive OTP?{' '}
                        <span
                            onClick={() => resendOtp()}
                            className="theme-link"
                        >
                            Re-send
                        </span>
                    </small>
                </Col>
            </Row>
            <br />
            <Form.Item style={{ justifyContent: 'flex-end' }}>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    shape="round"
                    className="theme-button primary full-width "
                >
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
}
