import React from 'react';

export function getUser(){
  const user = localStorage.getItem('user');
  if (user) return JSON.parse(user)
  else return false
}


export function getPermissions(){
  const user = localStorage.getItem('user');
  try {
    const permissions = JSON.parse(user).user.permissionIds
    if (permissions)
      return permissions
    else return []
  }
  catch {
    return []
  }
}
