import {
    Button,
    Checkbox,
    Col,
    Divider,
    Form,
    Input,
    Menu,
    Modal,
    Row,
    Select,
    Space,
    Typography,
    notification,
} from 'antd';
import React, { useEffect, useState } from 'react';
import API_SERVICE from '../../services/api-service';
import './index.scss';

import AccessControl from 'client/src/services/AccessControl';
import { getUser } from 'services/local-storage';

const { Text, Title, Link } = Typography;
export default function UserCreateOrEditPage({ onClose, details }: any) {
    const [form] = Form.useForm();
    const [saving, setSaving] = useState(false);
    const [roles, setRoles] = useState([] as any);
    const [selectedRole, setSelectedRole] = useState([] as any);
    const [companies, setCompanies] = useState([] as any);
    const user = getUser();
    const roleId = user.user.roleIds[0];

    const fetchCompanies = async () => {
        try {
            const {
                data: { data },
            } = await API_SERVICE.getCompanies({ perpage: 1000 });
            setCompanies(data.items);
            if (roleId === 4) {
                form.setFieldsValue({ companyId: data.items[0].id });
            }
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        }
    };
    const fetchRoles = async () => {
        try {
            const {
                data: { data },
            } = await API_SERVICE.getRoleSections();
            setRoles(data);
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        }
    };

    const getInitialValues = (details: any) => {
        if (details) {
            const obj = { ...details };
            const { companies, roles } = obj;
            if (companies.length) {
                obj.companyId = companies[0].id;
            }
            if (roles.length) {
                const roleIds: any[] = [];
                roles.map((role: any, index: number) => {
                    roleIds.push(role.id);
                });
                obj.roleIds = roleIds;
                // setSelectedRole(roleIds);
            }

            return obj;
        } else return {};
    };

    const resetCompany = () => {
        form.setFieldsValue({ companyId: undefined });
    };

    useEffect(() => {
        if (details) {
            const obj = { ...details };
            const { roles } = obj;

            const roleIds: any[] = [];
            roles.map((role: any, index: number) => {
                roleIds.push(role.id);
            });
            setSelectedRole(roleIds);
        }
        fetchRoles();
        fetchCompanies();
    }, []);

    return (
        <Modal
            title={
                <Space>
                    <Text>{details ? 'Edit User' : 'Add user'}</Text>
                </Space>
            }
            width={400}
            footer={null}
            visible={true}
            maskClosable={false}
            onOk={() => {
                form.submit();
            }}
            okText={details ? 'Update' : '+ Add'}
            okType="primary"
            okButtonProps={{ shape: 'round' }}
            cancelButtonProps={{ shape: 'round', className: 'btn-dark' }}
            onCancel={() => onClose()}
        >
            <Form
                form={form}
                initialValues={getInitialValues(details)}
                fields={[]}
                onFinish={(value) => {
                    const { companyId } = value;
                    const reqObj: any = {
                        ...value,
                        companyId: [companyId],
                    };
                    setSaving(true);
                    if (details) {
                        if (!reqObj['password']) {
                            delete reqObj['password'];
                        }
                        API_SERVICE.userUpdate(details.id, reqObj)
                            .then((d) => {
                                onClose(true);
                            })
                            .catch((e) => {
                                notification.error({
                                    message: API_SERVICE.handleErrors(e),
                                });
                            })
                            .finally(() => {
                                setSaving(false);
                            });
                    } else {
                        API_SERVICE.userCreate(reqObj)
                            .then((d) => {
                                onClose(true);
                            })
                            .catch((e) => {
                                notification.error({
                                    message: API_SERVICE.handleErrors(e),
                                });
                            })
                            .finally(() => {
                                setSaving(false);
                            });
                    }
                }}
                layout="vertical"
                requiredMark={true}
            >
                <Row gutter={20}>
                    <Col md={24} sm={24} xs={24}>
                        <Form.Item
                            label={'First Name'}
                            name={'firstName'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Name required',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col md={24} sm={24} xs={24}>
                        <Form.Item
                            label={'Last Name'}
                            name={'lastName'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Name required',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col md={24} sm={24} xs={24}>
                        <Form.Item
                            label={'Email ID'}
                            name={'email'}
                            rules={[
                                {
                                    required: true,
                                    type: 'email',
                                    message: 'Email required',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col md={24} sm={24} xs={24}>
                        <Form.Item
                            label={'Contact Number'}
                            name={'phoneNumber'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Phone required',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    {
                        <Col md={24} sm={24} xs={24}>
                            <Form.Item
                                label={'Select Role(s)'}
                                name={'roleIds'}
                                rules={
                                    [
                                        //{ required: true, message: 'Atleast one role is required.' },
                                    ]
                                }
                            >
                                <RoleSelect
                                    data={roles}
                                    setSelectedRole={setSelectedRole}
                                    resetCompany={resetCompany}
                                />
                            </Form.Item>
                        </Col>
                    }
                    <Col md={24} sm={24} xs={24}>
                        <Form.Item
                            label={'Select Company:'}
                            name={'companyId'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Company required',
                                },
                            ]}
                        >
                            <Select
                                disabled={roleId === 4 ? true : false}
                                mode={
                                    (selectedRole.includes(2) ||
                                        selectedRole.includes(3)) &&
                                    'multiple'
                                }
                                showSearch={true}
                                filterOption={(input, option: any) =>
                                    option?.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                options={companies.map((company: any) => {
                                    return {
                                        value: company.id,
                                        label: company.name,
                                    };
                                })}
                            ></Select>
                        </Form.Item>
                    </Col>

                    <Divider />
                    <Col md={24} sm={24} xs={24}>
                        <div className="text-right">
                            <Space>
                                <Button
                                    size="middle"
                                    shape="round"
                                    className="btn-dark primary"
                                    onClick={() => onClose()}
                                >
                                    Cancel
                                </Button>
                                {details ? (
                                    <AccessControl id={36}>
                                        <Button
                                            size="middle"
                                            htmlType="submit"
                                            shape="round"
                                            type="primary"
                                        >
                                            Update
                                        </Button>
                                    </AccessControl>
                                ) : (
                                    <AccessControl id={36}>
                                        <Button
                                            size="middle"
                                            htmlType="submit"
                                            shape="round"
                                            type="primary"
                                        >
                                            + Add
                                        </Button>
                                    </AccessControl>
                                )}
                            </Space>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}

function RoleSelect(props: any) {
    const sections = props.data || [];
    const [roleIds, setRoleIds] = useState(props.value || []);
    const [active_section, set_active_section] = useState('');

    useEffect(() => {
        props.onChange(roleIds);
    }, [roleIds]);

    return (
        <div>
            {sections.map((section: any, index: number) => {
                const isSectionActive = section.name === active_section;
                return (
                    <div className="role-section">
                        {section.roles && section.roles.length ? (
                            section.roles.map(
                                (role: any, roleIndex: number) => {
                                    if (
                                        roleIds.includes(role.id) &&
                                        !isSectionActive
                                    ) {
                                        set_active_section(section.name);
                                    }
                                    return (
                                        <Row gutter={25}>
                                            <Col>
                                                <Checkbox
                                                    disabled={
                                                        roleIds.length &&
                                                        !isSectionActive
                                                    }
                                                    checked={roleIds.includes(
                                                        role.id,
                                                    )}
                                                    onChange={() => {
                                                        // props.resetCompany();
                                                        const roles =
                                                            isSectionActive
                                                                ? [...roleIds]
                                                                : [];
                                                        const index =
                                                            roles.indexOf(
                                                                role.id,
                                                            );
                                                        if (index > -1) {
                                                            roles.splice(
                                                                index,
                                                                1,
                                                            );
                                                            setRoleIds(roles);
                                                            props.setSelectedRole(
                                                                roles,
                                                            );
                                                        } else {
                                                            setRoleIds(
                                                                roles.concat([
                                                                    role.id,
                                                                ]),
                                                            );
                                                            props.setSelectedRole(
                                                                roles.concat([
                                                                    role.id,
                                                                ]),
                                                            );
                                                        }
                                                    }}
                                                />
                                            </Col>
                                            <Text>{role.name}</Text>
                                        </Row>
                                    );
                                },
                            )
                        ) : (
                            <Text>No roles present in {section.name}</Text>
                        )}
                    </div>
                );
            })}
        </div>
    );
}
