import React, { CSSProperties, useEffect, useState } from 'react';
import './index.scss';
import {
    Button,
    Menu,
    Modal,
    notification,
    Space,
    Form,
    Typography,
    Input,
    Row,
    Col,
    Select,
    DatePicker, Checkbox,
} from 'antd';
import API_SERVICE from '../../../services/api-service';
import moment from 'moment';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const { Text } = Typography;


export default function ChallengeCreateOrEditPage({ onClose, details, claimId, projectId }: any) {
    const [form] = Form.useForm();
    const [saving, setSaving] = useState(false);
    const [fetching, setFetching] = useState(false);


    const [isOngoing, setIsOngoing] = useState(false);

    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');



    useEffect(() => {
        details && details.question && setQuestion(details.question);
        details && details.answer && setAnswer(details.answer);
    }, [details]);



    return (
        <Modal title={
            (<Space>
                <Text>{details ? 'Edit Uncertainty / Advancement' : 'Add New Uncertainty / Advancement'}</Text>
            </Space>)
        }
               width={500}
               visible={true}
               maskClosable={false}
               cancelText="Cancel"
               okText={details ? "Update" : "Add"}
               okType="primary"
               okButtonProps={{ shape: 'round' }}
               cancelButtonProps={{ shape: 'round', className: 'btn-dark' }}
               onOk={
                   () => {
                       form.submit();
                   }
               }
               onCancel={() => onClose()}>

            <Form
                form={form}
                initialValues={details || {}}
                fields={[]}
                onFinish={(value) => {
                    const reqObj = { ...value };
                    setSaving(true);
                    if (details) {
                        // console.log(reqObj); return;
                        API_SERVICE.updateProjectChallenge(claimId, projectId, details.id, reqObj).then((d) => {
                            notification.success({ message: 'Uncertainty updated successfully'});
                            onClose(true);
                        }).catch((e) => {
                            notification.error({ message: API_SERVICE.handleErrors(e) });
                        }).finally(() => {
                            setSaving(false);
                        });
                    } else {
                        // console.log(reqObj); return;
                        API_SERVICE.createProjectChallenge(claimId, projectId, reqObj).then((d) => {
                            notification.success({ message: 'Uncertainty created successfully'});
                            onClose(true);
                        }).catch((e) => {
                            notification.error({ message: API_SERVICE.handleErrors(e) });
                        }).finally(() => {
                            setSaving(false);
                        });
                    }
                }}
                layout='horizontal'
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 12 }}
                requiredMark={true}
            >

                <Form.Item
                    label={'Uncertainty / Advancement Title:'}
                    name={'name'}
                    rules={[
                        { required: true, message: 'Uncertainty / Advancement Required' },
                    ]}
                >
                    <Input placeholder="enter uncertainty name here" />
                </Form.Item>

            </Form>

        </Modal>
    );
}

