import React, { CSSProperties, useEffect, useState } from 'react';
import './index.scss';
import { Space, Typography, Row, Col, Divider, Button, notification } from 'antd';
import { useHistory } from 'react-router-dom';
import AdminListLayout from 'shared/components/layout/AdminListLayout/AdminListLayout';
import { ShopOutlined, PlusCircleOutlined } from '@ant-design/icons';
import Search from 'antd/es/input/Search';
import API_SERVICE from '../../services/api-service';
import TextArea from 'antd/es/input/TextArea';

import ChangePassword from "./ChangePassword";
import EditProfile from "./EditProfile";



const { Text, Title, Link } = Typography;


export default function UserProfile(props: any) {

    return (
      <div className={'role-wrapper page-wrapper'} style={{flex: 1, padding: '30px'}}>
          {/*@ts-ignore*/}
        <div className="profile_section">
            <h1>Profile Management</h1>
        </div>
        <div className="profile_section">
            <h1>User Profile</h1>
            <h1>Change Password</h1>
        </div>
        <Divider/><br/>
        <div className="profile_section">
            <EditProfile />
            <ChangePassword />
        </div>
      </div>
    );
}
