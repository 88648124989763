import React, {useState, useEffect} from 'react';
import {Upload, notification} from 'antd';
import API_SERVICE from '../services/api-service';


export default function FileUpload(props: any) {

  async function handleUpload(file: any) {
      try {
         const response = await API_SERVICE.fileUpload(props.type, file);
         const { data: { id, url } } = response.data;
         props.onChange(id);
         if (props.callback) {
           props.callback({ id, url });
         }
      } catch (e) {
         notification.error({message: API_SERVICE.handleErrors(e)});
      }
  }

  return (
    <Upload
       showUploadList={false}
       beforeUpload={(file) => {
          if (file && file.type.includes('image')) {
            handleUpload(file);
          }
          return false;
        }}
       >
       {props.children}
    </Upload>
  );
}
