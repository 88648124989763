import React, {CSSProperties, useEffect, useState} from 'react';
import './index.scss';
import {Button, Menu, notification, Space, Table, Tag, Typography, Pagination, Form, Row, Col, Select} from 'antd';
import {useHistory} from 'react-router-dom';
import AdminListLayout from 'shared/components/layout/AdminListLayout/AdminListLayout';
import Search from 'antd/es/input/Search';
import API_SERVICE from '../../services/api-service';
import RoleCreateOrEditPage from './RolesCreateOrEditPage';
import { ConfirmationModal } from "../../components";

import { PlusOutlined } from '@ant-design/icons';
import { VisibilityOutlined, EditOutlined, DeleteOutlined } from '@material-ui/icons';
import AccessControl from 'services/AccessControl';



const {SubMenu} = Menu;

const {Text, Title, Link} = Typography;

const status = [
    {
        name: 'Active',
        id: 1
    },
    {
        name: 'Inactive',
        id: 0
    },
];

export default function RolePage(props: any) {

    const history = useHistory();
    const [roles, setRoles] = useState([] as any[]);
    const [search, setSearch] = useState('' as string);
    const [addNew, setAddNew] = useState(false);
    const [editRole, setEditRole] = useState(null as any);
    const [preview, setPreview] = useState(null as any);
    const [fetching, setFetching] = useState(false);
    const [pagination, setPagination] = useState({page: 1, perpage: '10'} as any);
    const [total, setTotal] = useState(10);
    const [filters, setFilters] = useState({} as any);
    const [confirmationModal, setConfirmationModal] = useState({} as any);
    const columns = [
        {
            title: 'S.No',
            key: 'sNO',
            render: (a: any, v: any, index: number) =>
                <Text>{(pagination.page - 1) * (pagination.perpage) + index + 1}</Text>,
        },
        {
            title: 'Role Name',
            key: 'name',
            render: (a: any) => <Text>{a.name}</Text>,
        },
        {
            title: 'Role Description',
            key: 'description',
            render: (a: any) => <Text>{a.description}</Text>,
        },
        {
            title: 'Actions | Role & Permissions',
            key: 'action',
            render: (value: any) => {
                return (
                    <Space>
                        <AccessControl id = {54}>
                        <Space className="px-2">
                            <Button shape='round' className='theme-button primary' icon={<VisibilityOutlined />}
                                    onClick={() => {
                                        setPreview(value);
                                    }}/>
                        </Space>
                        </AccessControl>
                        <AccessControl id = {54}>
                        <Space>
                            <Button shape='round' className={value.status ? 'success-button' : 'info-button'}
                                    onClick={()=> {
                                        const reqObj = {
                                          status: value.status ? 0 : 1
                                        }
                                        setConfirmationModal({
                                            message: `Change status to ${value.status? "InActive" : "Active"}`,
                                            action: API_SERVICE.roleStatus,
                                            params: [value.id, reqObj]
                                        })
                                      }}>
                                    {value.status ? "Active" : "InActive"}
                            </Button>
                        </Space>                            
                        </AccessControl>
                        <AccessControl id = {54}>
                        <Space className="px-2">
                            <Button shape='round' className='theme-button primary ant-btn-icon-only-img' icon={<EditOutlined />}
                                    onClick={() => {
                                        setEditRole(value);
                                    }}/>
                        </Space>
                        </AccessControl>
                        <AccessControl id = {56}>
                        <Space>
                            <Button type='default' shape='round' className='gray-button ant-btn-icon-only-img' icon={<DeleteOutlined />}
                                    onClick={() => {
                                        setConfirmationModal({
                                            message: `Delete role?`,
                                            action: API_SERVICE.roleDelete,
                                            params: [value.id],
                                        })
                                    }}/>
                        </Space>
                        </AccessControl>
                    </Space>
                  );
              }
        },
    ];
    const refresh = async () => {
        setFetching(true);
        const params = {...pagination, ...filters};
        if (search) {
            params['q'] = search;
        }
        try {
            const {data: {data}} = await API_SERVICE.getRoles(params);

            setRoles(data.items);
            setTotal(6);
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        } finally {
            setFetching(false);
        }
    };
    useEffect(() => {
        refresh();
    }, [props.location, pagination, search, filters]);
    return (
        <div className={'role-wrapper page-wrapper'}>
            {/*@ts-ignore*/}
            <AdminListLayout title={'All Roles'}
                             titleSearch={<AccessControl id = {51}>
                             <div className='search-bar'>
                                 <Search
                                     placeholder='Search'
                                     onSearch={
                                         (e) => {
                                             setSearch(e);
                                         }
                                     }
                                 />
                             </div>
                             </AccessControl>}
                             titleAction={
                                 <AccessControl id = {52}>
                                 <Button type={'primary'} shape='round' className='theme-button primary'
                                         onClick={() => setAddNew(true)} icon={<PlusOutlined/>}>
                                     Add Role
                                 </Button>
                                 </AccessControl>
                             }
                             filters={
                                 <Form style={{flex: 'auto'}}
                                     onValuesChange={(obj)=>{
                                       setFilters({...filters, ...obj})
                                     }}
                                     >
                                    <AccessControl id = {51}>
                                     <Row gutter={20}>
                                         <Col flex={'auto'}>
                                             <Form.Item name="status" style={{marginBottom: 0}}>
                                                 <Select placeholder="Filter by Status" allowClear>
                                                     {
                                                         status.map((s: any) => {
                                                             return <Select.Option value={s.id}>{s.name}</Select.Option>;
                                                         })
                                                     }
                                                 </Select>
                                             </Form.Item>
                                         </Col>
                                     </Row>
                                     </AccessControl>
                                 </Form>
                             }
                             pagination={
                                 <AccessControl id = {51}>
                                 <Pagination showSizeChanger showQuickJumper defaultCurrent={1} total={total}
                                             onChange={(page, size) => setPagination({page: page, perpage: size})}/>
                             </AccessControl>}
            >
                {
                    addNew && <RoleCreateOrEditPage
                        allPermissions={roles.length && roles[0]}
                        onClose={
                            (e: boolean) => {
                                if (e) {
                                    refresh();
                                }
                                setAddNew(false);
                            }
                        }/>
                }
                {
                    editRole && <RoleCreateOrEditPage
                        details={editRole}
                        onClose={
                            (e: boolean) => {
                                if (e) {
                                    refresh();
                                }
                                setEditRole(null);
                            }
                        }/>
                }
                {
                    preview && <RoleCreateOrEditPage
                        details={preview}
                        onClose={
                            (e: boolean) => {
                                if (e) {
                                    refresh();
                                }
                                setPreview(null);
                            }
                          }
                        preview />
                }
                <AccessControl id = {51}>
                <Table loading={fetching} scroll={{x: true}} pagination={false} dataSource={roles}
                       columns={columns}/>
                </AccessControl>
                 <ConfirmationModal {...confirmationModal}
                       callback={(a: boolean)=>{
                         setConfirmationModal({})
                         if (a) {
                           refresh();
                         }
                       }} />

            </AdminListLayout>
        </div>
    );
}
