import React, { useState, useEffect } from 'react';
import { Table, Typography, notification, Space, Form, Input } from 'antd';
import API_SERVICE from 'services/api-service';
import { PrimaryTooltip } from 'components';
const { Text, Title } = Typography;
export default function ExternalWorkersTable({ claimId, claimDetails }: any) {
    const [EWorkersTable, setEWorkersTable] = useState([] as any[]);
    const [externalWorkerCostTotal, setExternalWorkerCostTotal] = useState({
        netAmount: 0,
        rnd: 0,
        externalWorkerAdjustment: 0,
        total: 0,
    } as any);
    const [fetching, setFetching] = useState(false);

    const [columns, setColumns] = useState([
        {
            title: 'S.No',
            dataIndex: 'sNO',
            width: '70px',
            render: (a: any, v: any, index: number) => <Text>{index + 1}</Text>,
        },
        {
            title: 'External Worker Name',
            dataIndex: 'name',
            width: '200px',
            render: (a: any) => <Text>{a}</Text>,
        },
        {
            title: 'Role',
            dataIndex: 'role',
            width: '180px',
            render: (a: any) => <Text>{a}</Text>,
        },
        {
            title: 'Agency',
            dataIndex: 'agency',
            width: '180px',
            render: (a: any) => <Text>{a}</Text>,
        },
        {
            title: 'Net Amount (GBP)',
            dataIndex: 'netAmount',
            width: '300px',
            render: (a: any) => <Text>{a}</Text>,
        },
        {
            title: 'R&D Allocation (%)',
            dataIndex: 'rndAllocationPercentage',
            width: '220px',
            render: (a: any) => <Text>{a}</Text>,
        },
        {
            title: 'Is Connected?',
            dataIndex: 'isConnected',
            width: '150px',
            render: (a: any) => <Text>{a ? 'Yes' : 'No'}</Text>,
        },
        {
            title: 'Worker Adjustment (%)',
            dataIndex: 'adjustmentsPercentage',
            width: '260px',
            render: (a: any) => <Text>{a}</Text>,
        },
        {
            title: 'Total (GBP)',
            dataIndex: 'total',
            width: '180px',
            render: (a: any) => <Text>{a}</Text>,
        },
    ]);

    const getExternalWorkerCostList = async () => {
        setFetching(true);
        try {
            const {
                data: { data },
            } = await API_SERVICE.externalWorkerCostList(claimId);
            setEWorkersTable(data.items);
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        } finally {
            setFetching(false);
        }
    };

    const getExternalWorkerCostTotal = async () => {
        try {
            await API_SERVICE.externalWorkerCostTotal(claimId).then(
                (result) => {
                    const {
                        data: { data },
                    } = result;
                    setExternalWorkerCostTotal(data);
                },
            );
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        }
    };

    const RDECColumn = [
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="RDEC Allocation (%)"
                />
            ),
            key: 'rdecAllocation',
            width: '200px',
            render: (a: any) => (
                <Text>{`${
                    100 - (a.smeAllocation ? a.smeAllocation : 0)
                } %`}</Text>
            ),
        },
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="RDEC Allocation (GBP)"
                />
            ),
            dataIndex: 'rdecTotal',
            width: '200px',
            render: (a: any) => (
                <Text>
                    {`£ ${a ? a : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </Text>
            ),
        },
    ];

    const SMEColumn = [
        {
            title: (
                <PrimaryTooltip title="prompt text" text="SME Allocation (%)" />
            ),
            dataIndex: 'smeAllocation',
            width: '200px',
        },
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="SME Allocation (GBP)"
                />
            ),
            dataIndex: 'smeTotal',
            width: '200px',
            render: (a: any) => (
                <Text>
                    {`£ ${a ? a : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </Text>
            ),
        },
    ];

    const MIXEDColumn = [
        {
            title: (
                <PrimaryTooltip title="prompt text" text="SME Allocation (%)" />
            ),
            dataIndex: 'smeAllocation',
            width: '200px',
        },
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="SME Allocation (GBP)"
                />
            ),
            dataIndex: 'smeTotal',
            width: '200px',
            render: (a: any) => (
                <Text>
                    {`£ ${a ? a : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </Text>
            ),
        },
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="RDEC Allocation (%)"
                />
            ),
            key: 'rdecAllocation',
            width: '200px',
            render: (a: any) => (
                <Text>{`${
                    100 - (a.smeAllocation ? a.smeAllocation : 0)
                } %`}</Text>
            ),
        },
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="RDEC Allocation (GBP)"
                />
            ),
            dataIndex: 'rdecTotal',
            width: '200px',
            render: (a: any) => (
                <Text>
                    {`£ ${a ? a : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </Text>
            ),
        },
    ];

    const checkClaimType = () => {
        if (claimDetails?.type?.name === 'SME') {
            let newColumns: any = [...columns, ...SMEColumn];
            setColumns(newColumns);
        } else if (claimDetails?.type?.name === 'RDEC') {
            let newColumns: any = [...columns, ...RDECColumn];
            setColumns(newColumns);
        } else if (claimDetails?.type?.name === 'Mixed') {
            console.log('mixed', MIXEDColumn);

            let newColumns: any = [...columns, ...MIXEDColumn];
            setColumns(newColumns);
        }
    };

    useEffect(() => {
        getExternalWorkerCostList();
        getExternalWorkerCostTotal();
        checkClaimType();
    }, []);

    return (
        <>
            <Title level={4}>External Workers </Title>
            <Table
                loading={fetching}
                pagination={false}
                scroll={{ x: true }}
                columns={columns}
                dataSource={EWorkersTable}
                bordered
            />
            <div className="d-flex align-items-center justify-content-end mt-3">
                <Space>
                    <Form.Item className="mb-0" style={{ width: '220px' }}>
                        <Input
                            disabled
                            value={`Net Amount: ${
                                externalWorkerCostTotal.netAmount &&
                                externalWorkerCostTotal.netAmount.toFixed(2)
                            } GBP`}
                        />
                    </Form.Item>
                    <Form.Item className="mb-0" style={{ width: '220px' }}>
                        <Input
                            disabled
                            value={`Total: ${
                                externalWorkerCostTotal.total &&
                                externalWorkerCostTotal.total.toFixed(2)
                            } GBP`}
                        />
                    </Form.Item>
                </Space>
            </div>
        </>
    );
}
