import React, { CSSProperties, useEffect, useState } from 'react';
import {
    Button,
    Menu,
    notification,
    Space,
    Table,
    Tag,
    Typography,
    Modal,
    Pagination,
    Form,
    Select,
    Row,
    Col,
    Progress,
    Popconfirm,
} from 'antd';
import { useHistory } from 'react-router-dom';
import API_SERVICE from '../../../services/api-service';
import './index.scss';
import { DownloadOutlined, CheckOutlined } from '@ant-design/icons';
import pound from 'shared/assets/icons/pound.svg';
import { FINALIZEFINANCIAL, FINANCIALS } from 'shared/constants/RouteConstants';
import { TECHNICALS } from 'shared/constants/RouteConstants';
import moment from 'moment';
import { Link } from 'react-router-dom';
import AccessControl from '../../../services/AccessControl';
import { commaAddingInPrice } from 'shared/services/Utility';

const { Text, Title } = Typography;
export default function ClaimOverviewPage(props: any) {
    const history = useHistory();
    const [fetching, setFetching] = useState(false);
    const [currentStage, setCurrentStage] = useState(0);
    const [claimStages, setClaimStages] = useState([] as any);

    const [details, setDetails] = useState({
        id: null,
        company: { name: '' },
        manager: {
            user: { name: '' },
        },
        type: {
            displayName: '',
        },
        inProgressFinancial: 0,
        inProgressTechnical: 0,
        financialYear: 0,
        value: 0,
        claimStartDate: '',
        claimEndDate: '',
        deadlineInternal: '',
        deadlineExternal: '',
        claimDate: '',
    });
    const [allProgress, setAllProgress] = useState({
        financial: 0,
        technical: 0,
        amendment: 0,
        submitted: 0,
    });

    const getClaimStages = async () => {
        try {
            const {
                data: { data },
            } = await API_SERVICE.getClaimStages();
            setClaimStages(data.items.sort((a: any, b: any) => a.id - b.id));
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        }
    };

    const setProgressBarValue = () => {
        //@ts-ignore
        let currentStage = details && details.stage && details.stage.id;
        if (currentStage === 1) {
            if (
                details.inProgressFinancial === 1 &&
                details.inProgressTechnical === 0
            ) {
                setAllProgress({
                    financial: 50,
                    technical: 0,
                    amendment: 0,
                    submitted: 0,
                });
            }
            if (
                details.inProgressFinancial === 0 &&
                details.inProgressTechnical === 1
            ) {
                setAllProgress({
                    technical: 50,
                    financial: 0,
                    amendment: 0,
                    submitted: 0,
                });
            }
            if (
                details.inProgressFinancial === 1 &&
                details.inProgressTechnical === 1
            ) {
                setAllProgress({
                    technical: 50,
                    financial: 50,
                    amendment: 0,
                    submitted: 0,
                });
            }
        }
        if (currentStage === 2) {
            if (
                details.inProgressFinancial === 1 &&
                details.inProgressTechnical === 0
            ) {
                setAllProgress({
                    financial: 100,
                    technical: 0,
                    amendment: 0,
                    submitted: 0,
                });
            }

            if (
                details.inProgressFinancial === 1 &&
                details.inProgressTechnical === 1
            ) {
                setAllProgress({
                    financial: 100,
                    technical: 50,
                    amendment: 0,
                    submitted: 0,
                });
            }
        }

        if (currentStage === 3) {
            if (
                details.inProgressFinancial === 0 &&
                details.inProgressTechnical === 1
            ) {
                setAllProgress({
                    financial: 0,
                    technical: 100,
                    amendment: 0,
                    submitted: 0,
                });
            }
            if (
                details.inProgressFinancial === 1 &&
                details.inProgressTechnical === 1
            ) {
                setAllProgress({
                    financial: 100,
                    technical: 100,
                    amendment: 0,
                    submitted: 0,
                });
            }
        }
        if (currentStage === 4) {
            setAllProgress({
                financial: 100,
                technical: 100,
                amendment: 100,
                submitted: 0,
            });
        }

        if (currentStage === 5) {
            setAllProgress({
                financial: 100,
                technical: 100,
                amendment: 100,
                submitted: 100,
            });
        }
    };

    const refresh = async () => {
        const {
            match: { params },
        } = props;
        setFetching(true);
        try {
            const {
                data: { data },
            } = await API_SERVICE.getClaimById(params.claimId);
            setDetails(data);
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        } finally {
            setFetching(false);
        }
    };

    useEffect(() => {
        refresh();
        getClaimStages();
    }, []);

    useEffect(() => {
        //@ts-ignore
        details && details.stage && setCurrentStage(details.stage.id);
        setProgressBarValue();
    }, [details]);

    const finalizeTechnicalStage = async () => {
        try {
            const {
                match: { params },
            } = props;
            const {
                data: { data },
            } = await API_SERVICE.finalizeTechnicals(params.claimId);

            refresh();
            notification.success({
                message: 'Technical Data successfully Finalised',
            });
        } catch (error) {
            notification.error({
                message: API_SERVICE.handleErrors(error),
            });
        }
    };

    const finalizeFinalStage = async () => {
        try {
            const {
                match: { params },
            } = props;
            await API_SERVICE.finalizeFinalStage(params.claimId);
            notification.success({
                message: 'Claim successfully Finalised',
            });
            refresh();
        } catch (error) {
            notification.error({ message: API_SERVICE.handleErrors(error) });
        }
    };

    const handleDownload = async () => {
        const {
            match: { params },
        } = props;
        try {
            const {
                data: { data },
            } = await API_SERVICE.downloadFinancialReport(params.claimId);
            window.open(API_SERVICE.downloadReport(data.url));
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        }
    };

    const handleDownloadTechnicalReport = async () => {
        const {
            match: { params },
        } = props;
        try {
            const {
                data: { data },
            } = await API_SERVICE.downloadTechnicialReport(params.claimId);
            const a = document.createElement('a');
            a.href = data.filePath;
            a.download = 'TechnicalReport';
            a.click();
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        }
    };

    return (
        <div className={'claim-overview-wrapper page-wrapper'}>
            <div>
                <Row justify={'center'}>
                    <Col xs={20}>
                        <h1 className="text-center page-title">
                            Overview | Claim ID: {details.id}
                        </h1>

                        <Row>
                            <Col
                                className="overview-detail-table-column"
                                xs={24}
                                sm={24}
                                md={12}
                                lg={6}
                            >
                                <div className="d-flex align-items-center h-100">
                                    <table className="overview-detail-table">
                                        <tr>
                                            <th>Company Name:</th>
                                            <td>{details?.company?.name}</td>
                                        </tr>
                                        <tr>
                                            <th>Claim Tax Year:</th>
                                            <td>
                                                {details?.financialYear} -{' '}
                                                {details?.financialYear + 1}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Manager Name:</th>
                                            <td>
                                                {details?.manager?.user?.name}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </Col>
                            <Col
                                className="overview-detail-table-column"
                                xs={24}
                                sm={24}
                                md={12}
                                lg={6}
                            >
                                <div className="d-flex align-items-center h-100">
                                    <table className="overview-detail-table">
                                        <tr>
                                            <th>Claim Type:</th>
                                            <td>
                                                {details?.type?.displayName}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Claim Value:</th>
                                            <td>
                                                {commaAddingInPrice(
                                                    details?.value,
                                                )}{' '}
                                                GBP
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </Col>
                            <Col
                                className="overview-detail-table-column"
                                xs={24}
                                sm={24}
                                md={12}
                                lg={6}
                            >
                                <div className="d-flex align-items-center h-100">
                                    <table className="overview-detail-table">
                                        <tr>
                                            <th>Start Date:</th>
                                            <td>
                                                {moment(
                                                    details.claimStartDate,
                                                ).format('DD/MM/YYYY')}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>End Date:</th>
                                            <td>
                                                {moment(
                                                    details.claimEndDate,
                                                ).format('DD/MM/YYYY')}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </Col>
                            <Col
                                className="overview-detail-table-column"
                                xs={24}
                                sm={24}
                                md={12}
                                lg={6}
                            >
                                <div className="d-flex align-items-center h-100">
                                    <table className="overview-detail-table">
                                        <tr>
                                            <th>Internal Deadline:</th>
                                            <td>
                                                {moment(
                                                    details.deadlineInternal,
                                                ).format('DD/MM/YYYY')}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>External Deadline:</th>
                                            <td>
                                                {moment(
                                                    details.deadlineExternal,
                                                ).format('DD/MM/YYYY')}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Claim Date:</th>
                                            <td>
                                                {details.claimDate
                                                    ? moment(
                                                          details.claimDate,
                                                      ).format('DD/MM/YYYY')
                                                    : 'N/A'}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </Col>
                        </Row>
                        <Row
                            justify="center"
                            align="middle"
                            className="rowGap"
                            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                        >
                            <Col xs={24} sm={24} md={6} lg={5}>
                                <div
                                    className={'d-flex justify-content-between'}
                                    style={{
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Progress
                                        strokeColor={{
                                            '0%': '#108ee9',
                                            '100%': '#87d068',
                                        }}
                                        percent={100}
                                        showInfo={false}
                                    />

                                    <span
                                        className={`steps move-left ${
                                            currentStage === 1 ? 'active' : ''
                                        }`}
                                    >
                                        {claimStages[0]?.id === 1 &&
                                            claimStages[0]?.displayName}
                                    </span>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={6} lg={5}>
                                <Row>
                                    <Col xs={24}>
                                        <div
                                            className={
                                                'd-flex justify-content-between'
                                            }
                                            style={{
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Progress
                                                strokeColor={{
                                                    '0%': '#108ee9',
                                                    '100%': '#87d068',
                                                }}
                                                percent={allProgress.financial}
                                                showInfo={false}
                                            />

                                            <span
                                                className={`steps move-left ${
                                                    currentStage === 2
                                                        ? 'active'
                                                        : ''
                                                }`}
                                            >
                                                {claimStages[1]?.id === 2 &&
                                                    claimStages[1]?.displayName}
                                            </span>
                                        </div>
                                    </Col>
                                    <Col xs={24}>
                                        <div
                                            className={
                                                'd-flex justify-content-between'
                                            }
                                            style={{
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Progress
                                                strokeColor={{
                                                    '0%': '#108ee9',
                                                    '100%': '#87d068',
                                                }}
                                                percent={allProgress.technical}
                                                showInfo={false}
                                            />

                                            <span
                                                className={`steps move-left ${
                                                    currentStage === 3
                                                        ? 'active'
                                                        : ''
                                                }`}
                                            >
                                                {claimStages[2]?.id === 3 &&
                                                    claimStages[2]?.displayName}
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24} sm={24} md={6} lg={5}>
                                <div
                                    className={'d-flex justify-content-between'}
                                    style={{
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Progress
                                        strokeColor={{
                                            '0%': '#108ee9',
                                            '100%': '#87d068',
                                        }}
                                        percent={allProgress.amendment}
                                        showInfo={false}
                                    />

                                    <span
                                        className={`steps move-left ${
                                            currentStage === 4 ? 'active' : ''
                                        }`}
                                    >
                                        {claimStages[3]?.id === 4 &&
                                            claimStages[3]?.displayName}
                                    </span>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={6} lg={5}>
                                <div
                                    className={'d-flex justify-content-between'}
                                    style={{
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Progress
                                        strokeColor={{
                                            '0%': '#108ee9',
                                            '100%': '#87d068',
                                        }}
                                        percent={allProgress.submitted}
                                        showInfo={false}
                                    />

                                    <span
                                        className={`steps move-left ${
                                            currentStage === 5 ? 'active' : ''
                                        }`}
                                    >
                                        {' '}
                                        {claimStages[4]?.id === 5 &&
                                            claimStages[4]?.displayName}
                                    </span>
                                </div>
                            </Col>
                        </Row>
                        <Row justify={'center'} className="my-5">
                            <div>
                                <Button
                                    type={'primary'}
                                    shape="round"
                                    className="theme-button primary"
                                    icon={<DownloadOutlined />}
                                >
                                    Download Report (Financial & Technical)
                                </Button>
                            </div>
                        </Row>

                        <Row
                            justify={'center'}
                            style={{ marginBottom: '60px' }}
                            className="financial-technical-row"
                        >
                            <AccessControl id={62}>
                                <Col xs={24} sm={24} md={12} lg={12}>
                                    <div className="text-center py-3">
                                        <Button
                                            type={'primary'}
                                            shape="round"
                                            className="black-button lg"
                                        >
                                            <Link
                                                to={`${FINANCIALS}/${details.id}`}
                                            >
                                                <div className="d-flex align-items-center">
                                                    <img
                                                        src={pound}
                                                        alt="pound"
                                                    />
                                                    <div className="text-left">
                                                        <h2>Financial</h2>
                                                        <small>Add Data</small>
                                                    </div>
                                                </div>
                                            </Link>
                                        </Button>
                                        <br />
                                        <br />

                                        <Button
                                            shape="round"
                                            className="black-button my-3"
                                            icon={<CheckOutlined />}
                                        >
                                            <Link
                                                to={`${FINALIZEFINANCIAL}/${details.id}`}
                                            >
                                                Finalize Financials
                                            </Link>{' '}
                                        </Button>
                                        <br />
                                        <Button
                                            onClick={handleDownload}
                                            shape="round"
                                            className="black-button"
                                            icon={<DownloadOutlined />}
                                        >
                                            Download Financial Report
                                        </Button>
                                    </div>
                                </Col>
                            </AccessControl>
                            <AccessControl id={92}>
                                <Col xs={24} sm={24} md={12} lg={12}>
                                    <div className="text-center py-3">
                                        <Button
                                            type={'primary'}
                                            shape="round"
                                            className="black-button lg"
                                        >
                                            <Link
                                                to={`${TECHNICALS}/${details.id}`}
                                            >
                                                <div className="d-flex align-items-center">
                                                    <img
                                                        src={pound}
                                                        alt="pound"
                                                    />
                                                    <div className="text-left">
                                                        <h2>Technical</h2>
                                                        <small>Add Data</small>
                                                    </div>
                                                </div>
                                            </Link>
                                        </Button>
                                        <br />
                                        <br />
                                        <Popconfirm
                                            title="Are you sure to you want to Finalize Report"
                                            onConfirm={() =>
                                                finalizeTechnicalStage()
                                            }
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button
                                                type="link"
                                                shape="round"
                                                className="black-button my-3"
                                                icon={<CheckOutlined />}
                                            >
                                                Finalize Technical
                                            </Button>
                                        </Popconfirm>
                                        <br />
                                        <Button
                                            onClick={
                                                handleDownloadTechnicalReport
                                            }
                                            shape="round"
                                            className="black-button"
                                            icon={<DownloadOutlined />}
                                        >
                                            Download Technical Report
                                        </Button>
                                    </div>
                                </Col>
                            </AccessControl>
                        </Row>
                    </Col>
                    {currentStage >= 3 && (
                        <Col xs={24} className="finalizeSubmitBtn ">
                            <Popconfirm
                                title="Are you sure to you want to Finalize Claim"
                                onConfirm={() => finalizeFinalStage()}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button
                                    type="link"
                                    shape="round"
                                    className="black-button"
                                    icon={
                                        <CheckOutlined className="check-icon " />
                                    }
                                >
                                    Finalize Claim
                                </Button>
                            </Popconfirm>
                        </Col>
                    )}
                </Row>
            </div>
        </div>
    );
}
