import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';
import { COMPANY_LIST } from 'shared/constants/RouteConstants';
import { withRouter } from 'react-router-dom';

export class DonutChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            series: [props.sme, props.nonsme],

            options: {
                chart: {
                    id: 'chart4',
                    type: 'donut',
                    events: {
                        dataPointSelection: (event, chartContext, config) => {
                            this.props.history.push(COMPANY_LIST);
                        },
                    },
                },

                plotOptions: {
                    pie: {
                        donut: {
                            size: '65%',
                            labels: {
                                show: true,
                                value: {
                                    fontSize: '25px',
                                },
                                total: {
                                    show: true,
                                    showAlways: true,
                                    fontSize: '12px',
                                    label: `No of Companies`,
                                    color: '#010101',
                                },
                            },
                        },
                    },
                },
                labels: ['SME', 'NON-SME'],
                colors: ['#70b7e1', '#EF8030'],
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: '130%',
                            },
                            legend: {
                                position: 'bottom',
                            },
                            plotOptions: {
                                pie: {
                                    donut: {
                                        labels: {
                                            value: {
                                                fontSize: '22px',
                                            },
                                            total: {
                                                show: true,
                                                showAlways: true,
                                                fontSize: '11px',
                                                label: 'Total No of Companies',
                                                color: '#010101',
                                            },
                                        },
                                    },
                                },
                            },
                        },
                    },
                ],
            },
        };
    }
    render() {
        return (
            <div id="chart" style={{ width: '75%' }}>
                <ReactApexChart
                    options={this.state.options}
                    series={this.state.series}
                    type="donut"
                />
            </div>
        );
    }
}

export default withRouter(DonutChart);
