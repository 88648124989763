import React from 'react';
import './DeadlineContainerBox.scss';
import { useHistory } from 'react-router-dom';
import { CLAIMS } from 'shared/constants/RouteConstants';

function DeadlineContainer({ value, text, height = '161', width = '170' }) {
    const history = useHistory();
    return (
        <div className="DeadlineContainer" onClick={() => history.push(CLAIMS)}>
            <p
                style={{
                    color: text === 'Complete' ? '#7AB51C' : '#EF8030',
                    fontSize: `${
                        (parseInt(height) + parseInt(width) * 0.2) * 0.35
                    }px`,
                }}
            >
                {value}
            </p>
            <p>
                {text} <br /> Claims
            </p>
        </div>
    );
}

export default DeadlineContainer;
