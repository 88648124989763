import React, { CSSProperties, useEffect, useState } from 'react';
import './index.scss';
import { Space, Typography, Row, Col, Divider, Button, notification, Collapse } from 'antd';
import { useHistory } from 'react-router-dom';
import AdminListLayout from 'shared/components/layout/AdminListLayout/AdminListLayout';
import { ShopOutlined, PlusCircleOutlined } from '@ant-design/icons';
import Search from 'antd/es/input/Search';
import API_SERVICE from '../../services/api-service';
import TextArea from 'antd/es/input/TextArea';

import ClaimStages from "./ClaimsManagement/ClaimStages";
import ClaimTypes from "./ClaimsManagement/ClaimTypes";
import ConsumableTypes from './ConsumablesManagement/ConsumableTypes';
import AccessControl from "../../services/AccessControl";

const { Text, Title, Link } = Typography;
const { Panel } = Collapse;


export default function ClaimsMaster(props: any) {

    return (
        <>
            <AccessControl id = {235}>
            <div className={'role-wrapper page-wrapper'} style={{flex: 1, padding: '30px'}}>
                {/*@ts-ignore*/}
                <div className="profile_section mb-3">
                    <h1>Master Management</h1>
                </div>
                <Collapse accordion>
                    <Panel header="Claim Stages & Types" key="1">
                        <div className="profile_section">
                            <h1>Claim Stages</h1>
                            <h1>Claim Types</h1>
                        </div>
                        <Divider/><br/>
                        <div className="profile_section">
                            <ClaimStages />
                            <ClaimTypes />
                        </div>
                    </Panel>
                    <Panel header="Consumable Types" key="2">
                        <ConsumableTypes />
                    </Panel>
                    <Panel header="Other Master Component" key="3">
                        dummy
                    </Panel>
                </Collapse>
            </div>
            </AccessControl>

        </>
    );
}
