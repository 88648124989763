import React, { CSSProperties, useEffect, useState } from 'react';
import './index.scss';
import {
    Button,
    Menu,
    notification,
    Space,
    Table,
    Tag,
    Typography,
    Pagination,
    Row,
    Col,
    Form,
    Select,
    Modal,
    Tooltip,
} from 'antd';
import { useHistory } from 'react-router-dom';
import AdminListLayout from 'shared/components/layout/AdminListLayout/AdminListLayout';

import Search from 'antd/es/input/Search';
import API_SERVICE from '../../services/api-service';
import ClaimCreateOrEditPage from './ClaimCreateOrEditPage';
//import {ISOtoDateString} from '../../../src/services/util';
import { ConfirmationModal, PrimaryTooltip } from '../../components';
import moment from 'moment';

import { PlusOutlined } from '@ant-design/icons';
import {
    VisibilityOutlined,
    EditOutlined,
    DeleteOutlined,
    CheckOutlined,
    CloseOutlined,
} from '@material-ui/icons';
import { CLAIMS_OVERVIEW } from 'shared/constants/RouteConstants';
import { Link } from 'react-router-dom';
import AccessControl from '../../services/AccessControl';
import { commaAddingInPrice } from 'shared/services/Utility';

const { SubMenu } = Menu;

const { Text, Title } = Typography;

export default function ClaimPage(props: any) {
    const history = useHistory();
    const [claims, setClaims] = useState([] as any[]);
    const [search, setSearch] = useState('' as string);
    const [addNew, setAddNew] = useState(false);
    const [editClaim, setEditClaim] = useState(null as any);
    const [fetching, setFetching] = useState(false);
    const [pagination, setPagination] = useState({
        page: 1,
        perpage: '10',
    } as any);
    const [total, setTotal] = useState(10);
    const [filters, setFilters] = useState({} as any);
    const [confirmationModal, setConfirmationModal] = useState({} as any);
    const [companies, setCompanies] = useState([] as any);
    const [claimStages, setClaimStages] = useState([] as any);
    const [claimTypes, setClaimTypes] = useState([] as any);
    const [taxYears, setTaxYears] = useState([] as any);
    /*const filterStatus: any = {
        status: false
    }*/
    const columns = [
        {
            title: 'S.No',
            key: 'sNO',
            width: 70,
            render: (a: any, v: any, index: number) => (
                <Text>
                    {(pagination.page - 1) * pagination.perpage + index + 1}
                </Text>
            ),
        },
        {
            title: <PrimaryTooltip title="prompt text" text="Company Name" />,
            key: 'companyName',
            width: 200,
            ellipsis: true,
            render: (a: any) => (
                <Text strong>
                    <Link
                        to={`${CLAIMS_OVERVIEW}/${a.id}`}
                        title={a && a.company && a.company.name}
                        className={'theme-link'}
                    >
                        {a && a.company && a.company.name}
                    </Link>
                </Text>
            ),
        },
        {
            title: <PrimaryTooltip title="prompt text" text="Claim Tax Year" />,
            key: 'taxYear',
            dataIndex: 'financialYear',
            width: 150,
            render: (a: any) => (
                <Text>
                    {a} - {a + 1}
                </Text>
            ),
        },
        {
            title: <PrimaryTooltip title="prompt text" text="Claim Period" />,
            key: 'claimPeriod',
            width: 150,
            render: (a: any) =>
                a.claimStartDate &&
                a.claimEndDate && (
                    <Text>
                        {moment(a.claimStartDate).format('DD-MM-YYYY')} <br />{' '}
                        to <br /> {moment(a.claimEndDate).format('DD-MM-YYYY')}
                    </Text>
                ),
        },
        {
            title: (
                <PrimaryTooltip title="prompt text" text="Filing Start Date" />
            ),
            key: 'filingStartDate',
            width: 150,
            render: (a: any) =>
                a.createdAt && (
                    <Text>{moment(a.createdAt).format('DD-MM-YYYY')}</Text>
                ),
        },
        {
            title: (
                <PrimaryTooltip title="prompt text" text="Internal Deadline" />
            ),
            key: 'internalDeadline',
            width: 150,
            render: (a: any) =>
                a.deadlineInternal && (
                    <Text>
                        {moment(a.deadlineInternal).format('DD-MM-YYYY')}
                    </Text>
                ),
        },
        {
            title: (
                <PrimaryTooltip title="prompt text" text="External Deadline" />
            ),
            key: 'externalDeadline',
            width: 150,
            render: (a: any) =>
                a.deadlineExternal && (
                    <Text>
                        {moment(a.deadlineExternal).format('DD-MM-YYYY')}
                    </Text>
                ),
        },
        {
            title: <PrimaryTooltip title="prompt text" text="Claim Date" />,
            key: 'claimDate',
            width: 150,
            render: (a: any) =>
                a.claimDate ? (
                    <Text>{moment(a.claimDate).format('DD-MM-YYYY')}</Text>
                ) : (
                    <Text>Claim Pending</Text>
                ),
        },

        {
            title: <PrimaryTooltip title="prompt text" text="Claim Type" />,
            key: 'claimType',
            dataIndex: 'type',
            width: 150,
            render: (a: any) => <Text>{a && a.displayName}</Text>,
        },
        {
            title: (
                <PrimaryTooltip title="prompt text" text="Claim Value (GBP)" />
            ),
            key: 'claimValue',
            dataIndex: 'value',
            width: 150,
            render: (value: any) => commaAddingInPrice(value),
        },
        {
            title: <PrimaryTooltip title="prompt text" text="Stage" />,
            key: 'stage',
            dataIndex: 'stage',
            width: 150,
            render: (a: any) => <Text>{a && a.displayName}</Text>,
        },
        {
            title: 'Actions',
            key: 'action',
            width: 200,
            render: (value: any) => {
                return (
                    <Space>
                        <AccessControl id={58}>
                            <Space className="px-2">
                                <Button
                                    shape="round"
                                    className="theme-button primary ant-btn-icon-only-img"
                                    icon={<EditOutlined />}
                                    onClick={() => {
                                        setEditClaim(value);
                                    }}
                                />
                            </Space>
                        </AccessControl>
                        <AccessControl id={61}>
                            <Space>
                                <Button
                                    type="default"
                                    shape="round"
                                    className="gray-button ant-btn-icon-only-img"
                                    icon={<DeleteOutlined />}
                                    onClick={() => {
                                        setConfirmationModal({
                                            message: `Delete claim?`,
                                            action: API_SERVICE.claimDelete,
                                            params: [value.id],
                                        });
                                    }}
                                />
                            </Space>
                        </AccessControl>
                    </Space>
                );
            },
        },
    ];

    const refresh = async () => {
        setFetching(true);
        const params = { ...pagination, ...filters };
        if (search) {
            params['q'] = search;
            params['page'] = 1;
        }
        try {
            const {
                data: { data },
            } = await API_SERVICE.getClaims(params);
            data.items.forEach((item: any) => {
                item.latestFilingDate = moment.utc(item.latestFilingDate);
                item.incorporationDate = moment.utc(item.incorporationDate);
                return item;
            });
            setClaims(data.items);
            setTotal(data.total);
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        } finally {
            setFetching(false);
        }
    };

    const fetchCompanies = async () => {
        try {
            const {
                data: { data },
            } = await API_SERVICE.getCompanies({ perpage: 1000 });
            setCompanies(data.items);
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        }
    };

    const getClaimStages = async () => {
        try {
            const {
                data: { data },
            } = await API_SERVICE.getClaimStages();
            setClaimStages(data.items);
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        }
    };

    const getClaimTypes = async () => {
        try {
            const {
                data: { data },
            } = await API_SERVICE.getClaimTypes();
            setClaimTypes(data.items);
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        }
    };

    const setFinancialYears = () => {
        let currentYear = moment().year();
        let lastYear = moment().subtract(1, 'year').year();
        let lastToLastYear = moment().subtract(2, 'year').year();
        let thirdLastYear = moment().subtract(3, 'year').year();
        let taxYears = [
            {
                value: lastYear,
                name: `${lastYear} - ${currentYear}`,
            },
            {
                value: lastToLastYear,
                name: `${lastToLastYear} - ${lastYear}`,
            },
            {
                value: thirdLastYear,
                name: `${thirdLastYear} - ${lastToLastYear}`,
            },
        ];
        setTaxYears(taxYears);
    };

    useEffect(() => {
        fetchCompanies();
        getClaimStages();
        getClaimTypes();
        setFinancialYears();
    }, []);

    useEffect(() => {
        refresh();
    }, [props.location, pagination, search, filters]);
    return (
        <div className={'claim-wrapper page-wrapper'}>
            {/*@ts-ignore*/}
            <AdminListLayout
                title={'All Claims'}
                titleSearch={
                    <div className="search-bar">
                        <Search
                            placeholder="Search"
                            onSearch={(e) => {
                                setSearch(e);
                                setPagination({
                                    page: 1,
                                    perpage: pagination.perpage,
                                });
                            }}
                        />
                    </div>
                }
                titleAction={
                    <AccessControl id={59}>
                        <Button
                            type={'primary'}
                            shape="round"
                            className="theme-button primary"
                            onClick={() => setAddNew(true)}
                            icon={<PlusOutlined />}
                        >
                            File New Claim
                        </Button>
                    </AccessControl>
                }
                filters={
                    <>
                        <Space className="filterBox">
                            Filter by:&nbsp;
                            <Form
                                style={{ width: '130px' }}
                                onValuesChange={(obj) => {
                                    setFilters({ ...filters, ...obj });
                                    setPagination({
                                        page: 1,
                                        perpage: pagination.perpage,
                                    });
                                }}
                            >
                                <Row gutter={20}>
                                    <Col flex={'auto'}>
                                        <Form.Item
                                            name="companyId"
                                            style={{ marginBottom: 0 }}
                                        >
                                            <Select
                                                placeholder="Company"
                                                mode={'multiple'}
                                                showArrow={true}
                                                showSearch={false}
                                                maxTagCount={1}
                                                allowClear
                                            >
                                                {companies.map((s: any) => {
                                                    return (
                                                        <Select.Option
                                                            value={s.id}
                                                        >
                                                            {s.name}
                                                        </Select.Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                            <Form
                                style={{ width: '130px' }}
                                onValuesChange={(obj) => {
                                    setFilters({ ...filters, ...obj });
                                }}
                            >
                                <Row gutter={20}>
                                    <Col flex={'auto'}>
                                        <Form.Item
                                            name="stageId"
                                            style={{ marginBottom: 0 }}
                                        >
                                            <Select
                                                placeholder="Stage"
                                                mode={'multiple'}
                                                showArrow={true}
                                                showSearch={false}
                                                maxTagCount={1}
                                                allowClear
                                            >
                                                {claimStages.map((s: any) => {
                                                    return (
                                                        <Select.Option
                                                            value={s.id}
                                                        >
                                                            {s.displayName}
                                                        </Select.Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                            <Form
                                style={{ width: '130px' }}
                                onValuesChange={(obj) => {
                                    setFilters({ ...filters, ...obj });
                                    setPagination({
                                        page: 1,
                                        perpage: pagination.perpage,
                                    });
                                }}
                            >
                                <Row gutter={20}>
                                    <Col flex={'auto'}>
                                        <Form.Item
                                            name="financialYear"
                                            style={{ marginBottom: 0 }}
                                        >
                                            <Select
                                                placeholder="Tax Year"
                                                mode={'multiple'}
                                                showArrow={true}
                                                showSearch={false}
                                                maxTagCount={1}
                                                allowClear
                                            >
                                                {taxYears.map((s: any) => {
                                                    return (
                                                        <Select.Option
                                                            value={s.value}
                                                        >
                                                            {s.name}
                                                        </Select.Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                            <Form
                                style={{ width: '130px' }}
                                onValuesChange={(obj) => {
                                    setFilters({ ...filters, ...obj });
                                }}
                            >
                                <Row gutter={20}>
                                    <Col flex={'auto'}>
                                        <Form.Item
                                            name="typeId"
                                            style={{ marginBottom: 0 }}
                                        >
                                            <Select
                                                placeholder="Type"
                                                mode={'multiple'}
                                                showArrow={true}
                                                showSearch={false}
                                                maxTagCount={1}
                                                allowClear
                                            >
                                                {claimTypes.map((s: any) => {
                                                    return (
                                                        <Select.Option
                                                            value={s.id}
                                                        >
                                                            {s.displayName}
                                                        </Select.Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Space>
                    </>
                }
                pagination={
                    <Pagination
                        showSizeChanger
                        showQuickJumper
                        current={pagination.page}
                        defaultCurrent={1}
                        total={total}
                        onChange={(page, size) => {
                            setPagination({ page: page, perpage: size });
                        }}
                    />
                }
            >
                {addNew && (
                    <ClaimCreateOrEditPage
                        onClose={(e: boolean) => {
                            if (e) {
                                refresh();
                            }
                            setAddNew(false);
                        }}
                    />
                )}
                {editClaim && (
                    <ClaimCreateOrEditPage
                        details={editClaim}
                        onClose={(e: boolean) => {
                            if (e) {
                                refresh();
                            }
                            setEditClaim(null);
                        }}
                    />
                )}
                <AccessControl id={57}>
                    <Table
                        loading={fetching}
                        scroll={{ x: true, y: 'calc(100vh - 280px)' }}
                        pagination={false}
                        dataSource={claims}
                        columns={columns}
                    />
                </AccessControl>

                <ConfirmationModal
                    {...confirmationModal}
                    callback={(a: boolean) => {
                        setConfirmationModal({});
                        if (a) {
                            refresh();
                        }
                    }}
                />
            </AdminListLayout>
        </div>
    );
}
