import React, { useState, useEffect } from 'react';
import { Table, Typography, notification, Space, Form, Input } from 'antd';
import API_SERVICE from 'services/api-service';
import { PrimaryTooltip } from 'components/index';

const { Text, Title } = Typography;
export default function EmployeesCostTable({ claimId, claimDetails }: any) {
    const [employeeCostTable, setEmployeeCostTable] = useState([] as any[]);
    const [employeeCostTotal, setEmployeeCostTotal] = useState({
        rnd: 0,
        sme: 0,
        rdec: 0,
    } as any);
    const [fetching, setFetching] = useState(false);

    const [columns, setColumns] = useState([
        {
            title: 'S.No',
            dataIndex: 'sNO',
            width: '70px',
            render: (a: any, v: any, index: number) => <Text>{index + 1}</Text>,
        },
        {
            title: 'Employee Name',
            dataIndex: 'name',
            width: '200px',
            render: (a: any) => <Text>{a}</Text>,
        },
        {
            title: 'Employee Role',
            dataIndex: 'role',
            width: '180px',
            render: (a: any) => <Text>{a}</Text>,
        },
        {
            title: 'Gross Annual Pay (GBP)',
            dataIndex: 'grossAnnualPay',
            width: '180px',
            render: (a: any) => <Text>{a}</Text>,
        },
        {
            title: "Employee's NIC (National Insurance) GBP",
            dataIndex: 'nic',
            width: '300px',
            render: (a: any) => <Text>{a}</Text>,
        },
        {
            title: 'Pension (GBP)',
            dataIndex: 'pension',
            width: '150px',
            render: (a: any) => <Text>{a}</Text>,
        },
        {
            title: 'Bonus (GBP)',
            dataIndex: 'bonus',
            width: '150px',
            render: (a: any) => <Text>{a}</Text>,
        },
        {
            title: 'Reimbursed Expenses (GBP)',
            dataIndex: 'reimbursement',
            width: '220px',
            render: (a: any) => <Text>{a}</Text>,
        },
        {
            title: 'Total Payroll (GBP)',
            dataIndex: 'totalPayroll',
            width: '220px',
            render: (a: any) => <Text>{a}</Text>,
        },
        {
            title: 'R&D Total Allocation (%)',
            dataIndex: 'rndAllocationPercentage',
            width: '200px',
            render: (a: any) => <Text>{a}</Text>,
        },
        {
            title: 'R&D Total Allocation (GBP)',
            dataIndex: 'rndTotal',
            width: '200px',
            render: (a: any) => <Text>{a}</Text>,
        },
    ]);

    const RDECColumn = [
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="RDEC Allocation (%)"
                />
            ),
            key: 'rdecAllocation',
            width: '200px',
            render: (a: any) => (
                <Text>{`${
                    100 - (a.smeAllocation ? a.smeAllocation : 0)
                } %`}</Text>
            ),
        },
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="RDEC Allocation (GBP)"
                />
            ),
            dataIndex: 'rdecTotal',
            width: '200px',
            render: (a: any) => (
                <Text>
                    {`£ ${a ? a : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </Text>
            ),
        },
    ];

    const SMEColumn = [
        {
            title: (
                <PrimaryTooltip title="prompt text" text="SME Allocation (%)" />
            ),
            dataIndex: 'smeAllocation',
            width: '200px',
        },
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="SME Allocation (GBP)"
                />
            ),
            dataIndex: 'smeTotal',
            width: '200px',
            render: (a: any) => (
                <Text>
                    {`£ ${a ? a : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </Text>
            ),
        },
    ];

    const MIXEDColumn = [
        {
            title: (
                <PrimaryTooltip title="prompt text" text="SME Allocation (%)" />
            ),
            dataIndex: 'smeAllocation',
            width: '200px',
        },
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="SME Allocation (GBP)"
                />
            ),
            dataIndex: 'smeTotal',
            width: '200px',
            render: (a: any) => (
                <Text>
                    {`£ ${a ? a : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </Text>
            ),
        },
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="RDEC Allocation (%)"
                />
            ),
            key: 'rdecAllocation',
            width: '200px',
            render: (a: any) => (
                <Text>{`${
                    100 - (a.smeAllocation ? a.smeAllocation : 0)
                } %`}</Text>
            ),
        },
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="RDEC Allocation (GBP)"
                />
            ),
            dataIndex: 'rdecTotal',
            width: '200px',
            render: (a: any) => (
                <Text>
                    {`£ ${a ? a : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </Text>
            ),
        },
    ];

    const getFinancialEmployeesList = async () => {
        setFetching(true);
        try {
            const {
                data: { data },
            } = await API_SERVICE.financialEmployeesCostList(claimId);
            setEmployeeCostTable(data.items);
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        } finally {
            setFetching(false);
        }
    };

    const getFinancialEmployeesCostTotal = async () => {
        try {
            await API_SERVICE.financialEmployeesCostTotal(claimId).then(
                (result) => {
                    const {
                        data: { data },
                    } = result;
                    setEmployeeCostTotal(data);
                },
            );
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        }
    };

    const checkClaimType = () => {
        console.log('ahkhdks', claimDetails);

        if (claimDetails?.type?.name === 'SME') {
            let newColumns: any = [...columns, ...SMEColumn];
            setColumns(newColumns);
        } else if (claimDetails?.type?.name === 'RDEC') {
            let newColumns: any = [...columns, ...RDECColumn];
            setColumns(newColumns);
        } else if (claimDetails?.type?.name === 'Mixed') {
            console.log('mixed', MIXEDColumn);

            let newColumns: any = [...columns, ...MIXEDColumn];
            setColumns(newColumns);
        }
    };

    useEffect(() => {
        getFinancialEmployeesList();
        getFinancialEmployeesCostTotal();
        checkClaimType();

        console.log('claimDetails', claimDetails);
    }, []);

    return (
        <>
            <Title level={4}>Employees Cost </Title>
            <Table
                loading={fetching}
                pagination={false}
                scroll={{ x: true }}
                columns={columns}
                dataSource={employeeCostTable}
                bordered
            />
            <div className="d-flex align-items-center justify-content-end mt-3">
                <Space>
                    <Form.Item className="mb-0" style={{ width: '220px' }}>
                        <Input
                            disabled
                            value={`R&D: ${
                                employeeCostTotal.rnd &&
                                employeeCostTotal.rnd.toFixed(2)
                            } GBP`}
                        />
                    </Form.Item>
                    {(claimDetails?.type?.id == 2 ||
                        claimDetails?.type?.id == 3) && (
                        <Form.Item className="mb-0" style={{ width: '220px' }}>
                            <Input
                                disabled
                                value={`SME: ${
                                    employeeCostTotal.sme &&
                                    employeeCostTotal.sme.toFixed(2)
                                } GBP`}
                            />
                        </Form.Item>
                    )}
                    {(claimDetails?.type?.id == 1 ||
                        claimDetails?.type?.id == 3) && (
                        <Form.Item className="mb-0" style={{ width: '220px' }}>
                            <Input
                                disabled
                                value={`RDEC: ${
                                    employeeCostTotal.rdec &&
                                    employeeCostTotal.rdec.toFixed(2)
                                } GBP`}
                            />
                        </Form.Item>
                    )}
                </Space>
            </div>
        </>
    );
}
