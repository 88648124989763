import React, { useState } from 'react';
import {
    Form,
    Input,
    Button,
    Modal,
    Row,
    Col,
    Radio,
    Checkbox,
    DatePicker,
    notification,
} from 'antd';
import { useTranslation } from 'react-i18next';
import 'antd/dist/antd.css';
import moment from 'moment';
import API_SERVICE from 'client/src/services/api-service';
import { Link } from 'react-router-dom';
// import DocViewer, { PDFRenderer, PNGRenderer } from 'react-doc-viewer';
import FileViewer from 'react-file-viewer';
import TermsAndConditions from '../../assets/Inventya_Terms&Condition.pdf';
import useWindowDimensions from '../../hooks/WindowDimensions';

interface Props {
    onRegister: (
        email: string,
        name: string,
        role: string,
        phoneNumber: string,
        cNumber: string,
        cName: string,
        incorporationDate: string,
        employeesNumber: string,
        isSME: boolean,
    ) => void;
    loading: boolean;
}

export default function RegisterForm({ onRegister, loading }: Props) {
    const [form] = Form.useForm();
    const [documentModal, setDocumentModal] = useState(false);
    const [isChecked, setChecked] = useState(false);
    const windowDimension = useWindowDimensions();

    const onCheckboxChange = async (e: any) => {
        await setChecked(e.target.checked);
    };

    function handleSubmit(values: any) {
        if (onRegister) {
            const {
                email,
                name,
                role,
                phoneNumber,
                cNumber,
                cName,
                incorporationDate,
                employeesNumber,
                isSME,
            } = values;

            onRegister(
                email,
                name,
                role,
                phoneNumber,
                Number(cNumber),
                cName,
                incorporationDate.format('YYYY-MM-DD'),
                employeesNumber,
                isSME,
            );
        }
    }

    const fetchCompanyHouseDetails = async (e: any) => {
        let cin = e.target.value;

        if (cin) {
            try {
                const {
                    data: { data },
                } = await API_SERVICE.getCompanyHouseDetails(cin);
                form.setFieldsValue({
                    ['cName']: data.name,
                    ['incorporationDate']: moment(data.incorporationDate),
                });
            } catch (e) {
                notification.error({ message: API_SERVICE.handleErrors(e) });
            }
        }
    };

    /*React.useEffect(()=>{
      console.log(isChecked)
    },[isChecked])*/

    function disabledDate(current: any) {
        return current && current > moment().endOf('day');
    }
    

    return (
        <>
            <Modal
                width={windowDimension.width < 768 ? '80%' : '40%'}
                visible={documentModal}
                title="Terms and Conditions"
                onCancel={() => setDocumentModal(false)}
                footer={false}
                destroyOnClose
            >
                <FileViewer
        fileType={"pdf"}
        filePath={TermsAndConditions}
        />
    
                {/* <DocViewer
                    config={{
                        header: {
                            disableHeader: true,
                        },
                    }}
                    pluginRenderers={[PDFRenderer, PNGRenderer]}
                    documents={[{ uri: TermsAndConditions }]}
                /> */}
            </Modal>
            <Form
                className={'register-form'}
                form={form}
                onFinish={handleSubmit}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                requiredMark={false}
            >
                <Form.Item
                    label={'Name'}
                    name="name"
                    rules={[{ required: true, message: 'Name required' }]}
                >
                    <Input
                        autoComplete={'off'}
                        placeholder="Enter your full name"
                    />
                </Form.Item>
                <Form.Item
                    label={'Email ID'}
                    name="email"
                    rules={[
                        {
                            required: true,
                            type: 'email',
                            message: 'Email ID required',
                        },
                    ]}
                >
                    <Input
                        autoComplete={'off'}
                        placeholder="Enter your official email ID"
                    />
                </Form.Item>
                <Form.Item
                    className={'multiple-line-label'}
                    label={'Role in your company?'}
                    name="role"
                    rules={[{ required: true, message: 'Role required' }]}
                >
                    <Input
                        autoComplete={'off'}
                        placeholder="Enter your role in the company"
                    />
                </Form.Item>

                <Form.Item
                    label={'Mobile Number'}
                    name="phoneNumber"
                    rules={[
                        { required: true, message: 'Mobile number required' },
                    ]}
                >
                    <Input autoComplete={'off'} placeholder="+44XXXXXXXXXX" />
                </Form.Item>

                <Form.Item
                    label={'Company Number'}
                    name="cNumber"
                    rules={[
                        { required: true, message: 'Company Number required' },
                    ]}
                >
                    <Input
                        type="number"
                        autoComplete={'off'}
                        placeholder="XXXXXXXX"
                        onBlur={fetchCompanyHouseDetails}
                        onPressEnter={fetchCompanyHouseDetails}
                    />
                </Form.Item>

                <Form.Item
                    label={'Company Name'}
                    name="cName"
                    rules={[
                        { required: true, message: 'Company Name required' },
                    ]}
                >
                    <Input
                        autoComplete={'off'}
                        placeholder="Enter your company name"
                    />
                </Form.Item>

                <Form.Item
                    label={'Incorporation Date'}
                    name="incorporationDate"
                    rules={[
                        {
                            required: true,
                            message: 'Incorporation Date required',
                        },
                    ]}
                >
                    <DatePicker
                        disabledDate={disabledDate}
                        placeholder="Select date (DD/MM/YYYY)"
                    />
                </Form.Item>

                <Form.Item
                    className={'multiple-line-label'}
                    label={'Number of Employees'}
                    name="employeesNumber"
                    rules={[
                        {
                            required: true,
                            message: 'Number of Employees required',
                        },
                    ]}
                >
                    <Input
                        autoComplete={'off'}
                        placeholder="Enter the total number of employees"
                    />
                </Form.Item>

                <Form.Item
                    label="is SME"
                    name="isSME"
                    style={{ textAlign: 'left' }}
                >
                    <Radio.Group>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    style={{ marginBottom: 0 }}
                    label={'Consent'}
                    name="consent"
                    valuePropName="checked"
                    rules={[
                        {
                            validator: (_, value) =>
                                value
                                    ? Promise.resolve()
                                    : Promise.reject(
                                          new Error(
                                              'Should accept Terms & Conditions',
                                          ),
                                      ),
                        },
                    ]}
                >
                    <Checkbox checked={isChecked} onChange={onCheckboxChange}>
                        I accept the{' '}
                        <span
                            onClick={() => setDocumentModal(true)}
                            className="theme-link"
                            style={{ cursor: 'pointer' }}
                        >
                            Terms & Conditions.
                        </span>
                    </Checkbox>
                </Form.Item>
                <br />
                <Form.Item style={{ justifyContent: 'flex-end' }}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        shape="round"
                        className="theme-button primary full-width "
                    >
                        Register Now
                    </Button>
                </Form.Item>
                <Row justify={'end'}>
                    <Col sm={18} className={'text-center'}>
                        or{' '}
                        <Link to="login" className="theme-link">
                            Login with us
                        </Link>
                    </Col>
                </Row>
            </Form>
        </>
    );
}
