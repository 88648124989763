import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';
import { commaAddingInPrice } from 'shared/services/Utility';

export class LineChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            series: [
                {
                    name: 'Complete Claims',
                    data: props.filteredValueOfClaims.complete.map((x) => x),
                },
                {
                    name: 'Incomplete Claims',
                    data: props.filteredValueOfClaims.incomplete.map((x) => x),
                },
            ],
            options: {
                fill: {
                    colors: ['#000'],
                },

                chart: {
                    id: 'chart3',
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: false,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    width: [5, 7, 5],
                    curve: 'straight',
                    dashArray: [0, 8, 5],
                },
                title: {
                    text: '',
                    align: 'left',
                },
                legend: {
                    tooltipHoverFormatter: function (val, opts) {
                        return (
                            val +
                            ' - ' +
                            opts.w.globals.series[opts.seriesIndex][
                                opts.dataPointIndex
                            ] +
                            ''
                        );
                    },
                },
                markers: {
                    size: 0,
                    hover: {
                        sizeOffset: 6,
                    },
                },
                yaxis: {
                    labels: {
                        formatter: function (value) {
                            return commaAddingInPrice(value);
                        },
                    },
                },
                xaxis: {
                    categories: props.valueOfClaimsYears.map((value) => value),
                },
                tooltip: {
                    y: [
                        {
                            title: {
                                formatter: function (val) {
                                    return val + ' (mins)';
                                },
                            },
                        },
                        {
                            title: {
                                formatter: function (val) {
                                    return val + ' per session';
                                },
                            },
                        },
                        {
                            title: {
                                formatter: function (val) {
                                    return val;
                                },
                            },
                        },
                    ],
                },
                grid: {
                    borderColor: '#f1f1f1',
                },
            },
        };
    }

    render() {
        return (
            <div id="chart">
                <ReactApexChart
                    options={this.state.options}
                    series={this.state.series}
                    type="line"
                    height={350}
                />
            </div>
        );
    }
}

export default LineChart;
