import React, { useState, useEffect } from 'react';
import {
    Typography,
    Modal,
    notification,
    Spin,
    Table,
    Form,
    Space,
    Button,
    Popconfirm,
} from 'antd';
import API_SERVICE from '../services/api-service';
import { PrimaryTooltip } from './index';

const { Text, Title, Link } = Typography;

export default function BulkUploadListModal(props: any) {
    const [loading, setLoading] = useState(false);
    const [withErrorData, setWithErrorData] = useState([] as any);
    const [columns, setColumns] = useState([] as any);
    const [withoutErrorData, setWithoutErrorData] = useState([] as any);

    const employeeCostMixedColumns = [
        {
            title: 'S.No',
            dataIndex: 'sNO',
            width: '70px',
            render: (a: any, v: any, index: number) => <Text>{index + 1}</Text>,
        },
        {
            title: <PrimaryTooltip title="prompt text" text="Employee Name" />,
            dataIndex: 'name',
            width: '200px',
        },
        {
            title: <PrimaryTooltip title="prompt text" text="Employee Role" />,
            dataIndex: 'role',
            width: '180px',
        },
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="Gross Annual Pay (GBP)"
                />
            ),
            dataIndex: 'grossAnnualPay',
            width: '180px',
        },
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="Employee's NIC (National Insurance) GBP"
                />
            ),
            dataIndex: 'nic',
            width: '300px',
        },
        {
            title: <PrimaryTooltip title="prompt text" text="Pension (GBP)" />,
            dataIndex: 'pension',
            width: '150px',
        },
        {
            title: <PrimaryTooltip title="prompt text" text="Bonus (GBP)" />,
            dataIndex: 'bonus',
            width: '150px',
        },
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="Reimbursed Expenses (GBP)"
                />
            ),
            dataIndex: 'reimbursement',
            width: '220px',
        },
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="Total Payroll (GBP)"
                />
            ),
            dataIndex: '',
            width: '220px',
            render: (a: any) => (
                <Text>
                    {Number(a.grossAnnualPay) +
                        Number(a.nic) +
                        Number(a.pension) +
                        Number(a.bonus) +
                        Number(a.reimbursement)}
                </Text>
            ),
        },
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="R&D Total Allocation (%)"
                />
            ),
            dataIndex: 'rndAllocationPercentage',
            width: '200px',
        },
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="R&D Total Allocation (GBP)"
                />
            ),
            dataIndex: '',
            width: '200px',
            render: (a: any) => (
                <Text>
                    {(
                        (a.rndAllocationPercentage / 100) *
                        ((a.grossAnnualPay ? Number(a.grossAnnualPay) : 0) +
                            (a.nic ? Number(a.nic) : 0) +
                            (a.pension ? Number(a.pension) : 0) +
                            (a.bonus ? Number(a.bonus) : 0) +
                            (a.reimbursement ? Number(a.reimbursement) : 0))
                    ).toFixed(2)}
                </Text>
            ),
        },
        {
            title: (
                <PrimaryTooltip title="prompt text" text="SME Allocation (%)" />
            ),
            dataIndex: 'smeAllocation',
            width: '200px',
        },
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="SME Allocation (GBP)"
                />
            ),
            dataIndex: '',
            width: '200px',
            render: (a: any) => (
                <Text>
                    {(
                        (a.rndAllocationPercentage / 100) *
                        ((a.grossAnnualPay ? Number(a.grossAnnualPay) : 0) +
                            (a.nic ? Number(a.nic) : 0) +
                            (a.pension ? Number(a.pension) : 0) +
                            (a.bonus ? Number(a.bonus) : 0) +
                            (a.reimbursement ? Number(a.reimbursement) : 0)) *
                        (a.smeAllocation / 100)
                    ).toFixed(2)}
                </Text>
            ),
        },
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="RDEC Allocation (%)"
                />
            ),
            dataIndex: 'rdecAllocation',
            width: '200px',
        },
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="RDEC Allocation (GBP)"
                />
            ),
            dataIndex: '',
            width: '200px',
            render: (a: any) => (
                <Text>
                    {(
                        (a.rndAllocationPercentage / 100) *
                        ((a.grossAnnualPay ? Number(a.grossAnnualPay) : 0) +
                            (a.nic ? Number(a.nic) : 0) +
                            (a.pension ? Number(a.pension) : 0) +
                            (a.bonus ? Number(a.bonus) : 0) +
                            (a.reimbursement ? Number(a.reimbursement) : 0)) *
                        (a.rdecAllocation / 100)
                    ).toFixed(2)}
                </Text>
            ),
        },
        {
            title: <PrimaryTooltip title="Error" text="Error" />,
            dataIndex: 'error',
            width: '200px',
            render: (a: any) => <Text type="danger">{a}</Text>,
        },
    ];
    const employeeCostRDECColumns = [
        {
            title: 'S.No',
            dataIndex: 'sNO',
            width: '70px',
            render: (a: any, v: any, index: number) => <Text>{index + 1}</Text>,
        },
        {
            title: <PrimaryTooltip title="prompt text" text="Employee Name" />,
            dataIndex: 'name',
            width: '200px',
        },
        {
            title: <PrimaryTooltip title="prompt text" text="Employee Role" />,
            dataIndex: 'role',
            width: '180px',
        },
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="Gross Annual Pay (GBP)"
                />
            ),
            dataIndex: 'grossAnnualPay',
            width: '180px',
        },
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="Employee's NIC (National Insurance) GBP"
                />
            ),
            dataIndex: 'nic',
            width: '300px',
        },
        {
            title: <PrimaryTooltip title="prompt text" text="Pension (GBP)" />,
            dataIndex: 'pension',
            width: '150px',
        },
        {
            title: <PrimaryTooltip title="prompt text" text="Bonus (GBP)" />,
            dataIndex: 'bonus',
            width: '150px',
        },
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="Reimbursed Expenses (GBP)"
                />
            ),
            dataIndex: 'reimbursement',
            width: '220px',
        },
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="Total Payroll (GBP)"
                />
            ),
            dataIndex: '',
            width: '220px',
            render: (a: any) => (
                <Text>
                    {Number(a.grossAnnualPay) +
                        Number(a.nic) +
                        Number(a.pension) +
                        Number(a.bonus) +
                        Number(a.reimbursement)}
                </Text>
            ),
        },
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="R&D Total Allocation (%)"
                />
            ),
            dataIndex: 'rndAllocationPercentage',
            width: '200px',
        },
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="R&D Total Allocation (GBP)"
                />
            ),
            dataIndex: '',
            width: '200px',
            render: (a: any) => (
                <Text>
                    {(
                        (a.rndAllocationPercentage / 100) *
                        ((a.grossAnnualPay ? Number(a.grossAnnualPay) : 0) +
                            (a.nic ? Number(a.nic) : 0) +
                            (a.pension ? Number(a.pension) : 0) +
                            (a.bonus ? Number(a.bonus) : 0) +
                            (a.reimbursement ? Number(a.reimbursement) : 0))
                    ).toFixed(2)}
                </Text>
            ),
        },
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="RDEC Allocation (%)"
                />
            ),
            dataIndex: 'rdecAllocation',
            width: '200px',
        },
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="RDEC Allocation (GBP)"
                />
            ),
            dataIndex: '',
            width: '200px',
            render: (a: any) => (
                <Text>
                    {(
                        (a.rndAllocationPercentage / 100) *
                        ((a.grossAnnualPay ? Number(a.grossAnnualPay) : 0) +
                            (a.nic ? Number(a.nic) : 0) +
                            (a.pension ? Number(a.pension) : 0) +
                            (a.bonus ? Number(a.bonus) : 0) +
                            (a.reimbursement ? Number(a.reimbursement) : 0)) *
                        (a.rdecAllocation / 100)
                    ).toFixed(2)}
                </Text>
            ),
        },
        {
            title: <PrimaryTooltip title="Error" text="Error" />,
            dataIndex: 'error',
            width: '200px',
            render: (a: any) => <Text type="danger">{a}</Text>,
        },
    ];
    const employeeCostSMEColumns = [
        {
            title: 'S.No',
            dataIndex: 'sNO',
            width: '70px',
            render: (a: any, v: any, index: number) => <Text>{index + 1}</Text>,
        },
        {
            title: <PrimaryTooltip title="prompt text" text="Employee Name" />,
            dataIndex: 'name',
            width: '200px',
        },
        {
            title: <PrimaryTooltip title="prompt text" text="Employee Role" />,
            dataIndex: 'role',
            width: '180px',
        },
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="Gross Annual Pay (GBP)"
                />
            ),
            dataIndex: 'grossAnnualPay',
            width: '180px',
        },
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="Employee's NIC (National Insurance) GBP"
                />
            ),
            dataIndex: 'nic',
            width: '300px',
        },
        {
            title: <PrimaryTooltip title="prompt text" text="Pension (GBP)" />,
            dataIndex: 'pension',
            width: '150px',
        },
        {
            title: <PrimaryTooltip title="prompt text" text="Bonus (GBP)" />,
            dataIndex: 'bonus',
            width: '150px',
        },
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="Reimbursed Expenses (GBP)"
                />
            ),
            dataIndex: 'reimbursement',
            width: '220px',
        },
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="Total Payroll (GBP)"
                />
            ),
            dataIndex: '',
            width: '220px',
            render: (a: any) => (
                <Text>
                    {Number(a.grossAnnualPay) +
                        Number(a.nic) +
                        Number(a.pension) +
                        Number(a.bonus) +
                        Number(a.reimbursement)}
                </Text>
            ),
        },
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="R&D Total Allocation (%)"
                />
            ),
            dataIndex: 'rndAllocationPercentage',
            width: '200px',
        },
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="R&D Total Allocation (GBP)"
                />
            ),
            dataIndex: '',
            width: '200px',
            render: (a: any) => (
                <Text>
                    {(
                        (a.rndAllocationPercentage / 100) *
                        ((a.grossAnnualPay ? Number(a.grossAnnualPay) : 0) +
                            (a.nic ? Number(a.nic) : 0) +
                            (a.pension ? Number(a.pension) : 0) +
                            (a.bonus ? Number(a.bonus) : 0) +
                            (a.reimbursement ? Number(a.reimbursement) : 0))
                    ).toFixed(2)}
                </Text>
            ),
        },
        {
            title: (
                <PrimaryTooltip title="prompt text" text="SME Allocation (%)" />
            ),
            dataIndex: 'smeAllocation',
            width: '200px',
        },
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="SME Allocation (GBP)"
                />
            ),
            dataIndex: '',
            width: '200px',
            render: (a: any) => (
                <Text>
                    {(
                        (a.rndAllocationPercentage / 100) *
                        ((a.grossAnnualPay ? Number(a.grossAnnualPay) : 0) +
                            (a.nic ? Number(a.nic) : 0) +
                            (a.pension ? Number(a.pension) : 0) +
                            (a.bonus ? Number(a.bonus) : 0) +
                            (a.reimbursement ? Number(a.reimbursement) : 0)) *
                        (a.smeAllocation / 100)
                    ).toFixed(2)}
                </Text>
            ),
        },
        {
            title: <PrimaryTooltip title="Error" text="Error" />,
            dataIndex: 'error',
            width: '200px',
            render: (a: any) => <Text type="danger">{a}</Text>,
        },
    ];

    const subContractorCostColumns = [
        {
            title: 'S.No',
            dataIndex: 'sNO',
            width: '70px',
            render: (a: any, v: any, index: number) => <Text>{index + 1}</Text>,
        },
        {
            title: (
                <PrimaryTooltip
                    title="Sub-Contactor Name"
                    text="Sub-Contactor Name"
                />
            ),
            dataIndex: 'name',
            width: '200px',
        },
        {
            title: <PrimaryTooltip title="Is Customer?" text="Is Customer?" />,
            dataIndex: 'isCustomer',
            width: '180px',
            render: (a: any) => <Text>{a ? 'Yes' : 'No'}</Text>,
        },
        {
            title: <PrimaryTooltip title="Is Supplier?" text="Is Supplier?" />,
            dataIndex: 'isSupplier',
            width: '180px',
            render: (a: any) => <Text>{a ? 'Yes' : 'No'}</Text>,
        },
        {
            title: (
                <PrimaryTooltip
                    title="Net Amount (GBP)"
                    text="Net Amount (GBP)"
                />
            ),
            dataIndex: 'netAmount',
            width: '300px',
        },
        {
            title: (
                <PrimaryTooltip
                    title="R&D Total Allocation (%)"
                    text="R&D Total Allocation (%)"
                />
            ),
            dataIndex: 'rndAllocationPercentage',
            width: '220px',
        },
        {
            title: (
                <PrimaryTooltip title="Is Connected?" text="Is Connected?" />
            ),
            dataIndex: 'isConnected',
            width: '150px',
            render: (a: any) => <Text>{a ? 'Yes' : 'No'}</Text>,
        },
        {
            title: (
                <PrimaryTooltip
                    title="Sub-Contractor Adjustment (GBP)"
                    text="Sub-Contractor Adjustment (%)"
                />
            ),
            dataIndex: 'adjustment',
            width: '260px',
            render: (a: any) => <Text>{`${a} %`}</Text>,
        },
        {
            title: <PrimaryTooltip title="Total (GBP)" text="Total (GBP)" />,
            dataIndex: 'total',
            width: '180px',
            render: (a: any) => (
                <Text>
                    {`£ ${a ? a : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </Text>
            ),
        },
        {
            title: (
                <PrimaryTooltip title="prompt text" text="SME Allocation (%)" />
            ),
            dataIndex: 'smeAllocation',
            width: '200px',
        },
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="SME Allocation (GBP)"
                />
            ),
            dataIndex: '',
            width: '200px',
            render: (a: any) => (
                <Text>
                    {(
                        (a.rndAllocationPercentage / 100) *
                        ((a.grossAnnualPay ? Number(a.grossAnnualPay) : 0) +
                            (a.nic ? Number(a.nic) : 0) +
                            (a.pension ? Number(a.pension) : 0) +
                            (a.bonus ? Number(a.bonus) : 0) +
                            (a.reimbursement ? Number(a.reimbursement) : 0)) *
                        (a.smeAllocation / 100)
                    ).toFixed(2)}
                </Text>
            ),
        },
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="RDEC Allocation (%)"
                />
            ),
            dataIndex: 'rdecAllocation',
            width: '200px',
        },
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="RDEC Allocation (GBP)"
                />
            ),
            dataIndex: '',
            width: '200px',
            render: (a: any) => (
                <Text>
                    {(
                        (a.rndAllocationPercentage / 100) *
                        ((a.grossAnnualPay ? Number(a.grossAnnualPay) : 0) +
                            (a.nic ? Number(a.nic) : 0) +
                            (a.pension ? Number(a.pension) : 0) +
                            (a.bonus ? Number(a.bonus) : 0) +
                            (a.reimbursement ? Number(a.reimbursement) : 0)) *
                        (a.rdecAllocation / 100)
                    ).toFixed(2)}
                </Text>
            ),
        },

        {
            title: <PrimaryTooltip title="Error" text="Error" />,
            dataIndex: 'error',
            width: '200px',
            render: (a: any) => <Text type="danger">{a}</Text>,
        },
    ];

    const externalWorkerCostColumns = [
        {
            title: 'S.No',
            dataIndex: 'sNO',
            width: '70px',
            render: (a: any, v: any, index: number) => <Text>{index + 1}</Text>,
        },
        {
            title: (
                <PrimaryTooltip
                    title="External Worker Name"
                    text="External Worker Name"
                />
            ),
            dataIndex: 'name',
            width: '200px',
        },
        {
            title: <PrimaryTooltip title="Role" text="Role" />,
            dataIndex: 'role',
            width: '180px',
        },
        {
            title: <PrimaryTooltip title="Agency" text="Agency" />,
            dataIndex: 'agency',
            width: '180px',
        },
        {
            title: (
                <PrimaryTooltip
                    title="Net Amount (GBP)"
                    text="Net Amount (GBP)"
                />
            ),
            dataIndex: 'netAmount',
            width: '300px',
        },
        {
            title: (
                <PrimaryTooltip
                    title="R&D Allocation (%)"
                    text="R&D Allocation (%)"
                />
            ),
            dataIndex: 'rndAllocationPercentage',
            width: '220px',
        },
        {
            title: (
                <PrimaryTooltip title="Is Connected?" text="Is Connected?" />
            ),
            dataIndex: 'isConnected',
            width: '150px',
            render: (a: any) => <Text>{a ? 'Yes' : 'No'}</Text>,
        },
        {
            title: (
                <PrimaryTooltip
                    title="External Worker Adjustment (GBP)"
                    text="Worker Adjustment (%)"
                />
            ),
            dataIndex: 'adjustmentsPercentage',
            width: '260px',
        },
        {
            title: <PrimaryTooltip title="Total (GBP)" text="Total (GBP)" />,
            dataIndex: 'total',
            width: '180px',
        },
        {
            title: (
                <PrimaryTooltip title="prompt text" text="SME Allocation (%)" />
            ),
            dataIndex: 'smeAllocation',
            width: '200px',
        },
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="SME Allocation (GBP)"
                />
            ),
            dataIndex: '',
            width: '200px',
            render: (a: any) => (
                <Text>
                    {(
                        (a.rndAllocationPercentage / 100) *
                        ((a.grossAnnualPay ? Number(a.grossAnnualPay) : 0) +
                            (a.nic ? Number(a.nic) : 0) +
                            (a.pension ? Number(a.pension) : 0) +
                            (a.bonus ? Number(a.bonus) : 0) +
                            (a.reimbursement ? Number(a.reimbursement) : 0)) *
                        (a.smeAllocation / 100)
                    ).toFixed(2)}
                </Text>
            ),
        },
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="RDEC Allocation (%)"
                />
            ),
            dataIndex: 'rdecAllocation',
            width: '200px',
        },
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="RDEC Allocation (GBP)"
                />
            ),
            dataIndex: '',
            width: '200px',
            render: (a: any) => (
                <Text>
                    {(
                        (a.rndAllocationPercentage / 100) *
                        ((a.grossAnnualPay ? Number(a.grossAnnualPay) : 0) +
                            (a.nic ? Number(a.nic) : 0) +
                            (a.pension ? Number(a.pension) : 0) +
                            (a.bonus ? Number(a.bonus) : 0) +
                            (a.reimbursement ? Number(a.reimbursement) : 0)) *
                        (a.rdecAllocation / 100)
                    ).toFixed(2)}
                </Text>
            ),
        },

        {
            title: <PrimaryTooltip title="Error" text="Error" />,
            dataIndex: 'error',
            width: '200px',
            render: (a: any) => <Text type="danger">{a}</Text>,
        },
    ];

    const consumablesCostColumns = [
        {
            title: 'S.No',
            dataIndex: 'sNO',
            width: '70px',
            render: (a: any, v: any, index: number) => <Text>{index + 1}</Text>,
        },
        {
            title: (
                <PrimaryTooltip title="prompt text" text="Consumable Name" />
            ),
            dataIndex: 'name',
            width: '200px',
        },
        {
            title: (
                <PrimaryTooltip title="prompt text" text="Consumable Type" />
            ),
            dataIndex: 'consumableId',
            width: '200px',
        },
        {
            title: <PrimaryTooltip title="prompt text" text="Description" />,
            dataIndex: 'description',
            width: '180px',
        },
        {
            title: (
                <PrimaryTooltip title="prompt text" text="Net Amount (GBP)" />
            ),
            dataIndex: 'netAmount',
            width: '180px',
        },
        {
            title: (
                <PrimaryTooltip title="prompt text" text="R&D Allocation (%)" />
            ),
            dataIndex: 'rndAllocationPercentage',
            width: '150px',
        },
        {
            title: <PrimaryTooltip title="prompt text" text="Total (GBP)" />,
            dataIndex: 'total',
            width: '150px',
            render: (a: any) => (
                <Text>{`£ ${a}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
            ),
        },
        {
            title: (
                <PrimaryTooltip title="prompt text" text="SME Allocation (%)" />
            ),
            dataIndex: 'smeAllocation',
            width: '200px',
        },
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="SME Allocation (GBP)"
                />
            ),
            dataIndex: '',
            width: '200px',
            render: (a: any) => (
                <Text>
                    {(
                        (a.rndAllocationPercentage / 100) *
                        ((a.grossAnnualPay ? Number(a.grossAnnualPay) : 0) +
                            (a.nic ? Number(a.nic) : 0) +
                            (a.pension ? Number(a.pension) : 0) +
                            (a.bonus ? Number(a.bonus) : 0) +
                            (a.reimbursement ? Number(a.reimbursement) : 0)) *
                        (a.smeAllocation / 100)
                    ).toFixed(2)}
                </Text>
            ),
        },
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="RDEC Allocation (%)"
                />
            ),
            dataIndex: 'rdecAllocation',
            width: '200px',
        },
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="RDEC Allocation (GBP)"
                />
            ),
            dataIndex: '',
            width: '200px',
            render: (a: any) => (
                <Text>
                    {(
                        (a.rndAllocationPercentage / 100) *
                        ((a.grossAnnualPay ? Number(a.grossAnnualPay) : 0) +
                            (a.nic ? Number(a.nic) : 0) +
                            (a.pension ? Number(a.pension) : 0) +
                            (a.bonus ? Number(a.bonus) : 0) +
                            (a.reimbursement ? Number(a.reimbursement) : 0)) *
                        (a.rdecAllocation / 100)
                    ).toFixed(2)}
                </Text>
            ),
        },
        {
            title: <PrimaryTooltip title="Error" text="Error" />,
            dataIndex: 'error',
            width: '200px',
            render: (a: any) => <Text type="danger">{a}</Text>,
        },
    ];

    useEffect(() => {
        filterData();
        setTableColumns();
    }, [props]);

    const setTableColumns = () => {
        if (props.tableType === 'esc') {
            if (props.claimDetails?.type?.id === 3) {
                setColumns(employeeCostMixedColumns);
            } else if (props.claimDetails?.type?.id === 2) {
                setColumns(employeeCostSMEColumns);
            } else if (props.claimDetails?.type?.id === 1) {
                setColumns(employeeCostRDECColumns);
            }
        } else if (props.tableType === 'scr') {
            setColumns(subContractorCostColumns);
        } else if (props.tableType === 'ewc') {
            setColumns(externalWorkerCostColumns);
        } else if (props.tableType === 'cms') {
            setColumns(consumablesCostColumns);
        }
    };
    const filterData = () => {
        if (props.data && props.data.length > 0) {
            const withErrorData = props.data.filter((item: any) => {
                return item.error !== '';
            });

            const withoutErrorData = props.data.filter((item: any) => {
                return item.error === '';
            });
            setWithErrorData(withErrorData);
            setWithoutErrorData(withoutErrorData);
        }
    };
    const handleSubmit = async () => {
        // return ;
        let reqObj = {} as any;
        // reqObj['costs'] = props.data;
        reqObj['costs'] = withoutErrorData;
        setLoading(true);
        try {
            if (props.tableType === 'esc') {
                await API_SERVICE.financialEmployeeCostCreate(
                    props.claimDetails?.id,
                    reqObj,
                );
            } else if (props.tableType === 'scr') {
                await API_SERVICE.subContractorCostCreate(
                    props.claimDetails?.id,
                    reqObj,
                );
            } else if (props.tableType === 'ewc') {
                await API_SERVICE.externalWorkerCostCreate(
                    props.claimDetails?.id,
                    reqObj,
                );
            } else if (props.tableType === 'cms') {
                await API_SERVICE.consumablesCostCreate(
                    props.claimDetails?.id,
                    reqObj,
                );
            }

            notification.success({
                message: 'Data updated, please refresh page.',
            });
            if (props.url) {
                window.open(props.url);
            }
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        } finally {
            setLoading(false);
            props.callback(false);
        }
    };
    return (
        <Modal
            width={'90%'}
            title="Bulk Upload"
            visible={props.bulkUploadModal}
            footer={false}
            onCancel={() => {
                props.callback(false);
            }}
        >
            <Table
                // loading={fetching}
                scroll={{ x: true }}
                pagination={false}
                dataSource={props.data}
                columns={columns}
            />

            <Form.Item>
                <div className="text-right">
                    <Space>
                        <Button
                            className="black-button"
                            onClick={() => {
                                props.callback(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            shape="round"
                            htmlType="button"
                            className="theme-button primary"
                            onClick={handleSubmit}
                        >
                            <span className={'btn-spin-wrapper'}>
                                {loading && <Spin />}{' '}
                                {withErrorData.length > 0 &&
                                    `Skip ${withErrorData.length} and`}{' '}
                                Import {withoutErrorData.length} Rows
                            </span>
                        </Button>
                    </Space>
                </div>
            </Form.Item>
        </Modal>
    );
}
