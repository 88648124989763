import * as React from 'react';
import { ReactElement } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { useMedia } from 'react-use';
import DesktopLayout from 'shared/components/layout/DesktopLayout';
import MobileLayout from 'shared/components/layout/MobileLayout';
import { useTranslation } from 'react-i18next';

/*
import Login from './login/Login';
import HomePage from './home/HomePage';
import Dashboard from './Dashboard'
import ProductListPage from './ProductList/ProductListPage';
import ProductDetailsPage from './ProductDetails/ProductDetailsPage';

import ProductListing from './ProductsAdmin/ProductListing';
import Shipping from './Shipping';
import Account from './Account';
import Orders from './OrdersAdmin/Orders';
import CategoriesPage from './Categories/CategoriesPage';
import PortAndSuppliersPage from './PortAndSuppliers/PortAndSuppliers';
import UsersPage from './UsersAndRolesManagement/UsersPage';
import RolesPage from './UsersAndRolesManagement/RolesPage';
import ContainersPage from './Containers/ContainersPage';
*/

import CompaniesPage from './Companies/CompaniesPage';
import RolesPage from './Roles/RolesPage';
//import UsersPage from './Users/UsersPage';
import DesktopAdminLayout from 'shared/components/layout/DesktopAdminLayout';
import UserProfile from './UserProfile';
import UsersPage from './Users/UsersPage';
import ClaimsMaster from './MasterManagement';

import {
    DASHBOARD,
    ROLES_LIST,
    USER_LIST,
    COMPANY_LIST,
    USER_PROFILE,
    MASTER_MANAGEMENT,
    CLAIMS,
    CLAIMS_OVERVIEW,
    FINANCIALS,
    TECHNICALS,
    FINALIZEFINANCIAL,
    ANALYTICS
} from 'shared/constants/RouteConstants';
import ClaimPage from './Claims/ClaimsPage';
import ClaimOverview from './Claims/ClaimOverview/ClaimOverview';
import FinancialDetails from './Claims/Financial/FinancialDetails';
import TechnicalDetails from "./Claims/Technical/TechnicalDetails";
import FinalizeFinancial from './Claims/Financial/FinalizeReport/Index';
import Analytics from './Analytics/Analytics';
import Dashboard from './Dashboard/Dashboard';

interface MenuItem {
    route: string;
    icon: ReactElement;
    label: string;
}


type Props = {}

const ClosedLayout: React.FunctionComponent<Props> = () => {
    const { t } = useTranslation();

    const { pathname } = useLocation();
    const history = useHistory();

    const selectedMenu = pathname;

    function handleMenuClick(route: string) {
        if (route !== pathname) {
            history.push(route);
        }
    }

    const AppLayout = DesktopAdminLayout;

    React.useEffect(()=>{
      document.title = "Inventya | R&D Tax Credit "
    },[])

    return (
        <AppLayout
            selectedMenu={selectedMenu}
            onMenuClick={handleMenuClick}
        >
            <Switch>
                <Route exact path={DASHBOARD} component={Dashboard} />
                <Route exact path={ANALYTICS} component={Analytics} />
                <Route exact path={COMPANY_LIST} component={CompaniesPage} />
                <Route exact path={ROLES_LIST} component={RolesPage} />
                <Route exact path={USER_PROFILE} component={UserProfile} />
                <Route exact path={USER_LIST} component={UsersPage} />
                <Route exact path={MASTER_MANAGEMENT} component={ClaimsMaster} />
                <Route exact path={CLAIMS} component={ClaimPage} />
                <Route exact path={`${CLAIMS_OVERVIEW}/:claimId`} component={ClaimOverview} />
                <Route exact path={`${FINANCIALS}/:claimId`} component={FinancialDetails} />
                <Route exact path={`${FINALIZEFINANCIAL}/:claimId`} component={FinalizeFinancial} />
                <Route exact path={`${TECHNICALS}/:claimId`} component={TechnicalDetails} />
                {/*
                  <Route exact path={USER_LIST} component={UsersPage} />
                  <Route exact path={DASHBOARD} component={Dashboard} />
                  <Route exact path={PRODUCT_LIST} component={ProductListing} />
                  <Route exact path={CATEGORIES_LIST} component={CategoriesPage} />
                  <Route exact path={PORT_AND_SUPPLIERS_LIST} component={PortAndSuppliersPage} />
                  <Route exact path={CONTAINER_TYPES} component={ContainersPage} />
                  <Route exact path={FRANCHISES_LIST} component={FranchisePage} />
                  <Route exact path={ORDER_LIST} component={Orders} />
                  <Route exact path={ORDER_LIST+'/:order_id'} component={Orders} />
                  <Route exact path="/admin/shipping" component={Shipping} />
                  <Route exact path="/admin/account" component={Account} />

                  <Route exact path='/admin/franchises/:id/:mode' component={ProductDetailsPage} />
                */}
            </Switch>
        </AppLayout>
    );
};

export default ClosedLayout;
