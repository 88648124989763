import React, {
    useState,
    useEffect,
} from 'react';
import {
    Table,
    Typography,
    notification, Space, Form, Input,Radio, Divider, Modal, Checkbox, Button,
} from 'antd';
import API_SERVICE from 'services/api-service';
const { Text, Title } = Typography;

export default function XeroFetchModal({ onClose, claimId, companyId, callback}: any) {
    const [xeroData, setXeroData] = useState([] as any[]);
    const [fetching, setFetching] = useState(false);
    const [selectedRowsArray, setSelectedRowsArray] = useState([] as any[]);
    const [xeroImportData, setXeroImportData]=useState([] as any[]);  //callback function parameter

    const columns = [
        {
            title:"Employee Name" ,
            dataIndex: 'name',
            width: '200px',
            render: (a: any) => <Text>{a}</Text>,

        },
        {
            title: "Employee Role",
            key: 'role',
            width: '180px',
            render: (a: any) => <Text>-</Text>,

        },
        {
            title: "Gross Annual Pay (GBP)",
            dataIndex: 'grossAnnualPay',
            width: '180px',
            render: (a: any) => <Text>{a}</Text>,

        },
        {
            title: "Employee's NIC (National Insurance) GBP",
            dataIndex: 'nic',
            width: '300px',
            render: (a: any) => <Text>{a}</Text>,
        },
        {
            title:"Pension (GBP)" ,
            dataIndex: 'pension',
            width: '150px',
            render: (a: any) => <Text>{a}</Text>,

        },
        {
            title:"Bonus (GBP)",
            dataIndex: 'bonus',
            width: '150px',
            render: (a: any) => <Text>{a}</Text>,

        },
        {
            title: "Reimbursed Expenses (GBP)",
            dataIndex: 'reimbursement',
            width: '220px',
            render: (a: any) => <Text>{a}</Text>,

        },
        {
            title: "Total Payroll (GBP)",
            dataIndex: 'totalPayroll',
            width: '220px',
            render: (a: any) => <Text>{a}</Text>,

        },
        {
            title:"R&D Total Allocation (%)",
            dataIndex: 'rndAllocationPercentage',
            width: '200px',
            render: (a: any) => <Text>{a}</Text>,

        },
        {
            title: "SME Allocation (%)",
            dataIndex: 'smeAllocation',
            width: '200px',
            render: (a: any) => <Text>{a}</Text>,

        },
    ];

    const data = [
        {
            key: '1',
            name:'Kevin',
            role: 'Designer',
            grossAnnualPay: '32000',
            nic: '12000',
            pension: '1000',
            bonus: '800',
            reimbursement: '4000',
            totalPayroll: '690000',
            rndAllocationPercentage: '40',
            smeAllocation:'60'
        },
        {
            key: '2',
            name:'Clark',
            role: 'Manager',
            grossAnnualPay: '62000',
            nic: '2000',
            pension: '15000',
            bonus: '1000',
            reimbursement: '6000',
            totalPayroll: '990000',
            rndAllocationPercentage: '30',
            smeAllocation:'50',
        },
        {
            key: '3',
            name:'Felix',
            role: 'Designer',
            grossAnnualPay: '52000',
            nic: '1000',
            pension: '11000',
            bonus: '2800',
            reimbursement: '14000',
            totalPayroll: '610000',
            rndAllocationPercentage: '30',
            smeAllocation:'62',
        },
        {
            key: '4',
            name:'David',
            role: 'Designer',
            grossAnnualPay: '32000',
            nic: '12000',
            pension: '1000',
            bonus: '800',
            reimbursement: '4000',
            totalPayroll: '690000',
            rndAllocationPercentage: '40',
            smeAllocation:'60',
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys: any, selectedRows: any) => {
            setSelectedRowsArray(selectedRows);
        },
    };

    const handleData=():any=>{
        setXeroImportData(selectedRowsArray);
    }

    const getXeroData = async () => {
        setFetching(true);
        try {
            const { data: { data } } = await API_SERVICE.fetchXeroData(claimId, companyId);
            data.forEach((item: any, i: number)=> {
                return item.key = i+1;
            })
            setXeroData(data);
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        } finally {
            setFetching(false);
        }
    };

    useEffect(() => {
        getXeroData();
    }, []);

    useEffect(() => {
        if(xeroImportData && xeroImportData.length > 0){
            callback(xeroImportData);
            onClose();
        }
    }, [xeroImportData]);

    return (
        <>
            <Modal
                title={<Text>Import From Xero</Text>}
                width={'80%'}
                footer={null}
                visible={true}
                maskClosable={false}
                cancelText="Cancel"
                okType="primary"
                cancelButtonProps={{ ghost: true}}
                onCancel={onClose}
            >
                <Table
                    rowSelection={{
                        type: 'checkbox',
                        ...rowSelection,
                    }}
                    loading={fetching}
                    pagination={false}
                    scroll={{ x: true }}
                    columns={columns}
                    // dataSource={data}
                    dataSource={xeroData}
                    bordered
                />
                <Divider />
                <div className='d-flex justify-content-end '>
                    <Space>
                        <Button
                            className="black-button"
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            type='primary'
                            disabled = {selectedRowsArray && selectedRowsArray.length >0? false: true}
                            shape="round"
                            htmlType='submit'
                            className='theme-button disabled'
                            onClick={handleData}
                        >
                            Import
                        </Button>
                    </Space>
                </div>
            </Modal>
        </>
    );
}
