import React, { CSSProperties, useEffect, useState } from 'react';
import './index.scss';
import {
    Button,
    Menu,
    Modal,
    notification,
    Space,
    Form,
    Typography,
    Input,
    Row,
    Col,
    Select,
    DatePicker, Checkbox,
} from 'antd';
import API_SERVICE from '../../../services/api-service';
import moment from 'moment';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const { Text } = Typography;


export default function ProjectQuestionCreateOrEditPage({ onClose, details, claimId, projectId }: any) {
    const [form] = Form.useForm();
    const [saving, setSaving] = useState(false);
    const [fetching, setFetching] = useState(false);


    const [isOngoing, setIsOngoing] = useState(false);

    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');



    useEffect(() => {
        details && details.question && setQuestion(details.question);
        details && details.answer && setAnswer(details.answer);
    }, [details]);



    return (
        <Modal title={
            (<Space>
                <Text>{details ? 'Edit Project Details' : 'Fill in Project Details'}</Text>
            </Space>)
        }
               width={'70%'}
               visible={true}
               maskClosable={false}
               cancelText="Cancel"
               okText={details ? "Update" : "Save"}
               okType="primary"
               okButtonProps={{ shape: 'round' }}
               cancelButtonProps={{ shape: 'round', className: 'btn-dark' }}
               onOk={(value) => {
                   const reqObj = {
                       question,
                       answer
                   };

                   setSaving(true);
                   if (details) {
                       // console.log(reqObj); return;
                       API_SERVICE.updateProjectQuestion(claimId, projectId, details.id, reqObj).then((d) => {
                           notification.success({ message: 'Question updated successfully'});
                           onClose(true);
                       }).catch((e) => {
                           notification.error({ message: API_SERVICE.handleErrors(e) });
                       }).finally(() => {
                           setSaving(false);
                       });
                   } else {
                       // console.log(reqObj); return;
                       API_SERVICE.createProjectQuestion(claimId, projectId, reqObj).then((d) => {
                           notification.success({ message: 'Question created successfully'});
                           onClose(true);
                       }).catch((e) => {
                           notification.error({ message: API_SERVICE.handleErrors(e) });
                       }).finally(() => {
                           setSaving(false);
                       });
                   }
               }}
               onCancel={() => onClose()}>

            <div>
                <div className="question-title">Question 1:</div>
                <ReactQuill theme="snow" value={question} onChange={setQuestion} />
                <div className="question-title">Answer 1:</div>
                <ReactQuill theme="snow" value={answer} onChange={setAnswer} />
            </div>

        </Modal>
    );
}

