import React, { CSSProperties, useEffect, useState } from 'react';
import '../index.scss';
import { Button, Menu, Modal, notification, Space, Form, Typography, Input, Row, Col, Select, Divider, Upload, Descriptions, message } from 'antd';
import { useHistory } from 'react-router-dom';
import AdminListLayout from 'shared/components/layout/AdminListLayout/AdminListLayout';
import { ShopOutlined, PlusCircleOutlined } from '@ant-design/icons';
import Search from 'antd/es/input/Search';
import API_SERVICE from '../../../services/api-service';
import TextArea from 'antd/es/input/TextArea';
import AccessControl from "../../../services/AccessControl";

const { SubMenu } = Menu;

const { Text, Title, Link } = Typography;


export default function ClaimTypes(props: any) {
    const [form] = Form.useForm();
    const [saving, setSaving] = useState(false);

    const [types, setTypes] = useState([] as any);
    const [fetching, setFetching] = useState(false);

    const getClaimTypes = async () => {
        setFetching(true);
        try {
            const { data: {data} } = await API_SERVICE.getClaimTypes();
            setTypes(data.items);
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        } finally {
            setFetching(false);
        }
    };
    useEffect(() => {
        getClaimTypes();
    }, []);

    if (fetching)
        return <div>Getting claim types...</div>
    else if (!types.length)
        return <div>No claim types found.</div>
    return (
        <div>
          <Form
              size='large'
              form={form}
              initialValues={{}}
              fields={[]}
              onFinish={(value) => {
                  const reqObj: any = {
                    claimTypes: Object.values(value)
                  };
                  setSaving(true);
                  API_SERVICE.claimTypesUpdate(reqObj).then((d) => {
                      notification.success({
                          message: 'Success',
                      });
                  }).catch((e) => {
                      notification.error({ message: API_SERVICE.handleErrors(e) });
                  }).finally(() => {
                      setSaving(false);
                  });
              }}
              layout='horizontal'
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 16 }}
              requiredMark={true}
          >       <Row gutter={20}>
                    <Col md={12} sm={24} xs={24}>
                      <Title level={5}>Actual Type</Title>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                        <Title level={5}>Display Type</Title>
                    </Col>
                  </Row>
                  {
                    types.map((item: any, index: number)=>{
                      return (
                        <Form.Item name={''+item.id} style={{flex: 'auto'}}>
                            <DisplayNameInput obj={item} />
                        </Form.Item>
                      )
                    })
                  }
          </Form>
          <br/>
            <AccessControl id = {236}>
              <Button onClick={form.submit}
                      shape='round' className="theme-button primary">
                      Update
              </Button>
            </AccessControl>
        </div>
    );
}

const DisplayNameInput = (props: any) => {
  const obj: any = props.obj || {}
  const [displayName, setDisplayName] = useState(obj.displayName || '')
  useEffect(()=>{
    props.onChange({
      id: obj.id,
      displayName: displayName
    });
  },[displayName])
  return (
    <Row gutter={20}>
      <Col md={12} sm={24} xs={24}>
        <Input value={obj.name} disabled/>
      </Col>
      <Col md={12} sm={24} xs={24}>
          <Input
            style={{width: 200}}
            defaultValue={obj.displayName}
            onChange={({target: {value}})=>setDisplayName(value)}/>
      </Col>
    </Row>
  )
}
