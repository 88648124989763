import React from 'react';
import ReactApexChart from 'react-apexcharts';

class StackedBarChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            series: [
                {
                    name: this.props.claimTypes[0].displayName,
                    data: props.filteredData.newFields.map((x) => x),
                },
                {
                    name: this.props.claimTypes[1].displayName,
                    data: props.filteredData.isProgressField.map((x) => x),
                },
                {
                    name: this.props.claimTypes[2].displayName,
                    data: props.filteredData.completeField.map((x) => x),
                },
            ],
            options: {
                chart: {
                    id: 'chart1',
                    type: 'bar',
                    height: 350,
                    width: '50%',
                    stacked: true,
                    toolbar: {
                        show: true,
                    },
                },

                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            legend: {
                                position: 'bottom',
                                offsetX: -10,
                                offsetY: 0,
                            },
                        },
                    },
                ],
                plotOptions: {
                    bar: {
                        horizontal: false,
                    },
                },

                xaxis: {
                    type: 'text',
                    categories: ['New', 'In-progress', 'Complete'],
                },
                legend: {
                    position: 'right',
                    offsetY: 40,
                },
                fill: {
                    opacity: 1,
                    colors: ['#70B7E1', '#7AB51C', '#EF8030'],
                },
            },
        };
    }

    render() {
        return (
            <div id="chart">
                <ReactApexChart
                    options={this.state.options}
                    series={this.state.series}
                    type="bar"
                    height={350}
                />
            </div>
        );
    }
}

export default StackedBarChart;
