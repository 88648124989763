import AXIOS from 'axios';
import parameters from 'shared/parameters';
import Parameters from 'shared/parameters';

function getPublicInstance() {
    return AXIOS.create({
        // @ts-ignore
        accept: 'application/json',
        baseURL: Parameters.ApiUrl,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    });
}
function getProtectedInstance(noEffect: boolean = false, headers: any = {}) {
    let user = localStorage.getItem('user') as string;
    let token = '';
    let isSuperAdmin = false;
    if (user) {
        token = JSON.parse(user).token;
        isSuperAdmin =
            JSON.parse(user).user &&
            JSON.parse(user).user.roleIds &&
            JSON.parse(user).user.roleIds.includes(1);
    } else {
        window.location.href = '/login';
    }
    return AXIOS.create({
        // @ts-ignore
        accept: 'application/json',
        baseURL: noEffect
            ? Parameters.ApiUrl
            : isSuperAdmin
            ? Parameters.ApiAdminUrl
            : Parameters.ApiUrl,
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            ...headers,
        },
    });
}

function getProtectedFileUpload() {
    let user = localStorage.getItem('user') as string;
    let token = '';
    if (user) {
        token = JSON.parse(user).token;
    } else {
        window.location.href = '/login';
    }
    return AXIOS.create({
        // @ts-ignore
        accept: 'application/json',
        baseURL: Parameters.ApiUrl,
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
        },
    });
}

function handleErrors(error: any) {
    let message = 'Something went wrong!!';
    if (error && error.response && error.response.data) {
        const data = error.response.data;
        if (data.error) {
            message = data.error;
        } else if (data.message) {
            const keys = Object.keys(data.message);
            if (keys.length) {
                message = data.message[keys[0]];
            }
        }
    }
    return message;
}

//auth
async function login(email: string, password: string) {
    const instance = getPublicInstance();
    return await instance.post('/login', { email, password });
}
async function self() {
    const instance = getProtectedInstance(true);
    return await instance.get('/self');
}
async function register(
    email: string,
    name: string,
    role: string,
    phoneNumber: string,
    cNumber: string,
    cName: string,
    incorporationDate: string,
    employeesNumber: string,
    isSME: boolean,
) {
    const instance = getPublicInstance();
    const data: any = {
        name: name,
        email: email,
        phoneNumber: phoneNumber,
        company: {
            name: cName,
            role: role,
            cin: cNumber,
            incorporationDate: incorporationDate,
            noOfEmployees: employeesNumber,
            isSme: isSME,
        },
    };
    return await instance.post('/register', data);
}
async function otpVerifcation(payload: any) {
    const instance = getPublicInstance();
    return await instance.post('/verify-otp', payload);
}

async function forgotPassword(email: string) {
    const instance = getPublicInstance();
    return await instance.post('/forgot-password', { email });
}

async function resetPassword(reqObj: any) {
    const instance = getPublicInstance();
    return await instance.post('/reset-password', reqObj);
}
async function resendOtp(reqObj: any) {
    const instance = getPublicInstance();
    return await instance.post('/resend-otp', reqObj);
}

async function fileUpload(type: any, file: any) {
    const formdata = new FormData();
    formdata.append('file', file);
    formdata.append('type', type);
    const instance = getPublicInstance();
    return await instance.post('/files', formdata);
}

//users
async function users(params: any = { perpage: 1000 }) {
    const instance = getProtectedInstance();
    return await instance.get('/users', { params });
}
async function userCreate(data: any) {
    const instance = getProtectedInstance();
    return await instance.post('/users', data);
}
async function userStatus(id: number, details: any) {
    const instance = getProtectedInstance();
    return await instance.put(`/users/${id}/status`, details);
}
async function userUpdate(id: number, data: any) {
    const instance = getProtectedInstance();
    return await instance.put('/users/' + id, data);
}
async function userDelete(id: number) {
    const instance = getProtectedInstance();
    return await instance.delete('/users/' + id);
}

//Analytics Page
async function claimsTotals(params: any) {
    const instance = getProtectedInstance();
    return await instance.get('/claim-totals', { params });
}

//companies
async function getCompanies(params: any) {
    const instance = getProtectedInstance();
    return await instance.get('/companies', { params });
}
async function companyUpdate(id: number, details: any) {
    const instance = getProtectedInstance();
    return await instance.put(`/companies/${id}`, details);
}
async function companyCreate(details: any) {
    const instance = getProtectedInstance();
    return await instance.post(`/companies`, details);
}
async function companyDelete(id: number) {
    const instance = getProtectedInstance();
    return await instance.delete(`/companies/${id}`);
}

async function companyStatus(id: number, details: any) {
    const instance = getProtectedInstance();
    return await instance.put(`/companies/${id}/status`, details);
}

async function companyApprove(id: number, details: any) {
    const instance = getProtectedInstance();
    return await instance.put(`/companies/${id}/approve`, details);
}

async function companyReject(id: number, details: any) {
    const instance = getProtectedInstance();
    return await instance.put(`/companies/${id}/reject`, details);
}

async function addSICCode(id: number, details: any) {
    const instance = getProtectedInstance();
    return await instance.put(`/companies/${id}/sic-codes`, details);
}

async function getEmployees(id: number) {
    const instance = getProtectedInstance();
    return await instance.get(`/companies/${id}/employees`);
}

//roles
async function getRoleSections() {
    const instance = getProtectedInstance();
    return await instance.get('/roles/sections');
}
async function getRoles(params: any) {
    const instance = getProtectedInstance();
    return await instance.get('/roles', { params });
}
async function roleUpdate(id: number, details: any) {
    const instance = getProtectedInstance();
    return await instance.put(`/roles/${id}/module-permission-groups`, details);
}
async function roleCreate(details: any) {
    const instance = getProtectedInstance();
    return await instance.post(`/roles`, details);
}
async function roleStatus(id: number, details: any) {
    const instance = getProtectedInstance();
    return await instance.put(`/roles/${id}/status`, details);
}
async function roleDelete(id: number) {
    const instance = getProtectedInstance();
    return await instance.delete('/roles/' + id);
}

//profile
async function getProfile() {
    const instance = getProtectedInstance(true);
    return await instance.get('/profile');
}
async function changePassword(req: any) {
    const instance = getProtectedInstance();
    return await instance.post('/change-password', req);
}
async function profileUpdate(req: any) {
    const instance = getProtectedInstance(true);
    return await instance.put('/profile', req);
}

//claims master

async function finalizeFinancials(id: number) {
    const instance = getProtectedInstance();
    return await instance.post(`/financial-stage-update/${id}`);
}

async function finalizeTechnicals(id: number) {
    const instance = getProtectedInstance();
    return await instance.post(`/technical-stage-update/${id}`);
}

async function finalizeFinalStage(id: number) {
    const instance = getProtectedInstance();
    return await instance.post(`/submission-stage-update/${id}`);
}

async function getClaimStages() {
    const instance = getProtectedInstance();
    return await instance.get('/claim-stages');
}
async function getClaimTypes() {
    const instance = getProtectedInstance();
    return await instance.get('/claim-types');
}
async function claimTypesUpdate(req: any) {
    const instance = getProtectedInstance();
    return await instance.put('/claim-types/bulk-update', req);
}
async function claimStagesUpdate(req: any) {
    const instance = getProtectedInstance();
    return await instance.put('/claim-stages/bulk-update', req);
}

//claim
async function getClaims(params: any) {
    const instance = getProtectedInstance();
    return await instance.get('/claims', { params });
}
async function claimUpdate(id: number, details: any) {
    const instance = getProtectedInstance();
    return await instance.put(`/claims/${id}`, details);
}
async function claimCreate(details: any) {
    const instance = getProtectedInstance();
    return await instance.post(`/claims`, details);
}
async function claimDelete(id: number) {
    const instance = getProtectedInstance();
    return await instance.delete(`/claims/${id}`);
}

async function getClaimById(claimId: any) {
    const instance = getProtectedInstance();
    return await instance.get(`/claims/${claimId}`);
}
async function downloadFinancialReport(claimId: any) {
    const instance = getProtectedInstance();
    return await instance.get(
        `${parameters.ApiUrl}/claims/${claimId}/financial/download-report`,
    );
}

async function downloadTechnicialReport(claimId: any) {
    const instance = getProtectedInstance();
    return await instance.get(
        `${parameters.ApiUrl}/claims/${claimId}/technical/download-report`,
    );
}

// financial employee cost
async function financialEmployeesCostList(claimId: any, params: any = {}) {
    const instance = getProtectedInstance();
    return await instance.get(`/claims/${claimId}/financial/employees-cost`, {
        params,
    });
}

async function financialEmployeeCostCreate(claimId: any, details: any) {
    const instance = getProtectedInstance();
    return await instance.post(
        `/claims/${claimId}/financial/employees-cost`,
        details,
    );
}

async function financialEmployeeCostDelete(claimId: any, employeesCostId: any) {
    const instance = getProtectedInstance();
    return await instance.delete(
        `/claims/${claimId}/financial/employees-cost/${employeesCostId}`,
    );
}

async function financialEmployeesCostTotal(claimId: any) {
    const instance = getProtectedInstance();
    return await instance.get(
        `/claims/${claimId}/financial/employees-cost/totals`,
    );
}

async function financialEmployeeCostDescriptionUpdate(
    claimId: any,
    details: any,
) {
    const instance = getProtectedInstance();
    return await instance.put(
        `/claims/${claimId}/financial/employees-cost/description`,
        details,
    );
}

async function uploadFinancialEmployeeCostBulkSheet(
    claimId: any,
    details: any,
) {
    const instance = getProtectedFileUpload();
    return await instance.post(
        `/claims/${claimId}/financial/employees-cost/bulk-upload/report`,
        details,
    );
}

async function uploadSubContractorCostBulkSheet(claimId: any, details: any) {
    const instance = getProtectedFileUpload();
    return await instance.post(
        `/claims/${claimId}/financial/sub-contractors-cost/bulk-upload/report`,
        details,
    );
}

async function uploadExternalWorkerCostBulkSheet(claimId: any, details: any) {
    const instance = getProtectedFileUpload();
    return await instance.post(
        `/claims/${claimId}/financial/external-workers-cost/bulk-upload/report`,
        details,
    );
}

async function uploadConsumablesCostBulkSheet(claimId: any, details: any) {
    const instance = getProtectedFileUpload();
    return await instance.post(
        `/claims/${claimId}/financial/consumables-cost/bulk-upload/report`,
        details,
    );
}

//financial SubContractor cost
async function subContractorCostList(claimId: any, params: any = {}) {
    const instance = getProtectedInstance();
    return await instance.get(
        `/claims/${claimId}/financial/sub-contractors-cost`,
        { params },
    );
}

async function subContractorCostCreate(claimId: any, details: any) {
    const instance = getProtectedInstance();
    return await instance.post(
        `/claims/${claimId}/financial/sub-contractors-cost`,
        details,
    );
}

async function subContractorCostDelete(claimId: any, employeesCostId: any) {
    const instance = getProtectedInstance();
    return await instance.delete(
        `/claims/${claimId}/financial/sub-contractors-cost/${employeesCostId}`,
    );
}

async function subContractorCostTotal(claimId: any) {
    const instance = getProtectedInstance();
    return await instance.get(
        `/claims/${claimId}/financial/sub-contractors-cost/totals`,
    );
}
async function financialSubContractorCostDescriptionUpdate(
    claimId: any,
    details: any,
) {
    const instance = getProtectedInstance();
    return await instance.put(
        `/claims/${claimId}/financial/sub-contractors-cost/description`,
        details,
    );
}

//financial external worker cost
async function externalWorkerCostList(claimId: any, params: any = {}) {
    const instance = getProtectedInstance();
    return await instance.get(
        `/claims/${claimId}/financial/external-workers-cost`,
        { params },
    );
}

async function externalWorkerCostCreate(claimId: any, details: any) {
    const instance = getProtectedInstance();
    return await instance.post(
        `/claims/${claimId}/financial/external-workers-cost`,
        details,
    );
}

async function externalWorkerCostDelete(claimId: any, employeesCostId: any) {
    const instance = getProtectedInstance();
    return await instance.delete(
        `/claims/${claimId}/financial/external-workers-cost/${employeesCostId}`,
    );
}

async function externalWorkerCostTotal(claimId: any) {
    const instance = getProtectedInstance();
    return await instance.get(
        `/claims/${claimId}/financial/external-workers-cost/totals`,
    );
}

async function externalWorkerCostDescriptionUpdate(claimId: any, details: any) {
    const instance = getProtectedInstance();
    return await instance.put(
        `/claims/${claimId}/financial/external-workers-cost/description`,
        details,
    );
}

//financial consumables cost
async function consumablesCostList(claimId: any, params: any = {}) {
    const instance = getProtectedInstance();
    return await instance.get(`/claims/${claimId}/financial/consumables-cost`, {
        params,
    });
}

async function consumablesCostCreate(claimId: any, details: any) {
    const instance = getProtectedInstance();
    return await instance.post(
        `/claims/${claimId}/financial/consumables-cost`,
        details,
    );
}

async function consumablesCostDelete(claimId: any, employeesCostId: any) {
    const instance = getProtectedInstance();
    return await instance.delete(
        `/claims/${claimId}/financial/consumables-cost/${employeesCostId}`,
    );
}

async function consumablesCostTotal(claimId: any) {
    const instance = getProtectedInstance();
    return await instance.get(
        `/claims/${claimId}/financial/consumables-cost/totals`,
    );
}

async function consumablesTypeList() {
    const instance = getProtectedInstance();
    return await instance.get(`/claim-consumables`);
}
async function financialConsumablesCostDescriptionUpdate(
    claimId: any,
    details: any,
) {
    const instance = getProtectedInstance();
    return await instance.put(
        `/claims/${claimId}/financial/consumables-cost/description`,
        details,
    );
}
async function createBulkClaimConsumables(details: any) {
    const instance = getProtectedInstance();
    return await instance.post(`/claim-consumables/bulk-save`, details);
}

async function getCompanyHouseDetails(cin: any) {
    const instance = getPublicInstance();
    return await instance.get(`/company-house/details?cin=${cin}`);
}

async function checkXeroAuthStatus(params: any = {}) {
    const instance = getProtectedInstance(true);
    return await instance.get(`/xero/connection-status`, { params });
}

async function fetchXeroData(claimId: number, companyId: number) {
    const instance = getProtectedInstance(true, { 'X-COMPANY-ID': companyId });
    return await instance.get(
        `/claims/${claimId}/financial/employees-cost/xero`,
    );
}

//technical

async function getProjects(claimId: any) {
    const instance = getProtectedInstance();
    return await instance.get(`/claims/${claimId}/technical/projects`);
}

async function createProject(claimId: any, details: any) {
    const instance = getProtectedInstance();
    return await instance.post(
        `/claims/${claimId}/technical/projects`,
        details,
    );
}

async function updateProject(claimId: any, projectId: number, details: any) {
    const instance = getProtectedInstance();
    return await instance.put(
        `/claims/${claimId}/technical/projects/${projectId}`,
        details,
    );
}

async function deleteProject(claimId: any, projectId: number) {
    const instance = getProtectedInstance();
    return await instance.delete(
        `/claims/${claimId}/technical/projects/${projectId}`,
    );
}

//technical QA

async function getTechnicalQuestions(claimId: any) {
    const instance = getProtectedInstance();
    return await instance.get(`/claims/${claimId}/technical/qa`);
}

async function createTechnicalQuestion(claimId: any, details: any) {
    const instance = getProtectedInstance();
    return await instance.post(`/claims/${claimId}/technical/qa`, details);
}

async function updateTechnicalQuestion(
    claimId: any,
    questionId: number,
    details: any,
) {
    const instance = getProtectedInstance();
    return await instance.put(
        `/claims/${claimId}/technical/qa/${questionId}`,
        details,
    );
}

async function editTechnicalQuestionWithoutChallenge(
    claimId: any,
    projectId: any,
    questionId: number,
    details: any,
) {
    const instance = getProtectedInstance();
    return await instance.put(
        `claims/${claimId}/technical/projects/${projectId}/qa/${questionId}`,
        details,
    );
}

async function editTechnicalQuestion(
    claimId: any,
    questionId: number,
    challengeId: number,
    projectId: any,
    details: any,
) {
    const instance = getProtectedInstance();
    return await instance.put(
        `claims/${claimId}/technical/projects/${projectId}/challenges/${challengeId}/qa/${questionId}`,
        details,
    );
}

async function deleteTechnicalQuestion(claimId: any, questionId: number) {
    const instance = getProtectedInstance();
    return await instance.delete(
        `/claims/${claimId}/technical/qa/${questionId}`,
    );
}

//technical project Challenges
async function getProjectChallenges(claimId: any, projectId: number) {
    const instance = getProtectedInstance();
    return await instance.get(
        `/claims/${claimId}/technical/projects/${projectId}/challenges`,
    );
}

async function createProjectChallenge(
    claimId: any,
    projectId: number,
    details: any,
) {
    const instance = getProtectedInstance();
    return await instance.post(
        `/claims/${claimId}/technical/projects/${projectId}/challenges`,
        details,
    );
}

async function updateProjectChallenge(
    claimId: any,
    projectId: number,
    challengeId: number,
    details: any,
) {
    const instance = getProtectedInstance();
    return await instance.put(
        `/claims/${claimId}/technical/projects/${projectId}/challenges/${challengeId}`,
        details,
    );
}

async function deleteProjectChallenge(
    claimId: any,
    projectId: number,
    challengeId: number,
) {
    const instance = getProtectedInstance();
    return await instance.delete(
        `/claims/${claimId}/technical/projects/${projectId}/challenges/${challengeId}`,
    );
}

//technical project QA
async function getProjectQuestions(claimId: any, projectId: number) {
    const instance = getProtectedInstance();
    return await instance.get(
        `/claims/${claimId}/technical/projects/${projectId}/qa`,
    );
}

async function createProjectQuestion(
    claimId: any,
    projectId: number,
    details: any,
) {
    const instance = getProtectedInstance();
    return await instance.post(
        `/claims/${claimId}/technical/projects/${projectId}/qa`,
        details,
    );
}

async function updateProjectQuestion(
    claimId: any,
    projectId: number,
    questionId: number,
    details: any,
) {
    const instance = getProtectedInstance();
    return await instance.put(
        `/claims/${claimId}/technical/projects/${projectId}/qa/${questionId}`,
        details,
    );
}

async function deleteProjectQuestion(
    claimId: any,
    projectId: number,
    questionId: number,
) {
    const instance = getProtectedInstance();
    return await instance.delete(
        `/claims/${claimId}/technical/projects/${projectId}/qa/${questionId}`,
    );
}

//technical challenges QA

async function getChallengeQuestions(
    claimId: any,
    projectId: number,
    challengeId: number,
) {
    const instance = getProtectedInstance();
    return await instance.get(
        `/claims/${claimId}/technical/projects/${projectId}/challenges/${challengeId}/qa`,
    );
}

async function createChallengeQuestion(
    claimId: any,
    projectId: number,
    challengeId: number,
    details: any,
) {
    const instance = getProtectedInstance();
    return await instance.post(
        `/claims/${claimId}/technical/projects/${projectId}/challenges/${challengeId}/qa`,
        details,
    );
}

async function updateChallengeQuestion(
    claimId: any,
    projectId: number,
    challengeId: number,
    questionId: number,
    details: any,
) {
    const instance = getProtectedInstance();
    return await instance.put(
        `/claims/${claimId}/technical/projects/${projectId}/challenges/${challengeId}/qa/${questionId}`,
        details,
    );
}

async function deleteChallengeQuestion(
    claimId: any,
    projectId: number,
    challengeId: number,
    questionId: number,
) {
    const instance = getProtectedInstance();
    return await instance.delete(
        `/claims/${claimId}/technical/projects/${projectId}/challenges/${challengeId}/qa/${questionId}`,
    );
}

function downloadEmoployeeCostSampleSheet(claimId: any) {
    return `${Parameters.ApiUrl}/claims/${claimId}/financial/employees-cost/bulk-upload/sample-download`;
}
function downloadReport(url: any) {
    return `${url}`;
}

function downloadSubContractorCostSampleSheet(claimId: any) {
    return `${Parameters.ApiUrl}/claims/${claimId}/financial/sub-contractor-cost/bulk-upload/sample-download`;
}
function downloadExternalWorkerCostSampleSheet(claimId: any) {
    return `${Parameters.ApiUrl}/claims/${claimId}/financial/external-workers-cost/bulk-upload/sample-download`;
}
function downloadConsumablesCostSampleSheet(claimId: any) {
    return `${Parameters.ApiUrl}/claims/${claimId}/financial/consumables-cost/bulk-upload/sample-download`;
}

//Xero

function authFormURL() {
    return `${Parameters.XeroUrl}/xero/redirect`;
}

//Dahsboard

async function getActiveUser() {
    const instance = getProtectedInstance();
    return await instance.get(`/reports/users-activity`);
}

async function fetchNoOfCompanies() {
    const instance = getProtectedInstance();
    return await instance.get(`/reports/number-of-companies`);
}

async function externalDeadlineClaims(selectedDate: any) {
    const instance = getProtectedInstance();
    return await instance.get(
        `reports/number-of-claims-deadline?date=${selectedDate}`,
    );
}

async function valueOfClaims() {
    const instance = getProtectedInstance();
    return await instance.get(`reports/value-of-claims`);
}

async function fetchNumberOfClaims() {
    const instance = getProtectedInstance();
    return await instance.get(`reports/number-of-claims`);
}

const API_SERVICE = {
    login,
    self,
    register,
    otpVerifcation,
    forgotPassword,
    resetPassword,
    resendOtp,

    fileUpload,

    getCompanies,
    companyCreate,
    companyUpdate,
    companyDelete,
    companyStatus,
    companyApprove,
    companyReject,
    addSICCode,
    getEmployees,

    users,
    userCreate,
    userUpdate,
    userDelete,
    userStatus,

    claimsTotals,

    getRoleSections,
    getRoles,
    roleCreate,
    roleUpdate,
    roleDelete,
    roleStatus,

    getProfile,
    changePassword,
    profileUpdate,

    getActiveUser,
    fetchNoOfCompanies,
    externalDeadlineClaims,
    valueOfClaims,
    fetchNumberOfClaims,

    getClaimStages,
    getClaimTypes,
    claimTypesUpdate,
    claimStagesUpdate,

    handleErrors,

    getClaims,
    claimUpdate,
    claimCreate,
    claimDelete,
    getClaimById,

    financialEmployeesCostList,
    financialEmployeeCostCreate,
    financialEmployeeCostDelete,
    financialEmployeesCostTotal,
    financialEmployeeCostDescriptionUpdate,
    uploadFinancialEmployeeCostBulkSheet,
    uploadConsumablesCostBulkSheet,
    uploadSubContractorCostBulkSheet,
    uploadExternalWorkerCostBulkSheet,

    subContractorCostList,
    subContractorCostCreate,
    subContractorCostDelete,
    subContractorCostTotal,
    financialSubContractorCostDescriptionUpdate,

    externalWorkerCostList,
    externalWorkerCostCreate,
    externalWorkerCostDelete,
    externalWorkerCostTotal,
    externalWorkerCostDescriptionUpdate,

    consumablesCostList,
    consumablesCostCreate,
    consumablesCostDelete,
    consumablesCostTotal,
    consumablesTypeList,
    financialConsumablesCostDescriptionUpdate,
    createBulkClaimConsumables,
    getCompanyHouseDetails,

    checkXeroAuthStatus,
    fetchXeroData,
    getProjects,
    createProject,
    updateProject,
    deleteProject,

    getTechnicalQuestions,
    createTechnicalQuestion,
    editTechnicalQuestion,
    editTechnicalQuestionWithoutChallenge,
    updateTechnicalQuestion,
    deleteTechnicalQuestion,

    getProjectChallenges,
    createProjectChallenge,
    updateProjectChallenge,
    deleteProjectChallenge,

    getProjectQuestions,
    createProjectQuestion,
    updateProjectQuestion,
    deleteProjectQuestion,

    getChallengeQuestions,
    createChallengeQuestion,
    updateChallengeQuestion,
    deleteChallengeQuestion,

    authFormURL,

    downloadEmoployeeCostSampleSheet,
    downloadSubContractorCostSampleSheet,
    downloadExternalWorkerCostSampleSheet,
    downloadConsumablesCostSampleSheet,
    downloadFinancialReport,
    downloadTechnicialReport,
    downloadReport,

    finalizeFinancials,
    finalizeTechnicals,
    finalizeFinalStage,
};
export default API_SERVICE;
