import React, { CSSProperties, useEffect, useState } from 'react';
import './index.scss';
import {
    Button,
    Menu,
    Modal,
    notification,
    Space,
    Form,
    Typography,
    Input,
    Row,
    Col,
    Select,
    Checkbox,
    Upload,
    message,
    Divider,
    Tree,
    List,
    Switch,
    Collapse,
    Table,
} from 'antd';
import { useHistory } from 'react-router-dom';
import AdminListLayout from 'shared/components/layout/AdminListLayout/AdminListLayout';
import { ShopOutlined, PlusCircleOutlined } from '@ant-design/icons';
import Search from 'antd/es/input/Search';
import API_SERVICE from '../../services/api-service';
import TextArea from 'antd/es/input/TextArea';
import { accessIncludes } from 'services/AccessControl';

const { SubMenu } = Menu;
const { Panel } = Collapse;
const { Text, Title, Link } = Typography;

export default function RoleCreateOrEditPage({
    onClose,
    details,
    preview,
    allPermissions,
}: any) {
    const [form] = Form.useForm();
    const permissionGroups = details ? details.modules : allPermissions.modules;
    const [saving, setSaving] = useState(false);
    const history = useHistory();

    return (
        <Modal
            title={
                <Space>
                    <ShopOutlined />
                    <Text>
                        {preview
                            ? 'View Role'
                            : details
                            ? 'Edit Role'
                            : 'Add Role'}
                    </Text>
                </Space>
            }
            width={800}
            footer={!accessIncludes([54]) && false}
            visible={true}
            maskClosable={false}
            okText={details ? 'Update' : '+ Add'}
            okType="primary"
            okButtonProps={{ shape: 'round' }}
            cancelButtonProps={{ shape: 'round', className: 'btn-dark' }}
            onOk={() => {
                preview ? onClose() : form.submit();
            }}
            onCancel={() => onClose()}
        >
            <Form
                size="large"
                form={form}
                initialValues={details || {}}
                fields={[]}
                onFinish={(value) => {
                    const reqObj: any = value;
                    setSaving(true);

                    if (details) {
                        if (reqObj.modulePermissionGroups.length === 0) {
                            delete reqObj['modulePermissionGroups'];
                        }
                        API_SERVICE.roleUpdate(details.id, reqObj)
                            .then((d) => {
                                onClose(true);
                            })
                            .catch((e) => {
                                notification.error({
                                    message: API_SERVICE.handleErrors(e),
                                });
                            })
                            .finally(() => {
                                setSaving(false);
                            });
                    } else {
                        API_SERVICE.roleCreate(reqObj)
                            .then((d) => {
                                onClose(true);
                            })
                            .catch((e) => {
                                notification.error({
                                    message: API_SERVICE.handleErrors(e),
                                });
                            })
                            .finally(() => {
                                setSaving(false);
                            });
                    }
                }}
                layout="vertical"
                requiredMark={true}
            >
                <Row gutter={20}>
                    <Col md={12} sm={24} xs={24}>
                        <Form.Item
                            label={'Role Name:'}
                            name={'name'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Role Name required',
                                },
                            ]}
                        >
                            {preview ? (
                                <Input disabled style={styles.disabledInput} />
                            ) : (
                                <Input />
                            )}
                        </Form.Item>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                        <Form.Item
                            label={'Role Description:'}
                            name={'description'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Role Description required',
                                },
                            ]}
                        >
                            {preview ? (
                                <Input disabled style={styles.disabledInput} />
                            ) : (
                                <Input />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item name={'modulePermissionGroups'}>
                    {permissionGroups ? (
                        <PermissionForm
                            modules={permissionGroups}
                            preview={preview}
                        />
                    ) : (
                        <div>No permissions available.</div>
                    )}
                </Form.Item>
                <Col
                    style={{
                        marginTop: '20px',
                        marginLeft: '20px',
                    }}
                >
                    <Button
                        htmlType="submit"
                        className="ant-btn ant-btn-round ant-btn-icon-only theme-button primary ant-btn-icon-only-img"
                    >
                        Update
                    </Button>
                </Col>
            </Form>
        </Modal>
    );
}

function PermissionForm(props: any) {
    const { modules, preview } = props;
    const [permissions, setPermissions] = useState({} as any);
    useEffect(() => {
        props.onChange(Object.values(permissions));
    }, [permissions]);

    function Toggle(props: any) {
        const { group, target, disabled } = props;
        //selects updated_group or existing_group
        const group_obj: any = { ...(permissions[group.id] || group) };
        return (
            <Checkbox
                disabled={props.disabled}
                checked={group_obj[target]}
                onChange={() => {
                    if (!preview) {
                        group_obj[target] = !group_obj[target];
                        const permissions_obj: any = { ...permissions };
                        permissions_obj[group_obj.id] = group_obj;
                        console.log(permissions_obj);
                        setPermissions(permissions_obj);
                    }
                }}
            />
        );
    }

    return (
        <Collapse accordion ghost>
            {modules.map((moduleObj: any, index: number) => {
                return (
                    <Panel header={moduleObj.name} key={index}>
                        <Table
                            pagination={false}
                            dataSource={
                                moduleObj.permissionGroups.filter(
                                    (item: any) => {
                                        return !(
                                            item.permissionReadId === null &&
                                            item.permissionShowId === null &&
                                            item.permissionCreateId === null &&
                                            item.permissionUpdateId === null &&
                                            item.permissionDeleteId === null
                                        );
                                    },
                                ) || []
                            }
                            columns={[
                                {
                                    title: 'Permission Right',
                                    key: 'permission',
                                    render: (a: any) => (
                                        <Text>
                                            {(a.permissionGroup || {}).name}
                                        </Text>
                                    ),
                                },
                                {
                                    title: 'Read Listing',
                                    key: 'read',
                                    render: (a: any) => {
                                        const group =
                                            (a.permissionGroup || {})
                                                .permissions || {};
                                        return (
                                            <Toggle
                                                disabled={
                                                    !group['permissionReadId']
                                                }
                                                target="permissionReadEnabled"
                                                group={group}
                                            />
                                        );
                                    },
                                },
                                {
                                    title: 'Read View',
                                    key: 'show',
                                    render: (a: any) => {
                                        const group =
                                            (a.permissionGroup || {})
                                                .permissions || {};
                                        return (
                                            <Toggle
                                                disabled={
                                                    !group['permissionShowId']
                                                }
                                                target="permissionShowEnabled"
                                                group={group}
                                            />
                                        );
                                    },
                                },
                                {
                                    title: 'Create',
                                    key: 'create',
                                    render: (a: any) => {
                                        const group =
                                            (a.permissionGroup || {})
                                                .permissions || {};
                                        return (
                                            <Toggle
                                                disabled={
                                                    !group['permissionCreateId']
                                                }
                                                target="permissionCreateEnabled"
                                                group={group}
                                            />
                                        );
                                    },
                                },
                                {
                                    title: 'Modify',
                                    key: 'modify',
                                    render: (a: any) => {
                                        const group =
                                            (a.permissionGroup || {})
                                                .permissions || {};
                                        return (
                                            <Toggle
                                                disabled={
                                                    !group['permissionUpdateId']
                                                }
                                                target="permissionUpdateEnabled"
                                                group={group}
                                            />
                                        );
                                    },
                                },
                                {
                                    title: 'Delete',
                                    key: 'delete',
                                    render: (a: any) => {
                                        const group =
                                            (a.permissionGroup || {})
                                                .permissions || {};
                                        return (
                                            <Toggle
                                                disabled={
                                                    !group['permissionDeleteId']
                                                }
                                                target="permissionDeleteEnabled"
                                                group={group}
                                            />
                                        );
                                    },
                                },
                            ]}
                        />
                    </Panel>
                );
            })}
        </Collapse>
    );
}

const styles = {
    disabledInput: {
        backgroundColor: '#fff',
        color: '#6E6E6E',
    },
};
