import { Col, Row, Typography, notification } from 'antd';
import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import OTPForm from './OTPForm';

import { useTranslation } from 'react-i18next';
import './OTP.scss';

import logo from 'shared/assets/logo.png';

//STATIC

import API_SERVICE from 'client/src/services/api-service';
import { AuthConsumer } from 'shared/contexts/AuthContext';

interface Props {
    location: any;
}

export default function OTP({ location }: Props) {
    const { t } = useTranslation();
    const history = useHistory();
    const state: any = history.location.state;
    const [loading, setLoading] = useState(false);
    const { from } = location.state || { from: { pathname: '/' } };

    async function resendOtp() {
        try {
            const {
                data: { data },
            } = await API_SERVICE.resendOtp({ email: state?.email });
            history.push('/OTP', {
                email: data?.user?.email,
                uuid: data?.uuid,
            });
            notification.success({
                message: 'Otp Resent successfully',
            });
        } catch (error) {
            notification.error({
                message: API_SERVICE.handleErrors(error),
            });
        }
    }

    return (
        <AuthConsumer>
            {({ isAuth, updateAuthToken }) => {
                return isAuth ? (
                    <Redirect to={from} />
                ) : (
                    <div className="otp">
                        <Row className="otp-form-row">
                            <Col sm={14} className="otp-text-column">
                                <div className="otp-text-wrapper">
                                    <div className={'title'}>
                                        Simply Claim your
                                    </div>
                                    <div className={'heading'}>
                                        R&D Tax Credit
                                    </div>
                                    <div className={'bar-wrapper'}>
                                        <span className="bar"></span>
                                        <span className="bar-text">
                                            EASY | FAST | SAFE
                                        </span>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={10} className={'otp-form-wrapper-column'}>
                                <div className="otp-form-wrapper">
                                    <div className={'text-center'}>
                                        <img
                                            src={logo}
                                            className={'form-logo'}
                                        />
                                    </div>
                                    <div className="logo-subtitle">
                                        Enter Login Security Code Received{' '}
                                        <br /> on your Email Id - {state?.email}
                                    </div>
                                    <OTPForm
                                        resendOtp={resendOtp}
                                        email={state?.email}
                                        onOTP={async (otp: string) => {
                                            setLoading(true);
                                            try {
                                                const payload = {
                                                    uuid: state?.uuid,
                                                    otp: otp,
                                                };
                                                await API_SERVICE.otpVerifcation(
                                                    payload,
                                                );
                                                notification.success({
                                                    message: 'OTP verified',
                                                });
                                                history.replace('login', {
                                                    email: '',
                                                    otp: '',
                                                });
                                            } catch (e) {
                                                notification.error({
                                                    message: t(
                                                        API_SERVICE.handleErrors(
                                                            e,
                                                        ),
                                                    ),
                                                });
                                            } finally {
                                                setLoading(false);
                                            }
                                        }}
                                        loading={loading}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                );
            }}
        </AuthConsumer>
    );
}
