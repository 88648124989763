import { Form, Modal, Space, Typography, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import API_SERVICE from '../../../services/api-service';
import './index.scss';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const { Text } = Typography;

export default function TechnicalQuestionCreateOrEditPage({
    onClose,
    details,
    claimId,
}: any) {
    const [form] = Form.useForm();
    const [saving, setSaving] = useState(false);
    const [fetching, setFetching] = useState(false);

    const [isOngoing, setIsOngoing] = useState(false);

    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');

    console.log(details);
    useEffect(() => {
        details && details.question && setQuestion(details.question);
        details && details.answer && setAnswer(details.answer);
    }, [details]);

    return (
        <Modal
            title={
                <Space>
                    <Text>
                        {details
                            ? 'Edit Basic Claim Details'
                            : 'Fill in Basic Claim Details'}
                    </Text>
                </Space>
            }
            width={'70%'}
            visible={true}
            maskClosable={false}
            cancelText="Cancel"
            okText={details ? 'Update' : 'Save'}
            okType="primary"
            okButtonProps={{ shape: 'round' }}
            cancelButtonProps={{ shape: 'round', className: 'btn-dark' }}
            onOk={(value) => {
                const reqObj = {
                    question,
                    answer,
                };

                setSaving(true);
                if (details) {
                    if (details.hasOwnProperty('projectId')) {
                        API_SERVICE.editTechnicalQuestionWithoutChallenge(
                            claimId,
                            details.projectId,
                            details.id,
                            reqObj,
                        )
                            .then((d) => {
                                notification.success({
                                    message: 'Question updated successfully',
                                });
                                onClose(true);
                            })
                            .catch((e) => {
                                notification.error({
                                    message: API_SERVICE.handleErrors(e),
                                });
                            })
                            .finally(() => {
                                setSaving(false);
                            });
                    } else if (details.hasOwnProperty('challengeId')) {
                        API_SERVICE.editTechnicalQuestion(
                            claimId,
                            details.id,
                            details.challengeId,
                            details.projectId,
                            reqObj,
                        )
                            .then((d) => {
                                notification.success({
                                    message: 'Question updated successfully',
                                });
                                onClose(true);
                            })
                            .catch((e) => {
                                notification.error({
                                    message: API_SERVICE.handleErrors(e),
                                });
                            })
                            .finally(() => {
                                setSaving(false);
                            });
                    } else {
                        API_SERVICE.updateTechnicalQuestion(
                            claimId,
                            details.id,
                            reqObj,
                        )
                            .then((d) => {
                                notification.success({
                                    message: 'Question updated successfully',
                                });
                                onClose(true);
                            })
                            .catch((e) => {
                                notification.error({
                                    message: API_SERVICE.handleErrors(e),
                                });
                            })
                            .finally(() => {
                                setSaving(false);
                            });
                    }
                } else {
                    // console.log(reqObj); return;
                    API_SERVICE.createTechnicalQuestion(claimId, reqObj)
                        .then((d) => {
                            notification.success({
                                message: 'Question created successfully',
                            });
                            onClose(true);
                        })
                        .catch((e) => {
                            notification.error({
                                message: API_SERVICE.handleErrors(e),
                            });
                        })
                        .finally(() => {
                            setSaving(false);
                        });
                }
            }}
            onCancel={() => onClose()}
        >
            <div>
                <div className="question-title">Question 1:</div>
                <ReactQuill
                    theme="snow"
                    value={question}
                    onChange={setQuestion}
                />
                <div className="question-title">Answer 1:</div>
                <ReactQuill theme="snow" value={answer} onChange={setAnswer} />
            </div>
        </Modal>
    );
}
