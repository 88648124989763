import React, { CSSProperties, useEffect, useState } from 'react';
import './index.scss';
import { Button, Menu, Modal, notification, Space, Form, Typography, Input, Row, Col, Select, Divider, Upload, message } from 'antd';
import { useHistory } from 'react-router-dom';
import AdminListLayout from 'shared/components/layout/AdminListLayout/AdminListLayout';
import { ShopOutlined, PlusCircleOutlined } from '@ant-design/icons';
import Search from 'antd/es/input/Search';
import API_SERVICE from '../../services/api-service';
import TextArea from 'antd/es/input/TextArea';

const { SubMenu } = Menu;

const { Text, Title, Link } = Typography;


export default function ChangePassword(props: any) {
    const [form] = Form.useForm();
    const [saving, setSaving] = useState(false);

    return (
        <div>
          <Form
              form={form}
              initialValues={{}}
              fields={[]}
              onFinish={(value) => {

                  if (value.newPassword === value.confirmPassword) {
                    const reqObj: any = value;
                    setSaving(true);
                    API_SERVICE.changePassword(reqObj).then((d) => {
                      notification.success({message: 'Password updated successfully'});
                    }).catch((e) => {
                        notification.error({ message: API_SERVICE.handleErrors(e) });
                    }).finally(() => {
                        setSaving(false);
                    });
                  }
                  else notification.error({message: 'New Password and Confirm Password do not match'})
              }}
              layout='horizontal'
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 16 }}
              requiredMark={true}
          >

                        <Form.Item
                            label={'Existing Password:'}
                            name={'oldPassword'}
                            rules={[
                                { required: true, message: 'This field is required' },
                            ]}
                        >
                            <Input type="password" />
                        </Form.Item>
                        <Form.Item
                            label={'New Password:'}
                            name={'newPassword'}
                            rules={[
                                { required: true, message: 'This field is required' },
                            ]}
                        >
                            <Input type="password" />
                        </Form.Item>
                        <Form.Item
                            label={'Confirm New Password:'}
                            name={'confirmPassword'}
                            rules={[
                                { required: true, message: 'This field is required' },
                            ]}
                        >
                            <Input type="password" />
                        </Form.Item>
          </Form>
          <div className="profile-submit">
            <Button onClick={form.submit} shape='round' className="theme-button primary">Update</Button>
          </div>

        </div>
    );
}
