import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';
import { USER_LIST } from 'shared/constants/RouteConstants';
import { withRouter } from 'react-router-dom';

export class SingleDonutChart extends Component {
    constructor(props) {
        super(props);

        let labels =
            this.props.text === 'Active'
                ? ['Active', 'InActive']
                : ['InActive', 'Active'];

        this.state = {
            series: [props.value, 100 - props.value],
            options: {
                chart: {
                    id: 'chart2',
                    type: 'donut',
                    width: '50',
                    events: {
                        dataPointSelection: (event, chartContext, config) => {
                            this.props.history.push(USER_LIST);
                        },
                    },
                },
                tooltip: {
                    enabled: false,
                },
                legend: {
                    show: false,
                },
                labels: labels,

                fill: {
                    colors: [
                        this.props.text === 'Active' ? '#7AB51C' : '#EF8030',
                        '#F4F4F4',
                    ],
                },
                dataLabels: {
                    enabled: false,
                },

                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: true,
                                name: {
                                    fontSize: '20px',
                                },
                                value: {
                                    fontSize: '27px',
                                    offsetY: -5,
                                    color:
                                        this.props.text === 'Active'
                                            ? '#7AB51C'
                                            : '#EF8030',
                                },
                                total: {
                                    show: true,
                                    showAlways: true,
                                    label: '',
                                    formatter: () => {
                                        return props.value;
                                    },
                                },
                            },
                        },
                    },
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: '100%',
                            },
                            legend: {
                                position: 'bottom',
                            },
                        },
                    },
                ],
            },
        };
    }
    render() {
        return (
            <div id="chart">
                <ReactApexChart
                    options={this.state.options}
                    series={this.state.series}
                    type="donut"
                />
                <p
                    style={{
                        textAlign: 'center',
                        fontSize: '18px',
                    }}
                >
                    {this.props.text}
                </p>
            </div>
        );
    }
}

export default withRouter(SingleDonutChart);
