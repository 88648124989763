import React, { CSSProperties, useEffect, useState } from 'react';
import {
    Button,
    notification,
    Space,
    Typography,
    Form,
    Select,
    Row,
    Col,
    Card,
    Popconfirm,
} from 'antd';
import API_SERVICE from '../../../services/api-service';
import './index.scss';
import {
    DownloadOutlined,
    FileExcelOutlined,
    ImportOutlined,
    PlusOutlined,
    CheckOutlined,
    DeleteOutlined,
    EditOutlined,
} from '@ant-design/icons';
import pound from 'shared/assets/icons/active_pound.png';
import { useTranslation } from 'react-i18next';
import { ConfirmationModal } from '../../../components';
import { PieChart } from 'react-minimal-pie-chart';
import ProjectCreateOrEditPage from './ProjectCreateOrEditPage';
import TechnicalQuestionCreateOrEditPage from './TechnicalQuestionCreateOrEditPage';
import ProjectQuestionCreateOrEditPage from './ProjectQuestionCreateOrEditPage';
import ChallengeCreateOrEditPage from './ChallengeCreateOrEditPage';
import ChallengeQuestionCreateOrEditPage from './ChallengeQuestionCreateOrEditPage';

const { Text, Title, Link } = Typography;
export default function TechnicalDetailsPage(props: any) {
    const { t } = useTranslation();
    const [claimId, setClaimId] = useState(null);
    const [selectedProject, setSelectedProject] = useState(0);
    const [claimDetails, setClaimDetails] = useState({} as any);
    const [projects, setProjects] = useState([] as any);
    const [addNew, setAddNew] = useState(false);
    const [editProject, setEditProject] = useState(null as any);
    const [technicalQuestions, setTechnicalQuestions] = useState([] as any);
    const [addNewTechnicalQuestion, setAddNewTechnicalQuestion] =
        useState(false);
    const [editTechnicalQuestion, setEditTechnicalQuestion] = useState(
        null as any,
    );

    const [addNewProjectQuestion, setAddNewProjectQuestion] = useState(false);
    const [editProjectQuestion, setEditProjectQuestion] = useState(null as any);

    const [addNewChallengeQuestion, setAddNewChallengeQuestion] =
        useState(false);
    const [editChallengeQuestion, setEditChallengeQuestion] = useState(
        null as any,
    );
    const [selectedChallenge, setSelectedChallenge] = useState(0);
    const [editChallenge, setEditChallenge] = useState(false);

    const [confirmationModal, setConfirmationModal] = useState({} as any);

    useEffect(() => {
        const {
            match: { params },
        } = props;
        setClaimId(params.claimId);
    }, []);

    useEffect(() => {
        if (claimId) {
            getProjects();
            getClaimsDetails();
            // getTechnicalQuestions();
        }
    }, [claimId]);

    useEffect(() => {
        if (selectedProject === 0) {
            claimId && getTechnicalQuestions();
        } else {
            claimId && getProjectQuestions();
        }
    }, [selectedProject, claimId]);

    useEffect(() => {
        if (selectedChallenge === 0) {
            if (selectedProject !== 0) {
                getProjectQuestions();
            }
        } else {
            getChallengeQuestions();
        }
    }, [selectedChallenge]);

    const handleProjectChange = (value: any) => {
        setSelectedProject(value);
    };

    const handleDeleteProject = async (value: number) => {
        setConfirmationModal({
            message: `Delete Project?`,
            action: API_SERVICE.deleteProject,
            params: [claimId, value],
            type: 'deleteProject',
        });
    };

    const handleDeleteChallenge = async (value: number) => {
        // console.log(value); return;
        setConfirmationModal({
            message: `Delete Challenge?`,
            action: API_SERVICE.deleteProjectChallenge,
            params: [claimId, selectedProject, value],
            type: 'deleteChallenge',
        });
    };

    const handleEditProject = (value: number) => {
        if (value) {
            const currentProject = projects.filter((item: any) => {
                return item.id === value;
            });

            setEditProject(currentProject[0]);
        }
    };

    const handleEditChallenge = (value: number) => {
        /*if(value){
            const currentProject = projects.filter((item: any) => {
                return item.id === value;
            });

            setEditProject(currentProject[0]);
        }*/
        setEditChallenge(true);
    };

    const handleEditQuestion = (value: any) => {
        setEditTechnicalQuestion(value);
    };

    const handleEditChallengeQuestion = (value: any) => {
        setEditChallengeQuestion(value);
    };

    const getProjects = async () => {
        try {
            const {
                data: { data },
            } = await API_SERVICE.getProjects(claimId);
            setProjects(data.items);
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        }
    };

    const getTechnicalQuestions = async () => {
        try {
            const {
                data: { data },
            } = await API_SERVICE.getTechnicalQuestions(claimId);
            setTechnicalQuestions(data.items);
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        }
    };
    const handleDeleteTechnicalQuestion = async (questionId: number) => {
        setConfirmationModal({
            message: `Delete Question?`,
            action: API_SERVICE.deleteTechnicalQuestion,
            params: [claimId, questionId],
            type: 'technical',
        });
    };

    const handleDeleteProjectQuestion = async (questionId: number) => {
        setConfirmationModal({
            message: `Delete Question?`,
            action: API_SERVICE.deleteProjectQuestion,
            params: [claimId, selectedProject, questionId],
            type: 'project',
        });
    };

    const handleDeleteChallengeQuestion = async (questionId: number) => {
        setConfirmationModal({
            message: `Delete Question?`,
            action: API_SERVICE.deleteChallengeQuestion,
            params: [claimId, selectedProject, selectedChallenge, questionId],
            type: 'deleteChallengeQuestion',
        });
    };

    const getProjectQuestions = async () => {
        try {
            const {
                data: { data },
            } = await API_SERVICE.getProjectQuestions(claimId, selectedProject);
            setTechnicalQuestions(data.items);
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        }
    };

    const getChallengeQuestions = async () => {
        try {
            const {
                data: { data },
            } = await API_SERVICE.getChallengeQuestions(
                claimId,
                selectedProject,
                selectedChallenge,
            );
            setTechnicalQuestions(data.items);
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        }
    };

    const getClaimsDetails = async () => {
        try {
            const {
                data: { data },
            } = await API_SERVICE.getClaimById(claimId);
            setClaimDetails(data);
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        }
    };

    return (
        <div className={'technical-details-wrapper page-wrapper'}>
            <div>
                <Row
                    className="title-bar"
                    align={'middle'}
                    justify={'space-between'}
                    gutter={20}
                    wrap={false}
                >
                    <Col>
                        <div className="d-flex align-items-center">
                            <div className={'title d-flex align-items-center'}>
                                <img
                                    src={pound}
                                    alt="pound"
                                    style={{
                                        height: '35px',
                                        marginRight: '7px',
                                    }}
                                />
                                Technical Details
                            </div>

                            <div style={{ width: '250px', marginLeft: '15px' }}>
                                <Row gutter={20}>
                                    <Col flex={'auto'}>
                                        <Form.Item
                                            name="tableType"
                                            style={{ marginBottom: 0 }}
                                        >
                                            <Select
                                                showArrow={true}
                                                showSearch={false}
                                                value={selectedProject}
                                                onSelect={handleProjectChange}
                                            >
                                                <Select.Option
                                                    key={0}
                                                    value={0}
                                                >
                                                    Basic Details
                                                </Select.Option>
                                                {projects &&
                                                    projects.length > 0 &&
                                                    projects.map(
                                                        (
                                                            item: any,
                                                            i: number,
                                                        ) => {
                                                            return (
                                                                <Select.Option
                                                                    key={`project-${i}`}
                                                                    value={
                                                                        item.id
                                                                    }
                                                                >
                                                                    {item.name}
                                                                </Select.Option>
                                                            );
                                                        },
                                                    )}
                                            </Select>

                                            {/*this select is for proper functioning of upper select*/}
                                            <select
                                                style={{ display: 'none' }}
                                                value={selectedProject}
                                            >
                                                <option key={0} value={0}>
                                                    Basic Details
                                                </option>
                                                {projects &&
                                                    projects.length > 0 &&
                                                    projects.map(
                                                        (
                                                            item: any,
                                                            i: number,
                                                        ) => {
                                                            return (
                                                                <option
                                                                    key={`projects-${i}`}
                                                                    value={
                                                                        item.id
                                                                    }
                                                                >
                                                                    {item.name}
                                                                </option>
                                                            );
                                                        },
                                                    )}
                                            </select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    <Col className={'header-buttons'}>
                        <Space>
                            {!!selectedProject ? (
                                <>
                                    {selectedChallenge === 0 ? (
                                        <>
                                            <Button
                                                shape="round"
                                                onClick={() => {
                                                    handleDeleteProject(
                                                        selectedProject,
                                                    );
                                                }}
                                                className="black-button my-3"
                                                icon={<DeleteOutlined />}
                                            >
                                                Delete Project
                                            </Button>
                                            <Button
                                                shape="round"
                                                onClick={() => {
                                                    handleEditProject(
                                                        selectedProject,
                                                    );
                                                }}
                                                className="black-button my-3"
                                                icon={<EditOutlined />}
                                            >
                                                Edit Project
                                            </Button>
                                            <Button
                                                shape="round"
                                                onClick={() => {
                                                    setAddNewProjectQuestion(
                                                        true,
                                                    );
                                                }}
                                                className="black-button my-3"
                                                icon={<PlusOutlined />}
                                            >
                                                Add Question
                                            </Button>
                                        </>
                                    ) : (
                                        <>
                                            <Button
                                                shape="round"
                                                onClick={() => {
                                                    handleDeleteChallenge(
                                                        selectedChallenge,
                                                    );
                                                }}
                                                className="black-button my-3"
                                                icon={<DeleteOutlined />}
                                            >
                                                Delete Uncertainty
                                            </Button>
                                            <Button
                                                shape="round"
                                                onClick={() => {
                                                    handleEditChallenge(
                                                        selectedChallenge,
                                                    );
                                                }}
                                                className="black-button my-3"
                                                icon={<EditOutlined />}
                                            >
                                                Edit Uncertainty
                                            </Button>
                                            <Button
                                                shape="round"
                                                onClick={() => {
                                                    setAddNewChallengeQuestion(
                                                        true,
                                                    );
                                                }}
                                                className="black-button my-3"
                                                icon={<PlusOutlined />}
                                            >
                                                Add Question
                                            </Button>
                                        </>
                                    )}
                                </>
                            ) : (
                                <Button
                                    shape="round"
                                    onClick={() => {
                                        setAddNewTechnicalQuestion(true);
                                    }}
                                    className="black-button my-3"
                                    icon={<PlusOutlined />}
                                >
                                    Add Question
                                </Button>
                            )}
                        </Space>
                    </Col>
                </Row>

                {selectedProject === 0 ? (
                    <Overview
                        setSelectedProject={setSelectedProject}
                        projects={projects}
                        claimId={claimId}
                        claimDetails={claimDetails}
                        getProjects={getProjects}
                        setAddNew={setAddNew}
                        handleEditQuestion={handleEditQuestion}
                        technicalQuestions={technicalQuestions}
                        handleDeleteTechnicalQuestion={
                            handleDeleteTechnicalQuestion
                        }
                    />
                ) : (
                    <Project
                        setSelectedProject={setSelectedProject}
                        projects={projects}
                        claimId={claimId}
                        claimDetails={claimDetails}
                        getProjects={getProjects}
                        selectedProject={selectedProject}
                        handleEditQuestion={handleEditQuestion}
                        technicalQuestions={technicalQuestions}
                        handleDeleteProjectQuestion={
                            handleDeleteProjectQuestion
                        }
                        selectedChallenge={selectedChallenge}
                        setSelectedChallenge={setSelectedChallenge}
                        editChallenge={editChallenge}
                        setEditChallenge={setEditChallenge}
                        handleEditChallengeQuestion={
                            handleEditChallengeQuestion
                        }
                        handleDeleteChallengeQuestion={
                            handleDeleteChallengeQuestion
                        }
                    />
                )}
            </div>

            <ConfirmationModal
                {...confirmationModal}
                callback={(a: any) => {
                    setConfirmationModal({});
                    if (a == 'technical') {
                        getTechnicalQuestions();
                    } else if (a == 'project') {
                        getProjectQuestions();
                    } else if (a == 'deleteProject') {
                        setSelectedProject(0);
                        getProjects();
                    } else if (a == 'deleteChallenge') {
                        setSelectedChallenge(0);
                    } else if (a == 'deleteChallengeQuestion') {
                        getChallengeQuestions();
                    }
                }}
            />

            {addNew && (
                <ProjectCreateOrEditPage
                    claimId={claimId}
                    onClose={(e: boolean) => {
                        if (e) {
                            getProjects();
                        }
                        setAddNew(false);
                    }}
                />
            )}

            {editProject && (
                <ProjectCreateOrEditPage
                    claimId={claimId}
                    details={editProject}
                    onClose={(e: boolean) => {
                        if (e) {
                            getProjects();
                        }
                        setEditProject(null);
                    }}
                />
            )}

            {addNewTechnicalQuestion && (
                <TechnicalQuestionCreateOrEditPage
                    claimId={claimId}
                    onClose={(e: boolean) => {
                        if (e) {
                            getTechnicalQuestions();
                        }
                        setAddNewTechnicalQuestion(false);
                    }}
                />
            )}

            {editTechnicalQuestion && (
                <TechnicalQuestionCreateOrEditPage
                    claimId={claimId}
                    details={editTechnicalQuestion}
                    onClose={(e: boolean) => {
                        if (e) {
                            if (selectedProject !== 0) {
                                getProjectQuestions();
                            } else {
                                getTechnicalQuestions();
                            }
                        }
                        setEditTechnicalQuestion(null);
                    }}
                />
            )}

            {addNewProjectQuestion && (
                <ProjectQuestionCreateOrEditPage
                    claimId={claimId}
                    projectId={selectedProject}
                    onClose={(e: boolean) => {
                        if (e) {
                            getProjectQuestions();
                        }
                        setAddNewProjectQuestion(false);
                    }}
                />
            )}

            {editProjectQuestion && (
                <ProjectQuestionCreateOrEditPage
                    claimId={claimId}
                    projectId={selectedProject}
                    details={editProjectQuestion}
                    onClose={(e: boolean) => {
                        if (e) {
                            getProjectQuestions();
                        }
                        setEditProjectQuestion(null);
                    }}
                />
            )}

            {addNewChallengeQuestion && (
                <ChallengeQuestionCreateOrEditPage
                    claimId={claimId}
                    projectId={selectedProject}
                    challengeId={selectedChallenge}
                    onClose={(e: boolean) => {
                        if (e) {
                            getChallengeQuestions();
                        }
                        setAddNewChallengeQuestion(false);
                    }}
                />
            )}

            {editChallengeQuestion && (
                <ChallengeQuestionCreateOrEditPage
                    claimId={claimId}
                    projectId={selectedProject}
                    challengeId={selectedChallenge}
                    details={editChallengeQuestion}
                    onClose={(e: boolean) => {
                        if (e) {
                            getChallengeQuestions();
                        }
                        setEditChallengeQuestion(null);
                    }}
                />
            )}
        </div>
    );
}

export function Overview({
    setSelectedProject,
    projects,
    claimId,
    claimDetails,
    getProjects,
    setAddNew,
    handleEditQuestion,
    technicalQuestions,
    handleDeleteTechnicalQuestion,
}: any) {
    const pie_external = 250;
    const pie_internal = 180;

    const [chartData, setChartData] = useState({} as any);
    // const [technicalQuestions, setTechnicalQuestions] = useState([] as any);

    const setChartDetails = () => {
        if (projects && projects.length > 0) {
            const totalProjects = projects.length;
            const ongoingProjects = projects.filter((item: any) => {
                return item.isOngoing === true;
            }).length;
            const pastProjects = totalProjects - ongoingProjects;

            setChartData({ totalProjects, ongoingProjects, pastProjects });
        }
    };

    /*const getTechnicalQuestions = async () => {
        try {
            const { data: { data } } = await API_SERVICE.getTechnicalQuestions(claimId);
            setTechnicalQuestions(data.items);
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        }
    };*/

    const finalizeTechnicalStage = async () => {
        try {
            const {
                match: { params },
                //@ts-ignore
            } = props;
            const {
                data: { data },
            } = await API_SERVICE.finalizeTechnicals(params.claimId);

            notification.success({
                message: 'Technical Data successfully Finalised',
            });
        } catch (error) {
            notification.error({
                message: API_SERVICE.handleErrors(error),
            });
        }
    };

    const handleDownloadTechnicalReport = async () => {
        try {
            const {
                data: { data },
            } = await API_SERVICE.downloadTechnicialReport(claimId);
            const a = document.createElement('a');
            a.href = data.filePath;
            a.download = 'TechnicalReport';
            a.click();
            console.log('clicke');
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        }
    };

    useEffect(() => {
        setChartDetails();
    }, [projects]);

    /*useEffect(() => {
        claimId && getTechnicalQuestions();
    }, [claimId]);*/

    return (
        <Row gutter={16}>
            <Col className="technical-pane-outer" xs={24} sm={10}>
                <div className="left-pane">
                    <Row>
                        <Col sm={13}>
                            <div className="bold-subtitle mb-0">
                                {claimDetails?.company?.name}
                            </div>
                            <div>Claim ID: {claimDetails?.id}</div>
                            <div>
                                Tax Year:{' '}
                                {claimDetails.financialYear +
                                    ' - ' +
                                    (claimDetails.financialYear + 1)}
                            </div>
                            <div>Type: {claimDetails?.type?.name}</div>

                            <br />

                            <div>
                                <div className="chart-wrapper">
                                    <PieChart
                                        style={{
                                            height: pie_external,
                                            width: pie_external,
                                        }}
                                        label={(props) =>
                                            props.dataEntry.percentage.toFixed(
                                                0,
                                            ) + '%'
                                        }
                                        labelPosition={86}
                                        labelStyle={{
                                            fontSize: '7px',
                                            fontWeight: 400,
                                            fill: '#fff',
                                        }}
                                        // data={users_set(details)}
                                        data={[
                                            {
                                                title: 'Ongoing Projects',
                                                value: chartData.ongoingProjects,
                                                color: '#f48028',
                                            },
                                            {
                                                title: 'Past Projects',
                                                value: chartData.pastProjects,
                                                color: '#000',
                                            },
                                        ]}
                                    />
                                    <div
                                        className="chart-center"
                                        style={{
                                            top:
                                                -(pie_external + pie_internal) /
                                                1.95,
                                            left:
                                                (pie_external - pie_internal) /
                                                1.95,
                                            height: pie_internal,
                                            width: pie_internal,
                                        }}
                                    >
                                        <div>Total</div>
                                        <div>
                                            {chartData.totalProjects} Projects
                                        </div>
                                    </div>

                                    <div style={{ marginTop: -pie_internal }}>
                                        <div className="d-flex align-items-center">
                                            <div className="info-point ongoing" />
                                            <div>Ongoing Projects</div>
                                        </div>

                                        <div className="d-flex align-items-center">
                                            <div className="info-point past" />
                                            <div>Past Projects</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <br />
                            <div className="text-center">
                                <Space direction="vertical" size={20}>
                                    <Popconfirm
                                        title="Are you sure to you want to Finalize Report"
                                        onConfirm={() =>
                                            finalizeTechnicalStage()
                                        }
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Button
                                            shape="round"
                                            className="black-button"
                                            icon={<CheckOutlined />}
                                        >
                                            Finalize Technical Report
                                        </Button>
                                    </Popconfirm>
                                    <Button
                                        onClick={handleDownloadTechnicalReport}
                                        shape="round"
                                        className="black-button"
                                        icon={<DownloadOutlined />}
                                    >
                                        Download Technical Report
                                    </Button>
                                </Space>
                            </div>
                        </Col>
                        <Col sm={11} className="left-pane-bottom">
                            <Card
                                size="small"
                                className="h-100 left-pane-bottom-inner"
                            >
                                <Button
                                    shape="round"
                                    onClick={() => {
                                        setAddNew(true);
                                    }}
                                    className="black-button my-3"
                                    icon={<PlusOutlined />}
                                >
                                    Add Project
                                </Button>

                                <div className="bold-subtitle">
                                    Projects Included:
                                </div>
                                {projects && projects.length > 0 ? (
                                    <ol className="project-list">
                                        {projects.map(
                                            (item: any, i: number) => {
                                                return (
                                                    <li key={i}>
                                                        <Link
                                                            onClick={() => {
                                                                setSelectedProject(
                                                                    item.id,
                                                                );
                                                            }}
                                                        >
                                                            {item.name}
                                                        </Link>
                                                    </li>
                                                );
                                            },
                                        )}
                                    </ol>
                                ) : (
                                    <div>No project Found</div>
                                )}
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Col>
            <Col sm={13} offset={1} style={{ margin: '0' }}>
                <div className="right-pane">
                    <div className="title stikcy-title">
                        Basic details | Claim Id: {claimDetails?.id} |{' '}
                        {claimDetails?.company?.name}
                    </div>
                    <div className="question-card-wrapper">
                        {technicalQuestions &&
                            technicalQuestions.length > 0 &&
                            technicalQuestions.map(
                                (item: any, index: number) => {
                                    return (
                                        <div
                                            className="question-card"
                                            key={index + 1}
                                        >
                                            <div className="question d-flex justify-content-between align-items-center">
                                                <div>
                                                    <div className="bold-subtitle">
                                                        Question {index + 1}:{' '}
                                                    </div>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: item.question,
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <Space>
                                                        <Space direction="vertical">
                                                            <Button
                                                                shape="round"
                                                                onClick={() => {
                                                                    handleEditQuestion(
                                                                        item,
                                                                    );
                                                                }}
                                                                className="theme-button primary ant-btn-icon-only-img"
                                                                icon={
                                                                    <EditOutlined />
                                                                }
                                                            />
                                                            {!item.isDefault && (
                                                                <Button
                                                                    type="default"
                                                                    shape="round"
                                                                    onClick={() => {
                                                                        handleDeleteTechnicalQuestion(
                                                                            item.id,
                                                                        );
                                                                    }}
                                                                    className="gray-button ant-btn-icon-only-img"
                                                                    icon={
                                                                        <DeleteOutlined />
                                                                    }
                                                                />
                                                            )}
                                                        </Space>
                                                    </Space>
                                                </div>
                                            </div>
                                            <div className="answer">
                                                <div className="bold-subtitle">
                                                    Answer {index + 1}:{' '}
                                                </div>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: item.answer,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    );
                                },
                            )}
                    </div>
                </div>
            </Col>
        </Row>
    );
}

export function Project({
    setSelectedProject,
    projects,
    claimId,
    claimDetails,
    getProjects,
    selectedProject,
    handleEditQuestion,
    technicalQuestions,
    handleDeleteProjectQuestion,
    selectedChallenge,
    setSelectedChallenge,
    editChallenge,
    setEditChallenge,
    handleEditChallengeQuestion,
    handleDeleteChallengeQuestion,
}: any) {
    const [currentProject, setCurrentProject] = useState({} as any);
    const [addNewProjectChallenge, setAddNewProjectChallenge] = useState(false);
    const [editProjectChallenge, setEditProjectChallenge] = useState(
        null as any,
    );

    const [technicalChallenges, setTechnicalChallenges] = useState([] as any);
    const [currentChallenge, setCurrentChallenge] = useState(0 as number);

    const getProjectChallenges = async () => {
        try {
            const {
                data: { data },
            } = await API_SERVICE.getProjectChallenges(
                claimId,
                selectedProject,
            );
            setTechnicalChallenges(data.items);
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        }
    };

    const handleCurrentChallengeChange = (value: number) => {
        setCurrentChallenge(value);
        setSelectedChallenge(value);
    };

    useEffect(() => {
        if (selectedProject) {
            const currentProject = projects.filter((item: any) => {
                return item.id === selectedProject;
            });

            setCurrentProject(currentProject[0]);
            getProjectChallenges();
        }
    }, [selectedProject, projects]);

    useEffect(() => {
        if (selectedChallenge === 0) {
            setCurrentChallenge(0);
            getProjectChallenges();
        }
    }, [selectedChallenge]);

    useEffect(() => {
        if (editChallenge) {
            const currentProject = technicalChallenges.filter((item: any) => {
                return item.id === currentChallenge;
            });

            setEditProjectChallenge(currentProject[0]);
        }
    }, [editChallenge]);
    return (
        <Row>
            <Col sm={10}>
                <div className="left-pane">
                    <Row>
                        <Col sm={24}>
                            <div className="d-flex align-items-center justify-content-between">
                                <div>
                                    <div className="bold-subtitle mb-0">
                                        {claimDetails?.company?.name}
                                    </div>
                                    <div>Claim ID: {claimDetails?.id}</div>
                                    <div>
                                        Tax Year:{' '}
                                        {claimDetails.financialYear -
                                            1 +
                                            ' - ' +
                                            claimDetails.financialYear}
                                    </div>
                                    <div>Type: {claimDetails?.type?.name}</div>
                                </div>
                                <div className="text-center">
                                    <Button
                                        type={
                                            currentChallenge === 0
                                                ? 'primary'
                                                : 'default'
                                        }
                                        size="large"
                                        onClick={() => {
                                            handleCurrentChallengeChange(0);
                                        }}
                                    >
                                        Overview: {currentProject?.name}
                                    </Button>
                                    <div>
                                        {currentProject?.startDate} -{' '}
                                        {currentProject.isOngoing
                                            ? 'Ongoing'
                                            : currentProject.endDate}
                                    </div>
                                </div>
                            </div>
                            <div className="text-center">
                                <div className="title">
                                    Technical Challenges & Uncertainities
                                </div>
                                <Button
                                    shape="round"
                                    onClick={() => {
                                        setAddNewProjectChallenge(true);
                                    }}
                                    className="black-button my-3"
                                    icon={<PlusOutlined />}
                                >
                                    Add New
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Row className="text-center challenge-switches-wrapper">
                        {technicalChallenges &&
                            technicalChallenges.map(
                                (challenge: any, index: number) => {
                                    return (
                                        <Col sm={24}>
                                            <Button
                                                type={
                                                    currentChallenge ===
                                                    challenge.id
                                                        ? 'primary'
                                                        : 'default'
                                                }
                                                size="large"
                                                block
                                                key={index}
                                                onClick={() => {
                                                    handleCurrentChallengeChange(
                                                        challenge.id,
                                                    );
                                                }}
                                            >
                                                {challenge?.name}
                                            </Button>
                                        </Col>
                                    );
                                },
                            )}
                    </Row>
                </div>
            </Col>

            <Col sm={13} offset={1}>
                <div className="right-pane">
                    <div className="title stikcy-title">
                        Basic details | Claim Id: {claimDetails?.id} |{' '}
                        {claimDetails?.company?.name}
                    </div>
                    <div className="question-card-wrapper">
                        {technicalQuestions &&
                            technicalQuestions.length > 0 &&
                            technicalQuestions.map(
                                (item: any, index: number) => {
                                    return (
                                        <div
                                            className="question-card"
                                            key={index + 1}
                                        >
                                            <div className="question d-flex justify-content-between align-items-center">
                                                <div>
                                                    <div className="bold-subtitle">
                                                        Question {index + 1}:{' '}
                                                    </div>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: item.question,
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <Space>
                                                        <Space direction="vertical">
                                                            {currentChallenge ===
                                                            0 ? (
                                                                <>
                                                                    <Button
                                                                        shape="round"
                                                                        onClick={() => {
                                                                            handleEditQuestion(
                                                                                item,
                                                                            );
                                                                        }}
                                                                        className="theme-button primary ant-btn-icon-only-img"
                                                                        icon={
                                                                            <EditOutlined />
                                                                        }
                                                                    />
                                                                    {!item.isDefault && (
                                                                        <Button
                                                                            type="default"
                                                                            shape="round"
                                                                            onClick={() => {
                                                                                handleDeleteProjectQuestion(
                                                                                    item.id,
                                                                                );
                                                                            }}
                                                                            className="gray-button ant-btn-icon-only-img"
                                                                            icon={
                                                                                <DeleteOutlined />
                                                                            }
                                                                        />
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <Button
                                                                        shape="round"
                                                                        onClick={() => {
                                                                            handleEditChallengeQuestion(
                                                                                item,
                                                                            );
                                                                        }}
                                                                        className="theme-button primary ant-btn-icon-only-img"
                                                                        icon={
                                                                            <EditOutlined />
                                                                        }
                                                                    />
                                                                    {!item.isDefault && (
                                                                        <Button
                                                                            type="default"
                                                                            shape="round"
                                                                            onClick={() => {
                                                                                handleDeleteChallengeQuestion(
                                                                                    item.id,
                                                                                );
                                                                            }}
                                                                            className="gray-button ant-btn-icon-only-img"
                                                                            icon={
                                                                                <DeleteOutlined />
                                                                            }
                                                                        />
                                                                    )}
                                                                </>
                                                            )}
                                                        </Space>
                                                    </Space>
                                                </div>
                                            </div>
                                            <div className="answer">
                                                <div className="bold-subtitle">
                                                    Answer {index + 1}:{' '}
                                                </div>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: item.answer,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    );
                                },
                            )}
                    </div>
                </div>
            </Col>

            {addNewProjectChallenge && (
                <ChallengeCreateOrEditPage
                    claimId={claimId}
                    projectId={selectedProject}
                    onClose={(e: boolean) => {
                        if (e) {
                            // getProjectQuestions();
                        }
                        getProjectChallenges();
                        setAddNewProjectChallenge(false);
                    }}
                />
            )}

            {editProjectChallenge && (
                <ChallengeCreateOrEditPage
                    claimId={claimId}
                    projectId={selectedProject}
                    details={editProjectChallenge}
                    onClose={(e: boolean) => {
                        if (e) {
                            getProjectChallenges();
                        }
                        setEditProjectChallenge(null);
                        setEditChallenge(false);
                    }}
                />
            )}
        </Row>
    );
}
