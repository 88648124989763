import React, { useState, useEffect } from 'react';
import { Table, Typography, notification, Space, Form, Input } from 'antd';
import API_SERVICE from 'services/api-service';
import { PrimaryTooltip } from 'components';

const { Text, Title } = Typography;

export default function ConsumablesTable({ claimId, claimDetails }: any) {
    const [consumableTableData, setConsumableTableData] = useState([] as any[]);
    const [consumablesCostTotal, setConsumableCostTotal] = useState({
        netAmount: 0,
        rnd: 0,
        total: 0,
    } as any);
    const [fetching, setFetching] = useState(false);

    const [columns, setColumns] = useState([
        {
            title: 'S.No',
            dataIndex: 'sNO',
            width: '70px',
            render: (a: any, v: any, index: number) => <Text>{index + 1}</Text>,
        },
        {
            title: 'Consumable Name',
            dataIndex: 'name',
            width: '200px',
            render: (a: any) => <Text>{a}</Text>,
        },
        {
            title: 'Consumable Type',
            dataIndex: 'consumableId',
            width: '200px',
            render: (a: any) => <Text>{a}</Text>,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            width: '180px',
            render: (a: any) => <Text>{a}</Text>,
        },
        {
            title: 'Net Amount (GBP)',
            dataIndex: 'netAmount',
            width: '180px',
            render: (a: any) => <Text>£ {a}</Text>,
        },
        {
            title: 'R&D Allocation (%)',
            dataIndex: 'rndAllocationPercentage',
            width: '150px',
            render: (a: any) => <Text>{a} %</Text>,
        },
        {
            title: 'Total (GBP)',
            dataIndex: 'total',
            width: '150px',
            editable: false,
            render: (a: any) => <Text>£ {a}</Text>,
        },
    ]);

    const RDECColumn = [
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="RDEC Allocation (%)"
                />
            ),
            key: 'rdecAllocation',
            width: '200px',
            render: (a: any) => (
                <Text>{`${
                    100 - (a.smeAllocation ? a.smeAllocation : 0)
                } %`}</Text>
            ),
        },
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="RDEC Allocation (GBP)"
                />
            ),
            dataIndex: 'rdecTotal',
            width: '200px',
            render: (a: any) => (
                <Text>
                    {`£ ${a ? a : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </Text>
            ),
        },
    ];

    const SMEColumn = [
        {
            title: (
                <PrimaryTooltip title="prompt text" text="SME Allocation (%)" />
            ),
            dataIndex: 'smeAllocation',
            width: '200px',
        },
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="SME Allocation (GBP)"
                />
            ),
            dataIndex: 'smeTotal',
            width: '200px',
            render: (a: any) => (
                <Text>
                    {`£ ${a ? a : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </Text>
            ),
        },
    ];

    const MIXEDColumn = [
        {
            title: (
                <PrimaryTooltip title="prompt text" text="SME Allocation (%)" />
            ),
            dataIndex: 'smeAllocation',
            width: '200px',
        },
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="SME Allocation (GBP)"
                />
            ),
            dataIndex: 'smeTotal',
            width: '200px',
            render: (a: any) => (
                <Text>
                    {`£ ${a ? a : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </Text>
            ),
        },
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="RDEC Allocation (%)"
                />
            ),
            key: 'rdecAllocation',
            width: '200px',
            render: (a: any) => (
                <Text>{`${
                    100 - (a.smeAllocation ? a.smeAllocation : 0)
                } %`}</Text>
            ),
        },
        {
            title: (
                <PrimaryTooltip
                    title="prompt text"
                    text="RDEC Allocation (GBP)"
                />
            ),
            dataIndex: 'rdecTotal',
            width: '200px',
            render: (a: any) => (
                <Text>
                    {`£ ${a ? a : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </Text>
            ),
        },
    ];

    const getConsumablesCostList = async () => {
        setFetching(true);
        try {
            const {
                data: { data },
            } = await API_SERVICE.consumablesCostList(claimId);
            setConsumableTableData(data.items);
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        } finally {
            setFetching(false);
        }
    };

    const getConsumablesCostTotal = async () => {
        try {
            await API_SERVICE.consumablesCostTotal(claimId).then((result) => {
                const {
                    data: { data },
                } = result;
                setConsumableCostTotal(data);
            });
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        }
    };

    const checkClaimType = () => {
        if (claimDetails?.type?.name === 'SME') {
            let newColumns: any = [...columns, ...SMEColumn];
            setColumns(newColumns);
        } else if (claimDetails?.type?.name === 'RDEC') {
            let newColumns: any = [...columns, ...RDECColumn];
            setColumns(newColumns);
        } else if (claimDetails?.type?.name === 'Mixed') {
            console.log('mixed', MIXEDColumn);

            let newColumns: any = [...columns, ...MIXEDColumn];
            setColumns(newColumns);
        }
    };

    useEffect(() => {
        getConsumablesCostList();
        getConsumablesCostTotal();

        checkClaimType();
    }, []);

    return (
        <>
            <Title level={4}>Consumable </Title>
            <Table
                loading={fetching}
                pagination={false}
                scroll={{ x: true }}
                columns={columns}
                dataSource={consumableTableData}
                bordered
            />

            <div className="d-flex align-items-center justify-content-end mt-3">
                <Space>
                    <Form.Item className="mb-0" style={{ width: '220px' }}>
                        <Input
                            disabled
                            value={`Net Amount: ${
                                consumablesCostTotal.netAmount &&
                                consumablesCostTotal.netAmount.toFixed(2)
                            } GBP`}
                        />
                    </Form.Item>
                    <Form.Item className="mb-0" style={{ width: '220px' }}>
                        <Input
                            disabled
                            value={`Total: ${
                                consumablesCostTotal.total &&
                                consumablesCostTotal.total.toFixed(2)
                            } GBP`}
                        />
                    </Form.Item>
                </Space>
            </div>
        </>
    );
}
